import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Material UI components
import { AppBar, Toolbar, Box } from '@material-ui/core';

// assets
import logo from 'assets/img/logo/brand-signature-tm.png';
import AccountMenu from 'components/Header/AccountMenu';
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.shadows[25],
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  appBarTitle: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  toolbar: {
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: '12rem',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      width: '8rem',
    },
  },
  logoLink: {
    display: 'inline-flex',
  },
  links: {
    backgroundColor: 'red',
  },
  languageSelector: {
    marginLeft: theme.spacing(3),
  },
}));

const Nav = (props) => {
  const classes = useStyles();

  return (
    <header className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <div>{props.children}</div>
          <Box className={classes.appBarTitle}>
            <Link className={classes.logoLink} to="/">
              <img className={classes.logo} src={logo} alt="Recycletek Logo" />
            </Link>
          </Box>
          <div>
            <AccountMenu />
          </div>
          <div className={classes.languageSelector}>
            <LanguageSelect />
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Nav;
