import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch } from 'react-redux';
import { addToCart } from 'redux/actions/shop';
import { ALERT_FAIL } from 'redux/actions/types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Grid,
  Divider,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

// Components
import BasicCard from 'components/Card/BasicCard';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import CustomNumInput from 'components/CustomNumberFormat/CustomNumInput';

// utils
import { formatMoney } from 'utils/formatTransactions';
import { SERVER_URL } from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '250px',
  },
  itemContainer: {
    padding: '1.5rem',
  },
  itemName: {
    fontWeight: 300,
    textTransform: 'capitalize',
  },
  itemNameBold: {
    fontWeight: 500,
  },
  itemPrice: {
    fontSize: theme.typography.pxToRem(14),
  },
  itemImage: {
    maxHeight: '125px',
    width: 'auto',
  },
  itemDescription: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
  },
  amount: {
    border: '1px solid grey',
    borderRadius: '10px',
    padding: '5px 10px',
  },
  plusButton: {
    marginLeft: '.5rem',
    color: theme.palette.tertiary.main,
  },
  minusButton: {
    marginRight: '.5rem',
    color: theme.palette.tertiary.main,
  },
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    margin: '.5rem 0',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  cart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  description: {
    fontSize: '14px',
  },
  descriptionText: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '.5rem',
  },
  listRoot: {
    padding: 0,
  },
  gutters: {
    padding: 0,
  },
  divider: {
    marginBottom: '.5rem',
  },
}));

function ItemCard(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // saved as string via CustomNumInput
  const [amount, setAmount] = React.useState(1);
  const [name, setName] = React.useState({ name: '', type: '' });
  const { price_microdollars, description, quantity, img_uri } = props.product;
  
  const handleAmount = (e) => {
    const { value } = e.target;
    setAmount(value);
  };

  const handleChangeAmount = (action) => {
    if (action === 'add') {
      amount === '' ? setAmount(1) : setAmount((prev) => parseFloat(prev) + 1);
    } else {
      if (amount === '') {
        setAmount(0);
      }
      if (amount >= 1) {
        setAmount((prev) => parseFloat(prev) - 1);
      } else {
        return;
      }
    }
  };

  const handleAddToCart = () => {
    const product = { ...props.product, amount: parseFloat(amount) };
    if (parseFloat(amount) === 0 || amount === '') {
      dispatch({
        type: ALERT_FAIL,
        payload: t('ecosupplies.pleaseEnterAnAmount'),
      });
    } else {
      dispatch(addToCart(product));
      setAmount(1);
    }
  };

  const getName = (name) => {
    const lowercase = name.toLowerCase();
    if (lowercase.includes('eco')) {
      const type = lowercase.split('eco').pop();
      setName({ name: 'eco', type });
    } else {
      setName((prev) => ({ ...prev, name: lowercase }));
    }
  };

  React.useEffect(() => {
    getName(props.product.name);
  }, [props.product.name]);

  return (
    <div className={classes.root} data-cy={props.product.name}>
      <BasicCard>
        <Grid container className={classes.itemContainer}>
          <Grid item container justify="center">
            <img
              className={classes.itemImage}
              src={`${SERVER_URL}${img_uri}`}
              alt={name}
            />
          </Grid>
          <Grid item>
            <Typography color="primary" className={classes.itemName}>
              {name.name}
              {name.type !== '' && (
                <span className={classes.itemNameBold}>{name.type}</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.itemPrice} color="primary">
              {formatMoney(price_microdollars)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.itemPrice} color="primary">
              {t('ecosupplies.quantity')} {quantity}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List disablePadding={true}>
              <ListItem
                button
                classes={{ root: classes.listRoot, gutter: classes.gutter }}
              >
                <ListItemText>
                  <Typography className={classes.description}>
                    {t('ecosupplies.description')}
                  </Typography>
                </ListItemText>
              </ListItem>
                <Typography className={classes.descriptionText}>
                  {description}
                </Typography>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.cart}>
              <Box display="flex" alignItems="center">
                <IconButton
                  className={classes.minusButton}
                  size="small"
                  aria-label="minus"
                  onClick={() => handleChangeAmount('minus')}
                >
                  <RemoveIcon name="minus" fontSize="inherit" />
                </IconButton>
                <PortalTextFieldNoShadow
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: 'center',
                      width: '50px',
                    },
                  }}
                  padding={'0 8px 0'}
                  className={classes.input}
                  id="amount"
                  name="amount"
                  value={amount}
                  inputComponent={CustomNumInput}
                  onChange={handleAmount}
                />
                <IconButton
                  className={classes.plusButton}
                  aria-label="minus"
                  size="small"
                  onClick={() => handleChangeAmount('add')}
                >
                  <AddIcon name="add" fontSize="inherit" />
                </IconButton>
              </Box>
              <Button
                classes={{ root: classes.button }}
                color="secondary"
                variant="contained"
                type="submit"
                onClick={handleAddToCart}
              >
                {t('buttons.addToCart')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </BasicCard>
    </div>
  );
}

export default ItemCard;
