import React from 'react';
import Button from 'components/CustomButtons/Button.js';

const SocialIcons = (props) => {
  return (
    <div>
      <Button
        href="https://facebook.com/recycletek"
        target="_blank"
        justIcon
        color="transparent"
      >
        <i className={' fab fa-facebook-f'} />
      </Button>

      <Button
	href="https://twitter.com/recycletek"
        target="_blank"
        justIcon
        color="transparent"
      >
        <i className={' fab fa-twitter'} />
      </Button>

      <Button
        href="https://www.linkedin.com/company/recycletekofficial/"
        target="_blank"
        justIcon
        color="transparent"
      >
        <i className={' fab fa-linkedin-in'} />
      </Button>
      <Button
        href="https://www.instagram.com/recycletek_official/"
        target="_blank"
        justIcon
        color="transparent"
      >
        <i className={' fab fa-instagram'} />
      </Button>
    </div>
  );
};

export default SocialIcons;
