import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  infoText: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'justify',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
}));

const PayPalSuccess = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { transferMessage } = useSelector((state) => state.transactions);
  return (
    <div>
      <Box mt={3}>
        <DialogContentText className={classes.infoText}>
          {transferMessage}
        </DialogContentText>
      </Box>
      <DialogActions>
        <Button
          classes={{ root: classes.button }}
          color="primary"
          variant="contained"
          onClick={props.handleClose}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          classes={{ root: classes.button }}
          color="secondary"
          variant="contained"
          type="submit"
          onClick={props.handlePreviousActiveStep}
        >
          {t('buttons.goBack')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default PayPalSuccess;
