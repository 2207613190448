import React from 'react';
import { DateTime } from 'luxon';
import { getKeyByValue } from 'utils/utils';
import { formatISO } from 'utils/dateFunctions';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Collapse,
  ListItemText,
  Button,
  FormLabel,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ShippingCallout from './ShippingCallout';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  detail: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.tertiary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  deliveryTitle: {
    color: theme.palette.primary.main,
  },
  formControl: {
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2),
  },
  radio: {
    padding: 0,
    paddingRight: '12px',
  },
}));

function ShippingOptions(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { checkoutOptions } = useSelector((state) => state.store);
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState('');
  const [type, setType] = React.useState('');
  const [shipID, setShipID] = React.useState('');
  const [error, setError] = React.useState(false);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setType(event.target.dataset.ship);
    if (event.target.dataset.id) {
      setShipID(event.target.dataset.id);
    }
    setError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const formatDeliverType = (type, delivery) => {
    if (type === 'USPS' || type === 'UPS') {
      return delivery.split(/\s+|_|(?=[A-Z][a-z])/).join(' ');
    } else if (type === 'FedEx') {
      const split = delivery.split('_');
      const newSplit = split.map((element) => {
        if (element === 'AM' || element === 'PM') {
          return element;
        } else {
          const lower = element.toLowerCase();
          return lower.charAt(0).toUpperCase() + lower.slice(1);
        }
      });
      return newSplit.join(' ').replace('Fedex', '');
    } else {
      return delivery;
    }
  };

  return (
    <React.Fragment>
      <ListItem
        classes={{ root: classes.listRoot }}
        button
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography color="primary" className={classes.title}>
            {t('ecosupplies.shippingOptions')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box py={1} pl={3}>
          <form onSubmit={handleSubmit}>
            <FormControl component="fieldset" error={error}>
              <FormLabel component="legend">
                <Typography variant="srOnly">
                  {t('ecosupplies.shippingOptions')}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-label="shippingOptions"
                name="shipping"
                value={value}
                onChange={handleRadioChange}
              >
                {Object.keys(checkoutOptions).length !== 0 &&
                checkoutOptions.shipping_options &&
                (checkoutOptions.shipping_options.subscribed_pickups.length !==
                  0 ||
                  checkoutOptions.shipping_options.subscribed_pickups_single
                    .length !== 0) ? (
                  <React.Fragment>
                    <Typography className={classes.deliveryTitle}>
                      <strong>
                        {t('ecosupplies.dropOffNextCurbside')} - $1.00
                      </strong>
                    </Typography>
                    <Box pl={1} display="flex" flexDirection="column">
                      {Object.keys(checkoutOptions).length !== 0 &&
                        checkoutOptions.shipping_options
                          .subscribed_pickups_single.length !== 0 &&
                        checkoutOptions.shipping_options.subscribed_pickups_single.map(
                          (pickup) => (
                            <FormControlLabel
                              key={pickup.id_singlepickuprequest}
                              value={pickup.id_singlepickuprequest.toString()}
                              control={
                                <Radio
                                  inputProps={{
                                    'data-ship': getKeyByValue(
                                      pickup,
                                      pickup.id_singlepickuprequest,
                                    ),
                                  }}
                                />
                              }
                              label={
                                <Typography>
                                  {formatISO(pickup.date).toLocaleString(
                                    DateTime.DATE_MED_WITH_WEEKDAY,
                                  )}
                                </Typography>
                              }
                            />
                          ),
                        )}
                      {Object.keys(checkoutOptions).length !== 0 &&
                        checkoutOptions.shipping_options.subscribed_pickups
                          .length !== 0 &&
                        checkoutOptions.shipping_options.subscribed_pickups.map(
                          (pickup) => (
                            <FormControlLabel
                              key={
                                pickup.id_pickuprequestsrecurring
                                  ? pickup.id_pickuprequestsrecurring
                                  : pickup.id_singlepickuprequest
                              }
                              value={
                                pickup.id_pickuprequestsrecurring
                                  ? pickup.id_pickuprequestsrecurring.toString()
                                  : pickup.id_singlepickuprequest.toString()
                              }
                              control={
                                <Radio
                                  inputProps={{
                                    'data-ship': getKeyByValue(
                                      pickup,
                                      pickup.id_pickuprequestsrecurring
                                        ? pickup.id_pickuprequestsrecurring
                                        : pickup.id_singlepickuprequest,
                                    ),
                                  }}
                                />
                              }
                              label={
                                <Typography>
                                  {formatISO(pickup.date).toLocaleString(
                                    DateTime.DATE_MED_WITH_WEEKDAY,
                                  )}
                                </Typography>
                              }
                            />
                          ),
                        )}
                    </Box>
                  </React.Fragment>
                ) : (
                  <ShippingCallout />
                )}

                {Object.keys(checkoutOptions).length !== 0 &&
                checkoutOptions.shipping_options.pickups.length !== 0 ? (
                  <React.Fragment>
                    <Typography className={classes.deliveryTitle}>
                      <strong>{t('ecosupplies.dropOff')} - $5.00</strong>
                    </Typography>
                    <Box pl={1} display="flex" flexDirection="column">
                      {Object.keys(checkoutOptions).length !== 0 &&
                        checkoutOptions.shipping_options.pickups.length !== 0 &&
                        checkoutOptions.shipping_options.pickups.map(
                          (pickup) => (
                            <FormControlLabel
                              key={pickup.id_availablepickupdays}
                              value={pickup.id_availablepickupdays.toString()}
                              control={
                                <Radio
                                  inputProps={{
                                    'data-ship': getKeyByValue(
                                      pickup,
                                      pickup.id_availablepickupdays,
                                    ),
                                  }}
                                />
                              }
                              label={
                                <Typography>
                                  {formatISO(pickup.date).toLocaleString(
                                    DateTime.DATE_MED_WITH_WEEKDAY,
                                  )}
                                </Typography>
                              }
                            />
                          ),
                        )}
                    </Box>
                  </React.Fragment>
                ) : null}

                <Typography className={classes.deliveryTitle}>
                  <strong>{t('ecosupplies.deliverBy')}</strong>
                </Typography>
                <Box pt={1} pl={2} display="flex" flexDirection={'column'}>
                  {Object.keys(checkoutOptions).length !== 0 &&
                    checkoutOptions.shipping_options.shipping_options.map(
                      (option) => (
                        <FormControlLabel
                          classes={{ root: classes.formControl }}
                          key={option.rate_id}
                          value={option.rate_id}
                          control={
                            <Radio
                              inputProps={{
                                'data-ship': 'shipment_id',
                                'data-id': `${option.shipment_id}`,
                              }}
                              className={classes.radio}
                            />
                          }
                          label={
                            <Box display="flex" flexDirection={'column'}>
                              <Typography>
                                {`${option.carrier} ${formatDeliverType(
                                  option.carrier,
                                  option.servcice,
                                )} - $${option.rate}`}
                              </Typography>
                              <Typography>
                                {t('ecosupplies.estimatedDeliveryDate')}
                                {option.delivery_date
                                  ? ` ${formatISO(
                                      option.delivery_date,
                                    ).toLocaleString(
                                      DateTime.DATE_MED_WITH_WEEKDAY,
                                    )}`
                                  : ' N/A'}
                              </Typography>
                            </Box>
                          }
                        />
                      ),
                    )}
                </Box>
              </RadioGroup>
              <Box py={2}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(event) =>
                    props.handleSelectShipping(event, value, type, shipID)
                  }
                  disabled={props.disabled}
                >
                  {t('ecosupplies.proceedToPayment')}
                </Button>
              </Box>
            </FormControl>
          </form>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default ShippingOptions;
