/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import theme from 'themes/memberPortalTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '12px 12px 12px 12px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  text: {
    fontWeight: 300,
    textAlign: 'justify',
  },
}));

function TotalGraph() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const totalData = useSelector((state) => state.metrics.stats?.totalData);

  useEffect(() => {
    console.log('totalData', totalData);
  }, []);

  const options = {
    aspectRation: 5 / 3,
    animation: true,
    animationSteps: 60,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Total Containers Recycled Per Month',
        // color: theme.palette.text,
        padding: {
          bottom: 30,
        },
        font: {
          size: 16,
        },
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.pointBackgroundColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: Math.round,
        padding: 4,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
        title: {
          display: true,
          text: t('metrics.numOfContainers'),
        },
        ticks: {
          stepSize: 100,
        },
        suggestedMax: 50,
        suggestedMin: 0,
      },
    },
  };

  const gradientData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(500, 0, 100, 0);
    gradient.addColorStop(0, 'rgba(0, 079, 113, 1)');
    gradient.addColorStop(0.5, 'rgba(5, 195, 221, 1)');
    gradient.addColorStop(1, 'rgba(151, 215, 0, 1)');

    const gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
    gradientFill.addColorStop(0, 'rgba(0, 079, 113, .4)');
    gradientFill.addColorStop(0.5, 'rgba(5, 195, 221, .4)');
    gradientFill.addColorStop(1, 'rgba(151, 215, 0, .4)');

    return {
      labels: totalData?.labels && [...totalData.labels],
      datasets: [
        {
          label: 'Total Monthly Containers',
          data: totalData?.metrics && [...totalData.metrics],
          fill: true,
          pointBackgroundColor: theme.palette.tertiary.main,
          pointBorderColor: theme.palette.tertiary.main,
          borderColor: gradient,
          backgroundColor: gradientFill,
          tension: 0.4,
        },
      ],
    };
  };

  let shadowed = {
    beforeDatasetsDraw: function (chart, options) {
      chart.ctx.shadowColor = 'rgba(0, 0, 0, .2)';
      chart.ctx.shadowOffsetY = 15;
      chart.ctx.shadowBlur = 10;
    },
    afterDatasetsDraw: function (chart, options) {
      chart.ctx.shadowColor = 'rgba(0, 0, 0, 0)';
      chart.ctx.shadowBlur = 0;
    },
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Typography>{t('metrics.totalContainersPerMonth')}</Typography>
      </Box>
      <Box mt={1}>
        <Line data={gradientData} plugins={[shadowed]} options={options} />
      </Box>
    </div>
  );
}

export default TotalGraph;
