import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Grid, Toolbar, Box } from '@material-ui/core';

// components
import RulesOfReturning from './Sections/RulesOfReturning';
import BeverageGuide from './Sections/BeverageGuide';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  card: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '900px',
    },
  },
}));

const Recyclable = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Toolbar />
      <Grid container className={classes.container}>
        <Grid item md={12} lg={9}>
          <Box mb={5} className={classes.card}>
            <RulesOfReturning />
          </Box>
        </Grid>
        <Grid item md={12} lg={9}>
          <Box className={classes.card}>
            <BeverageGuide />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Recyclable;
