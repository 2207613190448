import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';

// styles
const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: 0,
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

function NameForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.auth.customer);

  const handleCancel = () => {
    formik.handleReset();
    props.handleClose('name');
  };

  const { first_name, last_name } = props.validation;

  const validationSchema = yup.object({
    first_name,
    last_name,
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(updateCustomer(values));
      actions.resetForm();
      props.handleClose('name');
    },
  });

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title" color="primary">
            {t('account.form.changeName')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <PortalInputLabel
                  classes={{ root: classes.label }}
                  id="firstName"
                >
                  {t('account.form.firstName')}
                </PortalInputLabel>
                <PortalTextFieldNoShadow
                  fullWidth
                  type="text"
                  id="firstName"
                  name="first_name"
                  placeholder={customer.first_name}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PortalInputLabel
                  classes={{ root: classes.label }}
                  id="lastName"
                >
                  {t('account.form.lastName')}
                </PortalInputLabel>
                <PortalTextFieldNoShadow
                  fullWidth
                  type="text"
                  id="lastName"
                  name="last_name"
                  placeholder={customer.last_name}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} className={classes.button}>
              {t('buttons.cancel')}
            </Button>
            <Button className={classes.button} type="submit">
              {t('buttons.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default NameForm;
