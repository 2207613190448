import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { Typography, Box, Container } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';

// components
import Page from 'components/Page/Page';
import BlogCard from 'components/BlogCard/BlogCard';
import Section from 'components/Page/Section';
import { getBlogPosts } from 'redux/actions/blog';

const useStyles = makeStyles((theme) => ({
  posts: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  searchContainer: {
    margin: '0rem 0',
  },
  search: {
    position: 'relative',
    border: '1px solid',
    borderRadius: '50px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Blogs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const posts = useSelector((state) => state.blog.posts);
  // const [search, setSearch] = React.useState('');
  // const [staticPosts, setStatic] = React.useState([]); //Never changes value

  useEffect(() => {
    !posts && dispatch(getBlogPosts());
  }, []);

  // NOTE: currently only 4 blog posts, we do not need a search bar for 4 posts
  // const handleChange = (event) => { //takes care of the search
  //   const { value } = event.target;
  //   const filtered = staticPosts.filter((post) =>
  //     post.title.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setSearch(value);
  //   setPostArr([...filtered]);
  // };

  return (
    <Page>
      <Container maxWidth="lg">
        <Typography variant="h2">Exciting Recycling Blog</Typography>

        {/* <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
          <InputBase
            placeholder="Search…"
            value={search}
            onChange={handleChange}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </Box> */}
        <Section noMarginTop>
          <Box className={classes.posts}>
            {posts &&
              posts.map((post) => <BlogCard key={post.id} post={post} />)}
            {!posts && (
              <Typography variant="body1">{t('about.noPostsFound')}</Typography>
            )}
          </Box>
        </Section>
      </Container>
    </Page>
  );
};

export default Blogs;
