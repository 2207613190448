import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  boldTitle: {
    fontWeight: 600
  }
}))

export default function EcoTitle(props) {
  const classes = useStyles()
  const { regular, bold, variant, color } = props
  return (
    <Typography variant={variant} color={color}>
      {regular}
      <span className={classes.boldTitle}>{bold}</span>
    </Typography>
  )
}

EcoTitle.propTypes = {
  regular: PropTypes.string,
  bold: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string
}
