import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Box } from '@material-ui/core';

// assets
import ecoCount from 'assets/img/ecocount-machine.png';

const useStyles = makeStyles((theme) => ({
  intro: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  left: {
    '& img': {
      marginTop: theme.spacing(2),
      width: '85%',
      backgroundColor: 'white',
      borderRadius: '50%',
    },
  },
  right: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    '& h2': {
      fontSize: '78px',
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
      '& h2': { fontSize: '48px' },
    },
  },
  subHeading: {
    marginTop: theme.spacing(2),
  },
  recycling: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  reimagined: {
    display: 'flex',
    fontWeight: 500,
  },
  trademark: {
    fontSize: '1rem',
    paddingTop: theme.spacing(2),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& button:first-child': {
      marginRight: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(3),
      },
    },
    '& button': {
      marginBottom: theme.spacing(1),
    },
  },
  link: {
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: 'inherit',
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" className={classes.intro}>
      <Box className={classes.left}>
        <img src={ecoCount} alt="Recycletek EcoCount machine" />
      </Box>

      <Box className={classes.right}>
        <Typography variant="h1">
          <span className={classes.recycling}>{t('home.recycling')}</span>
          <span className={classes.reimagined}>
            {t('home.reimagined')}
            <span className={classes.trademark}>&#174;</span>
          </span>
        </Typography>
        <Typography className={classes.subHeading} variant="body2">
          <Trans>{t('home.recycletekProvidesAI')}</Trans>
        </Typography>
        <Box className={classes.buttonGroup}>
          <a href="/signup">
            <Button>{t('home.buttons.signup')}</Button>
          </a>
          <a href="/products">
            <Button>{t('home.buttons.products')}</Button>
          </a>
        </Box>
        <Typography variant="body2">
          {t('home.existingAccount')}{' '}
          <NavLink exact to="/login" className={classes.link}>
            {t('buttons.logIn')}
          </NavLink>{' '}
        </Typography>
      </Box>
    </Container>
  );
};

export default Header;
