import { SET_BLOG_POSTS } from '../actions/types.js';

const initialState = {
  posts: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOG_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    default:
      return state;
  }
};
