import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import GeoLocator from './GeoLocator';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  text: {
    fontWeight: 300,
    maxWidth: '465px',
    textAlign: 'justify',
  },
  hyperLink: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

const AdjustState = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const USState = useSelector((state) => state.customer.location.stateCode);
  const [open, setOpen] = useState(false);

  // TODO: set open as a proper toggle to reset state, not reset in geolocator
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>
        {t('common.notInState', { state: USState })}{' '}
        <a className={classes.hyperLink} onClick={handleOpen}>
          {t('common.clickHere')}
        </a>{' '}
        {t('common.toAdjust')}
      </Typography>
      <GeoLocator isOpen={open} close={handleClose} />
    </Box>
  );
};

export default AdjustState;
