import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// @material-ui/core components
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  leftOrRightArrow: {
    width: '100%',
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  downRightArrow: {
    width: '100%',
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  downLeftArrow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  arrow: {
    width: '200px',
    height: 'auto',
  },
}));

const Arrow = ({ type, src }) => {
  const classes = useStyles();

  const classNames = clsx({
    [classes.downRightArrow]: type === 'down-right',
    [classes.leftOrRightArrow]: type === 'default',
    [classes.downLeftArrow]: type === 'down-left',
  });

  return (
    <Hidden smDown>
      <div className={classNames}>
        <img className={classes.arrow} src={src} alt="Arrow" />
      </div>
    </Hidden>
  );
};

export default Arrow;
