import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, List, Typography, ListItem } from '@material-ui/core';
import BasicCard from 'components/Card/BasicCard';
import EnviroInfo from './Sections/EnviroInfo';
import Achievements from './Sections/Achievements';
import Percentage from './Sections/Percentage';

// assets
import car from 'assets/img/portal/car.svg';
import house from 'assets/img/portal/house.svg';
import cellPhone from 'assets/img/portal/cellPhone.svg';
import TotalGraph from 'components/Graphs/TotalGraph';
import TotalContainerGraph from 'components/Graphs/TotalContainerGraph';
import MetricDates from './Sections/MetricDates';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    maxWidth: '1400px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 100px',
      maxWidth: '1500px',
    },
  },
  cardTitle: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(21),
  },
  icon: {
    width: '100%',
    maxWidth: '200px',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  metricNumber: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(48),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: theme.typography.pxToRem(21),
    // },
  },
  metricText: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(21),
  },
  listText: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconBox: {
    width: '25%',
  },
  list: {
    marginTop: theme.spacing(2),
  },
  rightCol: {
    width: '100%',
  },
  datesContainers: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const icons = [
  {
    amount: 'gallons',
    metric: 'Gas consumption saved',
    icon: car,
    background: '#EFF9D7',
  },
  {
    metric: 'Homes powered for one day',
    icon: house,
    background: '#E2ECF0',
  },
  {
    metric: 'Smartphones charged',
    icon: cellPhone,
    background: '#E5FCFF',
  },
];

function Metrics(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const equivalencies = useSelector((state) => state.metrics.equivalencies);

  return (
    <div className={classes.container}>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12} sm={8} md={6}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Achievements />
              </Grid>

              <Grid item xs={12}>
                <Percentage />
              </Grid>

              <Grid item xs={12}>
                <BasicCard>
                  <Box p={3}>
                    <Box>
                      <Typography className={classes.cardTitle} color="primary">
                        {t('metrics.greenhouseGasEquivalencies')}
                      </Typography>
                    </Box>
                    <Box p={1}>
                      <List sx={{ width: '100%', maxWidth: 360 }}>
                        {icons.map((icon, index) => (
                          <ListItem className={classes.list} key={index}>
                            <Box className={classes.iconBox}>
                              <img className={classes.icon} src={icon.icon} />
                            </Box>
                            <Box>
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  component="span"
                                  className={classes.metricNumber}
                                  color="primary"
                                >
                                  {equivalencies[icon.metric]?.toFixed(2)}{' '}
                                  {icon.amount ? icon.amount : ''}
                                </Typography>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="primary"
                                >
                                  {icon.metric}
                                </Typography>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                </BasicCard>
              </Grid>
              {/*
              <Grid item xs={12}>
                <MoneyEarned />  // does not work at the moment, as the amounts per type are not calculated any more in the backend
              </Grid>
                        */}
            </Grid>
          </Box>
        </Grid>
        <Grid item container xs={12} sm={8} md={6}>
          <Box className={classes.rightCol}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EnviroInfo />
              </Grid>

              <Grid item xs={12}>
                <BasicCard>
                  <Box pt={3} data-cy="graphs-section">
                    <Box>
                      <Box mb={2} pl={3}>
                        <Typography
                          className={classes.cardTitle}
                          color="primary"
                        >
                          {t('metrics.redemptionHistory')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <TotalGraph />
                    </Box>
                    <Box mb={2}>
                      <TotalContainerGraph />
                    </Box>
                  </Box>
                </BasicCard>
              </Grid>
              <Grid className={classes.datesContainers} item container xs={12}>
                <Grid item xs={12}>
                  <MetricDates />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
export default Metrics;
