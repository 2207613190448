import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ALERT_FAIL } from 'redux/actions/types';

// Redux
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  createSinglePickUpRequest,
  createRecurringPickUpRequest,
} from 'redux/actions/pickups';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  FormHelperText,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// Utils
import { numBags } from 'lib/formValues';
import {
  isSameWeekday,
  checkIsLessThan48Hours,
  existsInRRule,
} from 'utils/dateFunctions';

// custom hook to encapsulate all form state functions
import useSchedulePickup from 'utils/use-schedulePickup';

// Components
import ScheduleFormDatePicker from 'components/DateTimePickers/ScheduleFormDatePicker';
import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import PickupTypeForm from './PickupTypeForm';
import ItemCountForm from './ItemCountForm';
import PaymentForm from './PaymentForm';
import ConfirmForm from './ConfirmForm';

// Lists for autocomplete forms
const bags = numBags(100);

const useStyles = makeStyles((theme) => ({
  dateInput: {
    minWidth: '185px',
  },
  recurringCheck: {
    color: '#797777',
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'none',
    paddingTop: '8px',
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  recurringFormControl: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  recurringCheckError: {
    maxWidth: '250px',
  },
}));

export const SchedulePickupForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pickups = useSelector((state) => state.pickups);
  const charities = useSelector((state) => state.charities.list);
  const paymentOptions = useSelector(
    (state) => state.transactions.paymentOptions,
    shallowEqual,
  );
  const [charityOptions, setCharityOptions] = useState([]);
  const [threeMonthDate, setThreeMonthDate] = useState(null);

  // custom hook for form and error values
  const {
    values,
    errors,
    setType,
    setTypeError,
    setSelectedDate,
    setSelectedDateError,
    setRecurringChecked,
    setRecurringCheckedError,
    setItemCount,
    setItemCountError,
    setPayment,
    setPaymentError,
    setCharity,
    setCharityError,
    setConfirm,
    setConfirmError,
    setFormValidationErrors,
    setValue,
    resetValues,
    resetErrors,
  } = useSchedulePickup();

  // set the charity list & three month out date
  useEffect(() => {
    const threeMonthDate = DateTime.local().plus({ months: 3 });

    // create the list of charity options
    const charityList = charities.map((charity) => charity.business_name);

    setCharityOptions([...charityList]);
    setThreeMonthDate(threeMonthDate);
    resetErrors();
    resetValues();
  }, [paymentOptions, pickups, charities]);

  const handleTypeChange = (event) => {
    if (!pickups.serviceType.bulk && !pickups.serviceType.bulk) {
      dispatch({
        type: ALERT_FAIL,
        payload: t('schedule.pickupForm.notAvailable'),
      });
      return;
    }

    const type = event.target.value;
    let payment = '';

    // if type === curbside set payment to charity
    if (type === 'curbside') {
      payment = 'charity';
    }

    // set type and payment and reset all other values
    setType({
      type,
      payment,
    });
    // reset all errors
    resetErrors();
  };

  // Returns an error on the type if user selects a day but hasn't picked a pickup type
  const handleDateClick = () => {
    console.log('error');
    setTypeError(true);
  };

  /**
   * checks if date occurs in one of the available recurring dates
   * @param {DateTime} date date as a luxon DateTime object
   * @returns {Boolean} true if it is a curbside date, false otherwise
   */
  const checkIsCurbsideDate = (date, pickups) => {
    const isCurbside = existsInRRule(date, [...pickups.availablePickupDays]);
    return isCurbside.exists;
  };

  /**
   * checks if a pickup date already exists on that date
   * @param {DateTime} date date as a luxon DateTime object
   * @returns {Boolean} true if pickup exists, false otherwise
   */
  const checkExistingDates = (date, pickups) => {
    const iosDate = date.toISODate();
    // check recurring dates
    const exists = existsInRRule(date, [...pickups.recurringPickupDays]);
    if (exists.exists) {
      return true;
    }

    // check single events
    for (let i = 0; i < pickups.singleEvents.length; i++) {
      if (pickups.singleEvents[i].date === iosDate) {
        return true;
      }
    }

    return false;
  };

  const validateSelectedDate = (date, pickups) => {
    const selectedDateError = {
      error: false,
      helperText: '',
    };

    if (checkIsLessThan48Hours(date, values.type)) {
      selectedDateError.error = true;
      if (values.type === 'curbside') {
        selectedDateError.helperText = t(
          'schedule.pickupForm.curbsideScheduledInAdvance',
        );
      } else if (values.type === 'bulk') {
        selectedDateError.helperText = t(
          'schedule.pickupForm.bulkScheduledInAdvance',
        );
      }
    } else if (threeMonthDate < date) {
      selectedDateError.error = true;
      selectedDateError.helperText = t('schedule.pickupForm.withinThreeMonths');
    } else if (isSameWeekday(7, date)) {
      selectedDateError.error = true;
      selectedDateError.helperText = t('schedule.pickupForm.noSundayPickups');
    } else if (checkExistingDates(date, pickups)) {
      selectedDateError.error = true;
      selectedDateError.helperText = t('schedule.pickupForm.alreadyScheduled');
    } else if (values.type === 'curbside') {
      if (!checkIsCurbsideDate(date, pickups)) {
        selectedDateError.error = true;
        selectedDateError.helperText = t(
          'schedule.pickupForm.notAvailbleThatDate',
        );
      }
    }

    return selectedDateError;
  };

  const handleDateSelect = (date) => {
    const dateErrors = {
      selectedDateError: {},
      recurringCheckedError: { error: false, helperText: '' },
    };

    // check that date is a valid luxon date
    if (date !== null && date.invalid === null) {
      // validate date & set date errors
      dateErrors.selectedDateError = validateSelectedDate(date, pickups);
      // if date is invalid luxon date set date error
    } else if (date.invalid) {
      dateErrors.selectedDateErrors = {
        error: true,
        helperText: t('schedule.pickupForm.enterValidDate'),
      };
    }

    // set date errors and reset recurring errors
    setSelectedDateError(dateErrors);
    // set date so that user can see selected date
    // reset recurringCheck in order to rerun validation for recurring
    setSelectedDate({ selectedDate: date, recurringChecked: false });
  };

  // checks that they are not already subscribed to recurring dates
  const validateRecurring = (day, pickups) => {
    for (let i = 0; i < pickups.recurringPickupDays.length; i++) {
      const recurringPickupDay = pickups.recurringPickupDays[i];
      // eslint-disable-next-line
      if (recurringPickupDay.availablepickupdays_id == day.id) {
        return true;
      }
    }
    return false;
  };

  // #To Do: separate all validation and setting check box
  // Validates and sets recurring check box
  const handleRecurringChecked = () => {
    let recurringChecked = true;
    let recurringDays = '';
    let recurringErrors = {
      error: false,
      helperText: '',
    };

    // get availableDay info recurring date occurs in
    const availableDay = existsInRRule(values.selectedDate, [
      ...pickups.availablePickupDays,
    ]);

    // set false if already checked
    if (values.recurringChecked) {
      recurringChecked = false;
    } else if (values.selectedDate === null || !availableDay.exists) {
      // if no date selected return
      recurringErrors = {
        error: true,
        helperText: 'Please select a curbside date',
      };
      recurringChecked = false;

      // check that they are not already subscribing to recurring date
    } else if (validateRecurring(availableDay.availableDay, pickups)) {
      recurringErrors = {
        error: true,
        helperText: 'You are already subscribed to selected recurring pickup',
      };
      recurringChecked = false;

      // else if recurring isn't already selected check that no dates are scheduled during recurring date
    } else if (!values.recurringChecked) {
      recurringDays = availableDay.availableDay;

      // check if any single days occur in selected recurringDay
      const days = [...pickups.singleEvents];
      const recurringLuxon = DateTime.fromISO(recurringDays.startdate);
      for (let i = 0; i < days.length; i++) {
        const existingPickupLuxon = DateTime.fromISO(days[i].date);

        if (recurringLuxon.weekday === existingPickupLuxon.weekday) {
          const beforeDate = recurringDays.rrule.before(
            existingPickupLuxon.toJSDate(),
            true,
          );

          const luxBefore = DateTime.fromJSDate(beforeDate)
            .toUTC()
            .startOf('day');
          if (luxBefore.hasSame(existingPickupLuxon, 'day')) {
            recurringErrors = {
              error: true,
              helperText: t('schedule.pickupForm.alreadyScheduledPleaseDelete'),
            };
            recurringChecked = false;
          }
        }
      }
    }

    setRecurringCheckedError(recurringErrors);
    setRecurringChecked({ recurringChecked, recurringDays });
  };

  /**
   * totals item amount in pickup
   * @param {Integer} superecosacks
   * @param {Integer} ecosacks
   * @param {Integer} ecokrates
   * @returns {Integer} total items
   */
  const itemTotal = (ecosacks, ecokrates, superecosacks) => {
    return ecosacks * 200 + ecokrates * 24 + superecosacks * 1500;
  };

  // checks that there are enough items for a bulk pickup
  const validateBulkItemAmount = (total) => {
    const itemCountError = {
      error: false,
      helperText: '',
    };
    if (total < 2500) {
      itemCountError.error = true;
      itemCountError.helperText = t('schedule.pickupForm.bulkPickupAtLeast');
    }

    return itemCountError;
  };

  // checks that there are enough items for a curbside pickup
  const validateCurbsideItemAmount = (total) => {
    const itemCountError = {
      error: false,
      helperText: '',
    };

    if (total < 100) {
      itemCountError.error = true;
      itemCountError.helperText = t(
        'schedule.pickupForm.curbsidePickupAtLeast',
      );
    } else if (total > 2500) {
      itemCountError.error = true;
      itemCountError.helperText = t(
        'schedule.pickupForm.curbsidePickupLessThan',
      );
    }

    return itemCountError;
  };

  // sets item count and validates count
  const handleItemCount = (name, newValue) => {
    let total = '';
    if (name === 'superecosacks') {
      total = itemTotal(values.ecosacks, values.ecokrates, newValue);
    } else if (name === 'ecosacks') {
      total = itemTotal(newValue, values.ecokrates, values.superecosacks);
    } else {
      total = itemTotal(values.ecosacks, newValue, values.superecosacks);
    }

    if (values.type === '') {
      setTypeError(true);
      return;
    }
    let itemCountError = {};

    switch (values.type) {
      case 'curbside':
        itemCountError = validateCurbsideItemAmount(total);
        break;
      case 'bulk':
        itemCountError = validateBulkItemAmount(total);
        break;
      default:
        break;
    }
    setItemCountError(itemCountError);
    setItemCount(name, newValue);
  };

  const handlePaymentChange = (e) => {
    const { value } = e.target;
    let charityError = false;
    if (value === 'charity') {
      charityError = true;
    }

    const payment = { payment: value, charity: null };
    const newErrors = { paymentError: false, charityError };
    setPaymentError(newErrors);
    setPayment(payment);
  };

  const handleCharityChange = (value) => {
    setCharityError(false);
    setCharity(value);
  };

  const handleConfirm = () => {
    if (errors.confirmError) {
      setConfirmError();
    }
    setConfirm();
  };

  const validateForm = () => {
    // determines if there is an error
    let error = false;
    const formErrors = {};

    // get total number of items
    const total = itemTotal(
      values.ecosacks,
      values.ecokrates,
      values.superecosacks,
    );

    // check user has selected a pickup type and pickup has enough items
    if (values.type === '') {
      formErrors.typeError = true;
      error = true;
    }

    if (values.selectedDate === null && !errors.selectedDateError.error) {
      formErrors.selectedDateError = {
        error: true,
        helperText: t('schedule.pickupForm.enterPickupDate'),
      };
      error = true;
    } else if (errors.selectedDateError.error) {
      error = true;
    }

    // check that a payment type is selected
    if (values.payment === '') {
      formErrors.paymentError = true;
      error = true;
    }

    // if payment is charity then check there is a charity
    if (values.payment === 'charity' && values.charity === null) {
      formErrors.charityError = true;
      error = true;
    }

    if (!values.confirm) {
      formErrors.confirmError = true;
      error = true;
    }

    // check that total isn't blank and correct amount is given
    let itemCountError = {};
    if (total === 0) {
      formErrors.itemCountError = {
        error: true,
        helperText: t('schedule.pickupForm.selectAmountToPickup'),
      };
      error = true;
    } else if (values.type === 'curbside') {
      itemCountError = validateCurbsideItemAmount(total);
    } else if (values.type === 'bulk') {
      itemCountError = validateBulkItemAmount(total);
    }

    if (itemCountError.error) {
      formErrors.itemCountError = itemCountError;
      error = true;
    }

    setFormValidationErrors(formErrors);
    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      submit();
    }
  };

  /**
   * returns paymentoptions_id from payment type
   * @returns {Integer} paymentoptions_id
   */
  const getPaymentId = () => {
    let paymentoptions_id;
    let option;

    if (values.payment === 'charity') {
      option = paymentOptions.find((option) => option.name === 'donate');
      paymentoptions_id = option.id;
    } else if (values.payment !== 'charity') {
      option = paymentOptions.find((option) => option.name === 'keep');
      paymentoptions_id = option.id;
    }

    return paymentoptions_id;
  };

  // forms pickupDetails object, dispatches to server, resets form and errors
  const submit = () => {
    const pickupDetails = {
      instructions: values.instructions,
      num_eco_sacks: values.ecosacks === null ? 0 : values.ecosacks,
      num_eco_krates: values.ecokrates === null ? 0 : values.ecokrates,
      num_eco_super_sacks:
        values.superecosacks === null ? 0 : values.superecosacks,
      bulk: values.type === 'bulk',
      curbside: values.type === 'curbside',
    };

    // get the payment id
    pickupDetails.paymentoptions_id = getPaymentId();

    // change luxon date object to ios format for backend
    pickupDetails.date = values.selectedDate.toISODate();
    // add available id day for recurring pickup
    if (values.recurringChecked && values.type === 'curbside') {
      pickupDetails.availablepickupdays_id = values.recurringDays.id;
      dispatch(createRecurringPickUpRequest(pickupDetails));
    } else {
      dispatch(createSinglePickUpRequest(pickupDetails));
    }
    resetErrors();
    resetValues();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justify="flex-start" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <PickupTypeForm
            value={values.type}
            handleTypeChange={handleTypeChange}
            error={errors.typeError}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.dateInput} data-cy="date-input">
            <PortalInputLabel classes={{ root: classes.label }}>
              {t('schedule.pickupForm.pickupDate')}
            </PortalInputLabel>
            <ScheduleFormDatePicker
              threeMonthDate={threeMonthDate}
              placeholder={'__/__/____'}
              selectedDate={values.selectedDate}
              handleDateSelect={handleDateSelect}
              handleClick={handleDateClick}
              type={values.type}
              error={errors.selectedDateError.error}
              helperText={errors.selectedDateError.helperText}
            />
          </Box>
          {values.type === 'curbside' && values.selectedDate !== null ? (
            <Grid item xs={12}>
              <Box mt={2}>
                <PortalInputLabel
                  classes={{ root: classes.label }}
                  id="recurring"
                >
                  {t('schedule.pickupForm.wantToMakeBiWeeklyPickup')}
                </PortalInputLabel>
              </Box>
              <Box ml={1}>
                <FormControlLabel
                  classes={{ root: classes.recurringFormControl }}
                  control={
                    <Checkbox
                      checked={values.recurringChecked}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      onChange={handleRecurringChecked}
                      name="recurring"
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      className={classes.recurringCheck}
                    >
                      {t('schedule.pickupForm.yesPlease')}
                    </Typography>
                  }
                />
              </Box>
              <FormHelperText
                className={classes.recurringCheckError}
                error={errors.recurringCheckedError.error}
              >
                {errors.recurringCheckedError.error
                  ? errors.recurringCheckedError.helperText
                  : ''}
              </FormHelperText>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <ItemCountForm
            ecosacks={values.ecosacks}
            ecokrates={values.ecokrates}
            superecosacks={values.superecosacks}
            list={bags}
            handleItemCount={handleItemCount}
            error={errors.itemCountError.error}
            helperText={errors.itemCountError.helperText}
          />
        </Grid>
        <Grid item xs={12}>
          <Box my={1}>
            <Typography>
              {t('schedule.pickupForm.instructionsForPickupDriver')}
            </Typography>
          </Box>
          <FormControl fullWidth>
            <PortalTextFieldNoShadow
              name="instructions"
              placeholder={t('schedule.pickupForm.instructionsPlaceholder')}
              multiline
              rows={3}
              value={values.instructions}
              onChange={(e) => setValue(e.target.name, e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box my={1}>
            <PaymentForm
              type={values.type}
              value={values.payment}
              charity={values.charity}
              list={charityOptions}
              handlePaymentChange={handlePaymentChange}
              handleCharityChange={(event, newValue) => {
                handleCharityChange(newValue);
              }}
              charityError={errors.charityError}
              paymentError={errors.paymentError}
            />
          </Box>
        </Grid>
        <ConfirmForm
          type={values.type}
          confirm={values.confirm}
          confirmError={errors.confirmError}
          handleConfirm={handleConfirm}
        />
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row-reverse">
            <Button variant="contained" type="submit">
              {t('buttons.submit')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

SchedulePickupForm.propTypes = {};
