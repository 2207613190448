import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';

const DashTabs = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  flexContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },
}))(Tabs);

export default DashTabs;
