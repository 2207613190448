import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Toolbar } from '@material-ui/core';

// components
import FAQList from './Sections/FAQList';
import ContactCallout from './Sections/ContactCallout';

// lib
import { faqs, stateBasedFAQs } from 'lib/faqList';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1000px',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
}));

const FAQ = (props) => {
  const classes = useStyles();
  const USState = useSelector((state) => state.customer.location.stateCode);

  return (
    <div>
      <Toolbar />
      <div className={classes.container}>
        <FAQList faqs={USState ? faqs.concat(stateBasedFAQs[USState]) : faqs} />
        <ContactCallout />
      </div>
    </div>
  );
};

export default FAQ;
