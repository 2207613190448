import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  TextField,
  FormHelperText,
  Tooltip,
  Badge,
  InputLabel,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';
import PortalInputLabel from 'components/TextField/PortalInputLabel';

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  subtitle: {
    marginLeft: '5px',
    fontSize: theme.typography.pxToRem(12),
  },
  subLabel: {
    display: 'inline-block',
  },
  labelSuper: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  helpLink: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '-14px',
    top: '-3px',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))(Badge);

export default function ItemCountForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const boxShadow = 'none';

  return (
    <React.Fragment>
      <Box my={1}>
        <Typography>
          {t('schedule.itemCountForm.estimatePickupAmount')}
          <HtmlTooltip
            enterTouchDelay={0}
            interactive
            arrow
            onClick={(e) => e.preventDefault()}
            title={
              <React.Fragment>
                <p>{t('schedule.itemCountForm.noteActualCount')}</p>
                <p>
                  {t('schedule.itemCountForm.notSureWhatReceptacle')}{' '}
                  <NavHashLink
                    className={classes.helpLink}
                    to={'/portal/faq#8'}
                  >
                    {t('common.faq')}
                  </NavHashLink>{' '}
                  {t('schedule.itemCountForm.pageForHelpfulGuide')}
                </p>
              </React.Fragment>
            }
          >
            <StyledBadge badgeContent={'?'} color="secondary" />
          </HtmlTooltip>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Box ml={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <InputLabel
              classes={{ root: classes.labelSuper }}
              id="ecosacks-label"
            >
              {t('schedule.itemCountForm.ecoSacks')}
            </InputLabel>
          </Box>
          <CustomAutoComplete
            boxShadow={boxShadow}
            placeholder="0"
            disabled={false}
            id="ecosacks"
            list={props.list}
            value={props.ecosacks}
            onChange={(event, newValue) => {
              props.handleItemCount('ecosacks', newValue);
            }}
            style={{ maxWidth: 95 }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField {...params.inputProps} />
              </div>
            )}
          />
          <Box style={{ maxWidth: 85 }}>
            <Typography align="center" className={classes.subtitle}>
              {t('schedule.itemCountForm.ecoSacksQuantity')}
            </Typography>
          </Box>
        </Box>
        <Box ml={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <InputLabel
              classes={{ root: classes.labelSuper }}
              id="ecokrates-label"
            >
              {t('schedule.itemCountForm.ecoKrates')}
            </InputLabel>
          </Box>
          <CustomAutoComplete
            boxShadow={boxShadow}
            placeholder="0"
            disabled={false}
            id="ecokrates"
            name="ecokrates"
            list={props.list}
            value={props.ecokrates}
            onChange={(event, newValue) => {
              props.handleItemCount('ecokrates', newValue);
            }}
            style={{ maxWidth: 95 }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField {...params.inputProps} />
              </div>
            )}
          />
          <Box style={{ maxWidth: 85 }}>
            <Typography align="center" className={classes.subtitle}>
              {t('schedule.itemCountForm.ecoKratesQuantity')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <FormHelperText error={props.error}>
        {props.error ? props.helperText : ''}
      </FormHelperText>
    </React.Fragment>
  );
}

ItemCountForm.propTypes = {
  handleTypeChange: PropTypes.func,
  error: PropTypes.bool,
  value: PropTypes.string,
};
