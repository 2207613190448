import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const useStylesInput = makeStyles((theme) => ({
  label: {
    marginLeft: theme.spacing(1.5),
    marginBottom: '8px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
}));

function PortalInputLabel(props) {
  const classes = useStylesInput();

  return (
    <InputLabel classes={{ root: classes.label }}>{props.children}</InputLabel>
  );
}

export default PortalInputLabel;
