import {
  CREATE_PICK_UPS,
  CREATE_RECURRING_PICKUPS,
  CREATE_SKIP_REQUEST,
  DELETE_PICK_UPS,
  GET_PICK_UPS,
  SET_AVAILABLE_PICKUP_DAYS,
  SET_SERVICE_TYPE,
  RESET_PICKUPS,
} from 'redux/actions/types';

import { createAvailableDaysRRule } from 'utils/dateFunctions.js';

import { createAvailableDaysEvent } from 'utils/eventFunctions';

const initialState = {
  nearest: {},
  confirmations: [],
  recurringEvents: [],
  recurringPickupDays: [],
  singleEvents: [],
  singlePickupDays: [],
  availablePickupEvents: [],
  availablePickupDays: [],
  skipEvents: [],
  serviceType: {},
  needConfirmation: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AVAILABLE_PICKUP_DAYS:
      return {
        ...state,
        availablePickupEvents: createAvailableDaysEvent([...action.payload]),
        availablePickupDays: createAvailableDaysRRule([...action.payload]),
      };
    case CREATE_PICK_UPS:
    case CREATE_RECURRING_PICKUPS:
    case DELETE_PICK_UPS:
    case CREATE_SKIP_REQUEST:
    case GET_PICK_UPS:
      return {
        ...state,
        singlePickupDays: [...action.payload.single],
        singleEvents: [...action.payload.singleEvents],
        recurringEvents: [...action.payload.recurringEvents],
        recurringPickupDays: [...action.payload.recurring],
        skipEvents: [...action.payload.skipEvents],
        confirmations: { ...action.payload.confirmations },
        needConfirmation: action.payload.needConfirmation,
        nearest: action.payload.nearest,
      };
    case SET_SERVICE_TYPE:
      return {
        ...state,
        serviceType: action.payload,
      };
    case RESET_PICKUPS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
