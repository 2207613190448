import axios from 'axios';
import { SERVER_URL } from '../config';
function returnDefaultConfig() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return config;
}

const Customer = {
  isServiceable: async (zipcode) => {
    const config = returnDefaultConfig();
    return axios.get(`${SERVER_URL}/customer/check_zipcode/${zipcode}`, config);
  },
  isMobile: async (phoneNumber) => {
    const config = returnDefaultConfig();
    return axios.get(
      `${SERVER_URL}/customer/check_mobilephone/${phoneNumber}`,
      config,
    );
  },
  isMobileUnique: async (phoneNumber) => {
    const config = returnDefaultConfig();
    return axios.get(
      `${SERVER_URL}/customer/check_unique_phone/${phoneNumber}`,
      config,
    );
  },
  subscribeAppNotification: async (values) => {
    axios.post(
      `${SERVER_URL}/customer/insertMobileAppNotificationEmail`,
      values,
    );
  },
  joinList: async (values) => {
    axios.post(`${SERVER_URL}/contact/list`, values);
  },
  isUniqueEmail: async (email) => {
    const config = returnDefaultConfig();
    return axios.get(`${SERVER_URL}/customer/check_unique/${email}`, config);
  },
};

export default Customer;
