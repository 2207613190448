import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MemberPortalNav from 'components/Nav/MemberPortalNav';
import SideDrawer from 'components/SideDrawer/SideDrawer';
import { OPEN_SIDEBAR } from 'redux/actions/types';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: drawerWidth,
    boxShadow: theme.shadows[10],
  },
  content: {
    backgroundColor: theme.palette.background.portal,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',
    marginLeft: 0,
    [theme.breakpoints.up('lg')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    [theme.breakpoints.up('lg')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
}));

function MemberPortalPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { sidebarOpen } = useSelector((state) => state.sidebar, shallowEqual);
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    dispatch({
      type: OPEN_SIDEBAR,
    });
  };
  const handleMobileDrawerToggle = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMobileOpen((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <MemberPortalNav>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={matches ? handleDrawerToggle : handleMobileDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </MemberPortalNav>
      <div className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleMobileDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on o
            }}
          >
            <SideDrawer handleMobileDrawerToggle={handleMobileDrawerToggle} />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open={sidebarOpen}
          >
            <SideDrawer />
          </Drawer>
        </Hidden>
      </div>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

export default MemberPortalPage;

MemberPortalPage.propTypes = {
  children: PropTypes.node,
};
