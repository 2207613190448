import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  deleteRecurringPickup,
  createSkipRequest,
} from 'redux/actions/pickups';

// date libraries
import { DateTime } from 'luxon';
import { rrulestr } from 'rrule';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import theme from 'themes/memberPortalTheme';

// components
import MetricsByPickupInfo from './MetricsByPickupInfo';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  pickup: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.primary,
    paddingRight: theme.spacing(6),
    fontWeight: 300,
  },
  info: {
    fontWeight: 300,
  },
  editButton: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  subscript: {
    verticalAlign: 'sub',
  },
  icon: {
    width: 15,
  },
}));

function MetricDayListItem(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const { metric } = props;

  const [open, setOpen] = React.useState(false);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem
        button
        classes={{ root: classes.listRoot }}
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography color="primary">
            {DateTime.fromISO(metric[0]).toLocaleString()}
          </Typography>
          <Typography className={classes.title}>
            {t('metrics.totalContainers')} {metric[1].total}
          </Typography>
          <Typography className={classes.title}>
            {t('metrics.total')} CO<span className={classes.subscript}>2</span>{' '}
            {t('metrics.emissionsAvoided')}:{' '}
            {(metric[1].saved / 1000).toFixed(2)} Kg
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box pb={3}>
          {metric[1].Plastic ? (
            <MetricsByPickupInfo
              type="plastic"
              material={t('common.plasticCap')}
              containerCount={metric[1].Plastic.count}
              emissionsAvoided={(metric[1].Plastic.co2_saved_g / 1000).toFixed(
                2,
              )}
            />
          ) : null}
          {metric[1].Aluminum ? (
            <MetricsByPickupInfo
              type="aluminum"
              material={t('common.aluminumCap')}
              containerCount={metric[1].Aluminum.count}
              emissionsAvoided={(metric[1].Aluminum.co2_saved_g / 1000).toFixed(
                2,
              )}
            />
          ) : null}
          {metric[1].Glass ? (
            <MetricsByPickupInfo
              type="glass"
              material={t('common.glassCap')}
              containerCount={metric[1].Glass.count}
              emissionsAvoided={(metric[1].Glass.co2_saved_g / 1000).toFixed(2)}
            />
          ) : null}
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default MetricDayListItem;
