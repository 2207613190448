import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, NavLink } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getTenantByShortcode } from 'redux/actions/verification';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Paper } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

// form components
import CreateAccountWindow from '../LoginSignUp/Forms/CreateAccountWindow';
import PromotionForm from './Forms/PromotionForm';
import EmailVerificationForm from './Forms/EmailVerificationForm';
import SelectATenantWindow from './Forms/SelectATenantWindow';

// styles
const useStyles = makeStyles((theme) => ({
  signupContainer: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3),
    backgroundColor: 'transparent',
    maxWidth: 550,
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 300,
    marginBottom: '.5rem',
  },
  subtitle: {
    color: theme.palette.tertiary.main,
  },
  bottomText: {
    fontSize: theme.typography.pxToRem(12),
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

export default function SignUp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const [showTenantCodes, setShowTenantCodes] = useState(false);
  const [associatedSignup, setAssociatedSignup] = useState('');

  useEffect(() => {
    checkTenantByShortCode();
  }, []);


  const formatBusinessName = (business) => {
    // some special characters show up in html as the unconverted character code eg. &amp;
    const formatted = business.replace('&', ' and ');

    setAssociatedSignup(formatted);
  };

  // note: a tenant is typically a redemption center
  const checkTenantByShortCode = () => {
    const hash = window.location.href.split('#')[1];

    if (hash) {
      dispatch(getTenantByShortcode(hash))
        .then((business) => {
          formatBusinessName(business);
        })
        .catch((res) => {
          setShowTenantCodes(true);
        });
    }
  };

  const hideTenantSelect = () => {
    setShowTenantCodes(false);
  };

  if (auth.isAuthenticated) {
    return <Redirect to="/portal/dashboard" />;
  }

  return (
    <Paper className={classes.signupContainer}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <React.Fragment>
            <Box>
              <Typography
                className={classes.title}
                color="primary"
                variant="h3"
              >
                {t('signup.joinUs')}
              </Typography>
              {associatedSignup && (
                <Typography className={classes.subtitle} variant="body1">
                  {t('signup.invitedBy', { business: associatedSignup })}
                </Typography>
              )}
              {!associatedSignup && (
                <Typography className={classes.subtitle} variant="body1">
                  {t('signup.weCanChangeTheWorld')}
                </Typography>
              )}
              {!showTenantCodes && <CreateAccountWindow />}
              {showTenantCodes && (
                <SelectATenantWindow hideTenantSelect={hideTenantSelect} />
              )}
            </Box>
          </React.Fragment>
        </Grid>
      </Grid>
    </Paper>
  );
}
