// content that is US state or location sensitive

export const content = {
  ['NY']: {
    state: 'common.states.newYork', // 'New York'
    acceptedContainers: {
      generalRule: 'what.newYork.accepted.generalRule',
      list: [
        'what.newYork.accepted.water',
        'what.newYork.accepted.sparklingSodaWater',
        'what.newYork.accepted.mineralWater',
        'what.newYork.accepted.flavoredWater',
        'what.newYork.accepted.beerOrMalt',
        'what.newYork.accepted.carbonatedTea',
        'what.newYork.accepted.carbonatedEnergy',
        'what.newYork.accepted.carbonatedJuice',
        'what.newYork.accepted.wineCoolers',
      ],
    },
    unacceptedContainers: {
      generalRule: 'what.newYork.unaccepted.generalRule',
      list: [
        'what.newYork.unaccepted.milk',
        'what.newYork.unaccepted.wineLiquorsCiders',
        'what.newYork.unaccepted.nonCarbonatedTeaAndJuice',
        'what.newYork.unaccepted.nonCarbonatedSportsEnergy',
        'what.newYork.unaccepted.drinkBoxes',
        'what.newYork.unaccepted.waterContainingSugar',
      ],
    },
    otherUnacceptedContainers: [
      'what.newYork.otherUnaccepted.noRefundValueIndicated',
      'what.newYork.otherUnaccepted.brokenBottles',
      'what.newYork.otherUnaccepted.crushedContainers',
      'what.newYork.otherUnaccepted.corrodedOrDismemberedCans',
      'what.newYork.otherUnaccepted.containersUnknownMaterial',
    ],
  },
  ['CA']: {
    state: 'common.states.california',
    acceptedContainers: {
      generalRule: 'what.california.accepted.generalRule',
      list: [
        'what.california.accepted.beerOrMalt',
        'what.california.accepted.distilledSpirits',
        'what.california.accepted.wine',
        'what.california.accepted.wineCoolers',
        'what.california.accepted.mineralWater',
        'what.california.accepted.carbonatedSoftDrinks',
        'what.california.accepted.nonCarbonatedSportsDrinks',
        'what.california.accepted.nonCarbonatedFruitDrinks',
        'what.california.accepted.carbonatedFruitDrinks',
        'what.california.accepted.coffeeAndTea',
        'what.california.accepted.vegetableJuice',
        'what.california.accepted.fruitJuice',
      ],
    },
    unacceptedContainers: {
      generalRule: 'what.california.unaccepted.generalRule',
      list: [
        'what.california.unaccepted.notAluminumGlassOrPlastic',
        'what.california.unaccepted.milk',
        'what.california.unaccepted.medicalFood',
        'what.california.unaccepted.instantFormula',
        'what.california.unaccepted.notIncludedInTheAct',
      ],
    },
    otherUnacceptedContainers: [
      'what.california.otherUnaccepted.noRefundIndicated',
      'what.california.otherUnaccepted.brokenBottles',
      'what.california.otherUnaccepted.crushedContainers',
      'what.california.otherUnaccepted.corrodedOrDismemberedCans',
      'what.california.otherUnaccepted.containsUnknownMaterial',
    ],
  },
};
