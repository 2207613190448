import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Container } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import GoogleTranslator from 'components/GoogleTranslator/GoogleTranslator';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    margin: theme.spacing(8, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4, 0),
      fontSize: theme.typography.pxToRem(18),
    },
  },
  listTitle: {
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const TermsAndConditions = (props) => {
  const classes = useStyles();

  return (
    <Page>
      <div className={classes.main}>
        <Container maxWidth="md" className={classes.container}>
          <Box>
            <Typography align="center" component="h2" className={classes.title}>
              TERMS AND CONDITIONS APPLY TO RESIDENTIAL AND COMMERCIAL RECYCLING
              SERVICES
              <br />
              AS MAY BE PROVIDED BY EVTEK, Inc. (“Company”)
              <br />
              THIS IS A LEGALLY BINDING AGREEMENT.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              1. SERVICE PROVIDED
            </Typography>
            <Typography className={classes.text}>
              Customer grants to Company the exclusive right to collect and
              recycle and/or dispose of all of Customer's Recycling Materials.
              Customer represents and warrants that the materials to be
              collected under this Agreement shall be only "Recycling Materials"
              as defined below. For purposes of this Agreement, "Recycling
              Materials" means only New York State Redemption Containers
              composed of aluminum, glass, or plastic generated by Customer or
              at Customer's Service Address. Prohibited Material include but are
              not limited to: all non-New York State Redemption containers,
              paper or cardboard, aluminum food and beverage containers, ferrous
              (iron) or steel cans, aerosol cans, rigid container plastics #1-7,
              foam, film plastics, plastic bags, solid waste, organic waste,
              e-waste, Hazardous Waste or Household Hazardous Waste, such as
              industrial process wastes, asbestos-containing material,
              contaminated soils, demolition debris, white goods (refrigerators,
              air conditioners, dehumidifiers, etc.), toxic substances, waste
              tires, radioactive, flammable, explosive, corrosive, infectious,
              bio-hazardous, and or any other material where disposal is
              regulated by federal, state or local laws or ordinances. Any
              material not specifically set forth above, including but not
              limited to New York State Redemption Containers that have been in
              contact with food, is unacceptable. Ownership of and liability for
              Prohibited Material shall remain with Customer at all times.
              Customer will be responsible for proper disposal of all Prohibited
              Materials. Ownership of Customer's Recycling Materials is
              transferred to Company upon Company's receipt or collection unless
              otherwise provided in this Agreement or applicable law. The
              Customer will ensure that all bags, containers, and loads are safe
              for road transportation. No containers or bags should be filled
              above the top level indicated on the container; any loads over the
              fill level will not be collected or exchanged by the Company. If
              the overfill cannot be rectified by the Customer during a 5 minute
              period, a wasted journey charge as stated in Section 16, may be
              levied. Unless otherwise noted, all material must be separated by
              type into Company Smart Bags, known as EcoSacks or EcoKrates.
              Customer missorting of more than 10% by weight per Smart Bag may
              result in forfeit of the value of the entire bag. Customer
              missorting of more than 10% on 3 or more independent calendar
              dates in a given year may result in suspension of contract.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              2. CONTRACT TERM
            </Typography>
            <Typography className={classes.text}>
              Walk-in and or Drop-off services are provided at will. Residential
              services are provided on a month to month basis. Business services
              are provided on a 12-month term agreement subject to early
              cancellation fee of any outstanding setup costs plus any remaining
              service charges (not to exceed 6 months of service charges).
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              3. SERVICE CHANGES
            </Typography>
            <Typography className={classes.text}>
              Customer and Company may agree to changes relating to service
              levels (schedule, quantity, frequency), type of equipment, types
              of services, and rates related to these changes through payment of
              invoice, in writing, or orally. These changes do not change any
              other terms of service, which will remain in effect for the
              duration of the contract.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              4. INVOICES; PAYMENTS; CREDITS
            </Typography>
            <Typography className={classes.text}>
              Customer shall pay any and all payments due the Company for
              services and/or equipment provided to Customer. Company reserves
              the right to increase the Payments payable by Customer during the
              Term: (a) changes in service (b) changes in container size (c) to
              cover any increases in disposal costs (d) to cover increased costs
              due to uncontrollable circumstances, including but not limited to:
              changes in taxes, operating expenses, fuel cost, and federal,
              state, or local laws or regulations. All parties agree that this
              Agreement as so adjusted will continue in full force and effect.
              Customer invoice balances not paid within thirty (30) days of the
              date of invoice will be subject to a late fee. Customer checks
              that are returned for insufficient funds will be subject to an
              Insufficient Funds fee. Company retains the right to suspend
              service to the Customer on balances past due over thirty (30) days
              until balance is paid in full. If service is suspended for a
              period longer than 30 days the Company may terminate this
              Agreement and recover the amount owed, any equipment used for
              service, and any remaining payments due the Company under the
              agreement.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>5. EQUIPMENT</Typography>
            <Typography className={classes.text}>
              All equipment provided to Customer by Company shall remain the
              property of the Company. Customer will be responsible for
              maintenance, custody and contents of the equipment while it is on
              the Customer’s property. Customer is liable for theft or damage of
              the container throughout the duration of this contract. Customer
              must not modify, alter, or overload containers. Customer shall
              return the equipment to Company in the condition in which it was
              provided when this Agreement terminates. Customer will not be held
              responsible for normal wear and tear on the container during the
              term of the Agreement. Customer is responsible for providing the
              Company with clear, unobstructed access to the equipment for
              service. Customer's failure to provide access may result in a
              Return Service Fee which shall be paid by the Customer. By
              participating in this Agreement, Customer certifies that Customer
              property is able to support the weight of the equipment used for
              providing service. Company shall not be held responsible for
              damage to the Customer ground surfaces as a result of providing
              service.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>6. INDEMNITY</Typography>
            <Typography className={classes.text}>
              The Company agrees to indemnify, defend and save Customer, its
              parent, subsidiaries, and corporate affiliates, harmless from and
              against any and all liability which Customer may be responsible
              for or pay out as a result of bodily injuries (including death),
              property damage, or any violation or alleged violation of law, to
              the extent caused by any negligent act or omission or willful
              misconduct of the Company or its employees, which occurs (a)
              during the collection or transportation of Customer's Recycling
              Materials, or (b) as a result of the disposal of Customer's
              Recycling Materials, provided that the Company's indemnification
              obligations will not apply to occurrences involving Excluded
              Materials. Customer agrees to indemnify, defend and save the
              Company, its parent, subsidiaries, corporate affiliates and their
              joint venture partners, harmless from and against any and all
              liability which the Company may be responsible for or pay out as a
              result of bodily injuries (including death), property damage, or
              any violation or alleged violation of law to the extent caused by
              Customer's breach of this Agreement or by any negligent act or
              omission or willful misconduct of the Customer or its employees,
              agents or contractors or Customer's use, operation or possession
              of any equipment furnished by the Company. Neither party shall be
              liable to the other for consequential, incidental or punitive
              damages arising out of the performance or breach of this
              Agreement.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              7. ACTS OF GOD
            </Typography>
            <Typography className={classes.text}>
              Except for the obligation to make payments, neither party shall be
              in violation of the Agreement for a failure to perform or delay in
              performance caused by events outside of reasonable control. This
              includes but is not limited to: weather, fires, acts of god,
              strikes, labor trouble, riots, imposition of laws or governmental
              orders, fires, acts of war or terrorism, acts of God, and the
              inability to obtain equipment, and the affected party shall be
              excused from performance during the occurrence of such events.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              8. WEBSITE APPLICATION CONDITION OF USE
            </Typography>
            <Typography className={classes.text}>
              By using this website, you certify that you have read and reviewed
              this Agreement and that you agree to comply with its terms. If you
              do not want to be bound by the terms of this Agreement, you are
              advised to leave the website accordingly. Company only grants use
              and access of this website, its products, and its services to
              those who have accepted its terms.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              9. PRIVACY POLICY
            </Typography>
            <Typography className={classes.text}>
              Before you continue using our website, we advise you to read our
              <a className={classes.link} href="/privacy-policy">
                {' '}
                Privacy Policy
              </a>{' '}
              regarding our user data collection. It will help you better
              understand our practices.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              10. AGE RESTRICTION
            </Typography>
            <Typography className={classes.text}>
              You must be at least 18 (eighteen) years of age before you can use
              this website. By using this website, you warrant that you are at
              least 18 years of age and you may legally adhere to this
              Agreement. Company assumes no responsibility for liabilities
              related to age misrepresentation.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              11. INTELLECTUAL PROPERTY
            </Typography>
            <Typography className={classes.text}>
              You agree that all materials, products, and services provided on
              this website are the property of the Company, its affiliates,
              directors, officers, employees, agents, suppliers, or licensors
              including all copyrights, trade secrets, trademarks, patents, and
              other intellectual property. You also agree that you will not
              reproduce or redistribute the Company’s intellectual property in
              any way, including electronic, digital, or new trademark
              registrations. You grant the Company a royalty-free and
              non-exclusive license to display, use, copy, transmit, and
              broadcast the content you upload and publish. For issues regarding
              intellectual property claims, you should contact the Company in
              order to come to an agreement.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              12. USER ACCOUNTS
            </Typography>
            <Typography className={classes.text}>
              As a user of this website, you may be asked to register with us
              and provide private information. You are responsible for ensuring
              the accuracy of this information, and you are responsible for
              maintaining the safety and security of your identifying
              information. You are also responsible for all activities that
              occur under your account or password. If you think there are any
              possible issues regarding the security of your account on the
              website, inform us immediately so we may address them accordingly.
              We reserve all rights to terminate accounts, edit or remove
              content and cancel orders at our sole discretion.
            </Typography>
            <Box mt={2}>
              <Typography className={classes.text}>
                If you register to create an account to receive payments via our
                application, you expressly authorize info@evtek.co service
                provider, Dwolla, Inc. to originate credit transfers to your
                financial institution account. You must be at least 13 years old
                and obtain parental permission if under 18 to receive funds. You
                authorize info@evtek.co to collect and share with Dwolla your
                personal information including full name, email address and
                financial information, and you are responsible for the accuracy
                and completeness of that data. Dwolla’s Privacy Policy is
                available{' '}
                <a
                  className={classes.link}
                  href="https://www.dwolla.com/legal/privacy/"
                  target="_blank"
                >
                  here
                </a>
                .
              </Typography>
            </Box>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>13. DONATION</Typography>
            <Typography className={classes.text}>
              We will use your donations at our discretion but within our stated
              charitable objectives. A portion of all donations will be utilized
              for service and product expenses associated with the collection
              and processing of recyclable materials. On average, the costs
              associated with the collection and processing of each individual
              item is $0.025 not including handling fees, however, this amount
              is neither an official value nor derived from official accounting
              calculations and is subject to change without notice. Third party
              processing fees associated with the electronic transfer of
              monetary donations derived from the redemption value of containers
              will additionally be deducted from the original donation amount.
              Once the Company receives a donation, it cannot provide a refund
              of that donation.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              14. APPLICABLE LAWS
            </Typography>
            <Typography className={classes.text}>
              By visiting this website, you agree that the laws of the STATE OF
              NEW YORK, without regard to principles of conflict laws, will
              govern these terms and conditions, or any dispute of any sort that
              might come between the Company and you, or its business partners
              and associates.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>15. DISPUTES</Typography>
            <Typography className={classes.text}>
              Any dispute related in any way to your visit to this website, to
              services obtained through this website or to products you purchase
              from us shall be arbitrated by state or federal court and you
              consent to exclusive jurisdiction and venue of such courts.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              16. WASTED JOURNEY
            </Typography>
            <Typography className={classes.text}>
              Requested collections or exchanges that cannot be performed due to
              Section 1, and/or due to blockage of access/egress due to vehicles
              and or pallets or other restriction, the company has the right to
              charge the Customer a wasted journey fee per visit. The Customer
              shall ensure that the Driver has access to unload or pickup the
              container or load within 5 minutes of arrival at the Customer’s
              location. If the Driver is kept waiting longer than 5 minutes
              after arrival, the Customer may at the discretion of the Company
              be charged for reasonable demurrage.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              17. LIMITATION ON LIABILITY
            </Typography>
            <Typography className={classes.text}>
              Company is not liable for any damages that may occur to you, your
              employees, family members or other individuals or secondary users
              as a result of your misuse of our website, products, or services.
              Company reserves the right to edit, modify, and change this
              Agreement at any time. We shall let our users know of these
              changes through electronic mail. This Agreement is an
              understanding between Company and the user, and this supersedes
              and replaces all prior agreements regarding the use of this
              website, products, and services.
            </Typography>
          </Box>
          <GoogleTranslator />
        </Container>
      </div>
    </Page>
  );
};

export default TermsAndConditions;
