import React from 'react';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../config';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),

    maxWidth: '270px',
    '& .MuiTypography-caption': {
      fontSize: '16px',
    },
  },
  card: {
    position: 'relative',
    minHeight: '400px',
    boxShadow: '0px 0px 10px 5px rgba(7,7,7,0.09)',
    '& a': {
      '&:hover, focus': {
        color: 'inherit',
      },
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  media: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  footer: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const BlogCard = (props) => {
  const classes = useStyles();
  const {
    author_first_name,
    author_last_name,
    date,
    description,
    header_img,
    id,
    title,
  } = props.post;

  function dateFunction(date) {
    let dateResult = '';
    const monthArr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    //Month
    if (Number(date[5]) <= 0) {
      dateResult = monthArr[Number(date[6]) - 1];
    } else {
      dateResult = monthArr[Number(date.slice(5, 7)) - 1];
    }
    //Day
    if (Number(date[8]) <= 0) {
      dateResult = dateResult + ' ' + date[9] + ', ';
    } else {
      dateResult = dateResult + ' ' + date.slice(8, 10) + ', ';
    }
    //Year
    dateResult = dateResult + date.slice(0, 4);

    return dateResult;
  }

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <CardActionArea>
          <Link className={classes.link} to={`blog/${id}`}>
            <div className={classes.media}>
              <CardMedia
                className={classes.image}
                image={SERVER_URL + header_img}
                title={title}
              />
            </div>
            <CardContent>
              <Typography className={classes.title} variant="body1">
                {title}
              </Typography>
              <Typography variant="caption">{description}</Typography>
            </CardContent>
          </Link>
        </CardActionArea>
        <Typography className={classes.footer} variant="caption">
          {dateFunction(date)}, {author_first_name + ' ' + author_last_name}
        </Typography>
      </Card>
    </Box>
  );
};

export default BlogCard;

BlogCard.propTypes = {
  post: PropTypes.object,
};
