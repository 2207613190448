import { SET_CHARITIES_LIST, ALERT_FAIL } from './types';
import { SERVER_URL } from 'config.js';
import { configureConfigHeader } from 'utils/utils.js';
import axios from 'axios';

export const getCharities = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  axios
    .get(`${SERVER_URL}/donee/`, config)
    .then((res) => {
      dispatch({
        type: SET_CHARITIES_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ALERT_FAIL,
        payload: err.response.data?.msg,
      });
    });
};
