import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

// components
import EmailVerificationForm from '../Forms/EmailVerificationForm';
import PhoneVerificationForm from '../Forms/PhoneVerificationForm';

// styles
const useStyles = makeStyles((theme) => ({
  step: {
    padding: '8px 25px',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(21),
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
    textAlign: 'center',
    maxWidth: '400px',
    margin: '10px auto 2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
      margin: '10px auto 2rem',
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  number: {
    fontWeight: 500,
  },
  numberInput: {
    fontFamily: 'monospace',
    MozAppearance: 'textfield',
    borderRadius: '6px',
    border: '1px solid',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
    margin: '4px',
    paddingLeft: '8px',
    width: '36px',
    height: '42px',
    fontSize: '32px',
    boxSizing: 'border-box',
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'lightgrey',
  },
  subtext: {
    textAlign: 'center',
    maxWidth: '300px',
    margin: '0 auto',
    fontSize: theme.typography.pxToRem(14),
  },
  resendButton: {
    border: 'none',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    color: theme.palette.tertiary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  bold: {
    fontWeight: 700,
  },
}));

export default function EmailPhoneVerification(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box
        mb={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      ></Box>
      <Typography className={classes.title}>
        {t('signup.form.pleaseEnterCode')}
      </Typography>
      <Box mb={6}>{props.newCustomer.email && <EmailVerificationForm />}</Box>
      <Box mb={6}>
        {props.newCustomer.phone_number && (
          <PhoneVerificationForm newCustomer={props.newCustomer} />
        )}
      </Box>
    </React.Fragment>
  );
}
