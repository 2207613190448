import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// mui components
import { Hidden, Stepper, Step, Box, StepLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepper: {
    // maxWidth: '500px',
    backgroundColor: 'transparent',
  },
  step: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    '& $completed': {
      color: theme.palette.secondary.main,
    },
    '& $active': {
      color: theme.palette.tertiary.main,
    },
  },
  // needed so that the &$active tag works
  active: {},
  completed: {},
  alternativeLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  labelContainer: {
    maxWidth: '90px',
  },
}));

export default function SignUpStepper(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Hidden xsDown>
      <Box mb={1}>
        <Stepper
          className={classes.stepper}
          activeStep={props.activeStep}
          alternativeLabel
        >
          {props.steps.map((label) => (
            <Step
              classes={{
                root: classes.step,
                completed: classes.completed,
                alternativeLabel: classes.active,
              }}
              key={t(label)}
            >
              <StepLabel
                classes={{
                  alternativeLabel: classes.alternativeLabel,
                  labelContainer: classes.labelContainer,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active,
                    disabled: classes.disabled,
                  },
                }}
              >
                {t(label)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Hidden>
  );
}

SignUpStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};
