import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import BasicCard from 'components/Card/BasicCard';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    fontWeight: 300,
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.tertiary.main,
      background: 'inherit',
    },
  },
}));

function KioskAccount(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();

  return (
    <BasicCard>
      <Box p={4}>
        <Box mb={2}>
          <Typography variant="h5" color="primary">
            {t('schedule.kioskAccount.looksIncomplete')}
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography className={classes.text}>
            {t('schedule.kioskAccount.headToYour')}{' '}
            <NavLink exact to="/portal/account" className={classes.link}>
              {t('schedule.kioskAccount.account')}
            </NavLink>{' '}
            {t('schedule.kioskAccount.completeInfo')}
          </Typography>
        </Box>
        <Typography className={classes.text}>
          {t('schedule.dropOffOnly')}{' '}
          <NavLink exact to="/portal/locations" className={classes.link}>
            {t('schedule.dropOffOnly.dropOffLocations')}
          </NavLink>{' '}
          {t('schedule.dropOffOnly.findOneNearYou')}
        </Typography>
      </Box>
    </BasicCard>
  );
}

export default KioskAccount;

KioskAccount.propTypes = {
  children: PropTypes.node,
};
