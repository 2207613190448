/*eslint-disable*/
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// react components for routing our app without refresh
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'redux/actions/auth';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  list: {
    margin: 0,
    paddingLeft: 0,
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0 .5rem',
    lineHeight: '.9rem',
  },
  menuLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
    },
  },
  navButton: {
    cursor: 'pointer',
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'block',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
}));

export default function AccountMenu(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (event.target.getAttribute('data-id')) {
      dispatch(logout());
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.navButton}
      >
        <AccountCircleIcon fontSize="default" />
      </button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>
                    <NavLink
                      exact
                      to="/portal/account"
                      className={classes.menuLink}
                    >
                      {t('nav.account')}
                    </NavLink>
                  </MenuItem>
                  <MenuItem data-id="logout" onClick={handleClose}>
                    {t('buttons.logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
