import {
  SET_STORE,
  SET_CART,
  DELETE_CART,
  SET_SHIPPING_OPTIONS,
  ALERT_FAIL,
} from './types';
import axios from 'axios';
import { SERVER_URL } from '../../config.js';
import { configureConfigHeader } from 'utils/utils';
import { t } from 'i18next';

export const loadCartFromStorage = () => (dispatch, getState) => {
  const user = getState().auth.customer.id;
  const localCart = localStorage.getItem(user);

  const cart = JSON.parse(localCart);
  if (cart) {
    let totalPrice = 0;
    let totalItems = 0;
    for (let i = 0; i < cart.length; i++) {
      let product = cart[i];
      totalPrice += product.price_microdollars * product.amount;
      totalItems += product.amount;
    }
    dispatch({
      type: SET_CART,
      payload: { cart, totalItems, totalPrice },
    });
  } else {
    dispatch({ type: DELETE_CART });
  }
};

export const updateItemLocalStorage = (key, value) => (dispatch, getState) => {
  const stringValue = JSON.stringify(value);

  localStorage.setItem(key, stringValue);
};

export const deleteItemLocalStorage = (key) => (dispatch) => {
  localStorage.removeItem(key);
  dispatch({
    type: SET_CART,
    payload: { cart: [], totalPrice: 0, totalItems: 0 },
  });
};

export const getProducts = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  return axios.get(`${SERVER_URL}/shop/`, config);
};

export const getShop = () => async (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  return axios
    .get(`${SERVER_URL}/shop/`, config)
    .then((res) => {
      const products = res.data;
      dispatch({
        type: SET_STORE,
        payload: {
          products,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const placeOrder = (info) => async (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  const body = JSON.stringify({ ...info });
  return axios.post(`${SERVER_URL}/shop/place_order`, body, config);
};

// CART
export const addToCart = (newProduct) => (dispatch, getState) => {
  const user = getState().auth.customer.id;
  const cartInfo = getState().cart;
  let totalPrice = cartInfo.totalPrice;
  let totalItems = cartInfo.totalItems;
  let cart = [...cartInfo.cart];

  const productIndex = cart.findIndex(
    (product) => product.id === newProduct.id
  );

  if (productIndex < 0) {
    cart.push({ ...newProduct });
    totalPrice += newProduct.price_microdollars * newProduct.amount;
    totalItems += newProduct.amount;
  } else {
    cart[productIndex].amount += newProduct.amount;
    totalPrice += cart[productIndex].price_microdollars * newProduct.amount;
    totalItems += newProduct.amount;
  }

  dispatch(updateItemLocalStorage(user, cart));
  dispatch({ type: SET_CART, payload: { cart, totalPrice, totalItems } });
};

export const updateCartItem = (id, action) => (dispatch, getState) => {
  const user = getState().auth.customer.id;
  const cartInfo = getState().cart;
  let totalPrice = cartInfo.totalPrice;
  let totalItems = cartInfo.totalItems;
  let cart = [...cartInfo.cart];
  const prodId = id;

  const prodIndex = cart.findIndex((prod) => prod.id === prodId);

  if (action === 'add') {
    cart[prodIndex].amount += 1;
    totalPrice += cart[prodIndex].price_microdollars;
    totalItems += 1;
  } else {
    cart[prodIndex].amount -= 1;
    totalPrice -= cart[prodIndex].price_microdollars;
    totalItems -= 1;
  }

  if (cart[prodIndex].amount === 0) {
    dispatch(deleteCartItem(id));
    return;
  }

  dispatch(updateItemLocalStorage(user, cart));
  dispatch({
    type: SET_CART,
    payload: { cart, totalPrice, totalItems },
  });
};

const calcTotal = (cart) => {
  let totalPrice = 0;
  let totalItems = 0;
  cart.forEach((prod) => {
    totalPrice += prod.amount * prod.price_microdollars;
    totalItems += prod.amount;
  });

  return { totalPrice, totalItems };
};

export const deleteCartItem = (id) => (dispatch, getState) => {
  const user = getState().auth.customer.id;
  const cartInfo = getState().cart;
  let cart = [...cartInfo.cart];
  const prodIndex = cart.findIndex((prod) => prod.id === id);

  cart.splice(prodIndex, 1);

  if (cart.length <= 0) {
    const totalPrice = 0;
    const totalItems = 0;
    localStorage.removeItem(user);
    dispatch({
      type: SET_CART,
      payload: { cart, totalPrice, totalItems },
    });
    return;
  } else {
    const { totalPrice, totalItems } = calcTotal(cart);
    updateItemLocalStorage(user, cart);
    dispatch({
      type: SET_CART,
      payload: { cart, totalPrice, totalItems },
    });
  }
};

export const computeOrder = (order_list) => async (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  const body = JSON.stringify({ order_list });
  return axios.post(`${SERVER_URL}/shop/compute_order`, body, config);
};

export const getShippingOptions =
  (order_list) => async (dispatch, getState) => {
    const config = configureConfigHeader(getState);
    const body = JSON.stringify({ order_list });
    return axios
      .post(`${SERVER_URL}/shop/get_shipping_options`, body, config)
      .then((res) => {
        const options = res.data;
        dispatch({
          type: SET_SHIPPING_OPTIONS,
          payload: { options },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ALERT_FAIL,
          payload: t('actions.shop.errorGettingShippingOptions'),
        });
      });
  };

export const getOrderTotal = (info) => async (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  const body = JSON.stringify({ ...info });
  return axios.post(`${SERVER_URL}/shop/get_order_total`, body, config);
};
