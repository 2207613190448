import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import theme from 'themes/memberPortalTheme';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 15,
    // marginLeft: 'auto',
    // display: 'block',
    transform: 'rotate(45deg)',
  },
  shadow: {
    background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
    boxShadow: '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff',
    borderRadius: '99999px',
  },
}));

function ProgressCircle(props) {
  const classes = useStyles(props);
  const { name, amount, icon, color } = props;
  return (
    <CircularProgressbarWithChildren
      className={classes.shadow}
      value={amount}
      strokeWidth={10}
      styles={{
        trail: {
          // Trail color
          stroke: `${theme.palette.grey.main}`,
          strokeLinecap: 'round',
        },
        path: {
          // Path color
          stroke: `${color}`,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'round',
        },
      }}
    >
      {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

      <div style={{ marginTop: -5 }} data-cy={`${name}-percentage`}>
        <Typography style={{ marginTop: -5 }}>{amount}%</Typography>
      </div>
      <img className={classes.icon} src={icon} alt={name} />
    </CircularProgressbarWithChildren>
  );
}

export default ProgressCircle;
