import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

// assets
import co2 from 'assets/img/portal/co2.svg';
import BasicCard from 'components/Card/BasicCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 15,
    boxShadow: theme.shadows[25],
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardTitle: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(21),
  },
  icon: {
    width: '100%',
    maxWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    },
  },
  circle: {
    backgroundColor: (props) => props.background,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(21),
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  info: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(48),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(32),
    },
  },
  subscript: {
    verticalAlign: 'sub',
  },
}));

function EnviroInfo(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const { totals } = useSelector((state) => state.metrics);
  return (
    <BasicCard>
      <Box p={3}>
        <Box mb={2}>
          <Typography className={classes.cardTitle} color="primary">
            {t('metrics.environmentalImpact')}
          </Typography>
        </Box>
        <Box p={2} display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <img className={classes.icon} src={co2} alt="CO2 Icon" />
          </Box>
          <Box pl={3} data-cy="environment">
            <Typography className={classes.info}>
              {(totals.co2_g / 1000).toFixed(2)} Kg
            </Typography>
            <Typography className={classes.title}>
              CO<span className={classes.subscript}>2</span>{' '}
              {t('metrics.emissionsAvoided')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </BasicCard>
  );
}

export default EnviroInfo;
