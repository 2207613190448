import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// datamatrix scanner

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import BasicCard from 'components/Card/BasicCard';

import axios from 'axios';
import { SERVER_URL } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#007a9c',
    padding: '1rem',
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    maxWidth: '900px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    },
  },
  headerTitle: {
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    maxWidth: '700px',
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    fontWeight: 400,
    fontSize: '1rem',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.primary.contrastText,
      background: 'inherit',
    },
  },
  thankyou: {
    color: theme.palette.tertiary.main,
  },
  logo: {
    width: '3.5rem',
    height: 'auto',
    padding: '1px',
    filter: 'drop-shadow(2px 2px 3px rgb(0 0 0 / 0.4))',
  },
  title: {
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
}));

function DatamatrixCard(props) {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.access_token);
  const classes = useStyles(props);
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  const [datamatrixImage, setDatamatrixImage] = useState('');

  const getDatamatrixCode = () => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer',
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log('gettingDatamatrixCode()');

    // eslint-disable-next-line
    let ret = axios
      .get(`${SERVER_URL}/customer/datamatrix`, config)
      .then((response) => {
        console.log(response);
        const dm_b64 = Buffer.from(response.data, 'binary').toString('base64');
        console.log(dm_b64);
        setDatamatrixImage(dm_b64);
      })
      .catch((error) => {
        console.log(error.response);
        setMessage(t('dashboard.scanner.generalError'));
      });
  };

  useEffect(() => {
    getDatamatrixCode();
  }, []);

  return (
    <BasicCard>
      <Box p={2} pb={0}>
        <Typography variant="h4" className={classes.title}>
          {t('dashboard.kioskLoginCode')}
        </Typography>
        <Typography className={classes.text}>
          {t('dashboard.justShowThisCode')}
        </Typography>
      </Box>
      <Box p={2} align="center">
        <Box p={2}>
          <img src={`data:image/png;charset=utf-8;base64,${datamatrixImage}`} />
        </Box>
      </Box>
    </BasicCard>
  );
}

export default DatamatrixCard;

DatamatrixCard.propTypes = {
  children: PropTypes.node,
};
