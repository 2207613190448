import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  icon: {
    width: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
    },
  },
  circle: {
    backgroundColor: (props) => props.background,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
  },
  info: {
    fontSize: theme.typography.pxToRem(21),
  },
}));

function BasicAccountInfo(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Box>
        <Avatar
          className={classes.iconAvatar}
          classes={{ colorDefault: classes.circle }}
        >
          <img className={classes.icon} src={props.icon} />
        </Avatar>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography className={classes.title}>{props.name}</Typography>
        {props.subInfo && (
          <Typography className={classes.info}>{props.subInfo}</Typography>
        )}
        <Typography className={classes.info}>{props.info}</Typography>
      </Box>
    </div>
  );
}

export default BasicAccountInfo;
