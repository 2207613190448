import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch } from 'react-redux';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, Grid, Divider } from '@material-ui/core';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '350px',
    maxWidth: '400px',
    borderRadius: 10,
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
  },
  form: {
    marginBottom: 0,
  },
  input: {
    marginBottom: theme.spacing(1),
    transition: 'all .3s ease-out',
    paddingLeft: '7px',
    width: 'auto',
    '&..MuiSelect-icon': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      marginBottom: 0,
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  },
  section: {
    marginTop: theme.spacing(2),
  },
  sectionText: {
    paddingLeft: '7px',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  button: {
    cursor: 'pointer',
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default function AccountPayPalSignupForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { paypal_email } = props.validation;

  const validationSchema = yup.object({
    paypal_email,
  });

  const formik = useFormik({
    initialValues: {
      paypal_email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(updateCustomer(values));
      actions.resetForm();
    },
  });

  return (
    <React.Fragment>
      <Box p={1} mb={2}>
        <Typography className={classes.text}>
          {t('account.form.linkPayPalInfo')}
        </Typography>
      </Box>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <PortalInputLabel classes={{ root: classes.label }} id="email">
                {t('account.form.payPalEmail')}
              </PortalInputLabel>
              <PortalTextFieldNoShadow
                fullWidth
                type="text"
                id="email"
                name="paypal_email"
                placeholder={t('account.form.email')}
                value={formik.values.paypal_email}
                onChange={formik.handleChange}
                error={
                  formik.touched.paypal_email &&
                  Boolean(formik.errors.paypal_email)
                }
                helperText={
                  formik.touched.paypal_email && formik.errors.paypal_email
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <Divider />
            </Box>
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              classes={{ root: classes.button }}
              color="secondary"
              variant="contained"
              type="submit"
            >
              {t('buttons.submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
