import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Button,
  ButtonBase,
  Box,
  Typography,
  Grid,
  Paper,
  InputLabel,
  TextField,
  Divider,
  Select,
  MenuItem,
  Hidden,
  FormHelperText,
} from '@material-ui/core';

// Components
import CustomPhoneNumberFormat from 'components/CustomNumberFormat/CustomPhoneNumberFormat';

// Lib
import { states } from 'lib/formValues';

// Service
import Customer from 'api/CustomerService';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[25],
  },
  step: {
    padding: '8px 25px',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(21),
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    transition: 'all .3s ease-out',
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
  form: {
    marginBottom: 0,
  },
  input: {
    marginBottom: theme.spacing(1),
    transition: 'all .3s ease-out',
    paddingLeft: '7px',
    width: 'auto',
    '&..MuiSelect-icon': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      opacity: 1,
      marginBottom: 0,
      '&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  },
  section: {
    marginTop: theme.spacing(2),
  },
  sectionLabel: {
    paddingBottom: '7px',
  },
  sectionText: {
    paddingLeft: '7px',
  },
  select: {
    transition: 'all .3s ease-out',
  },
  editButton: {
    color: theme.palette.tertiary.main,
    fontSize: '1.125rem',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  agreement: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  textField: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const accountType = [
  'dashboard.recycletekMember',
  'dashboard.recycletekBusinessMember',
];

export default function ReviewForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(true);
  // if a user signs up with a specific redemption center or tenant
  const flatProps = {
    options: states.map((state, index) => state),
  };
  const handleEdit = () => {
    setEdit((prevState) => !prevState);
    props.handleSubmit();
  };

  const validationSchema = yup.object({
    ...props.validation,
    zipcode: yup.string().required(t('signup.form.pleaseEnterZipcode')),
  });

  const formik = useFormik({
    initialValues: {
      email: props.userInfo.email,
      password: props.userInfo.password,
      confirmPassword: props.userInfo.confirmPassword,
      type: props.userInfo.type,
      first_name: props.userInfo.first_name,
      last_name: props.userInfo.last_name,
      phone_number: props.userInfo.phone_number,
      street: props.userInfo.street,
      apt: props.userInfo.apt,
      city: props.userInfo.city,
      state: props.userInfo.state,
      zipcode: props.userInfo.zipcode,
      business_name: props.userInfo.business_name,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      handleEdit();
      actions.setSubmitting(false);
      props.handleChange(values);
    },
  });
  return (
    <React.Fragment>
      <Box
        mb={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Hidden smUp>
          <Box mt={1}>
            <Typography className={classes.step} color="primary" align="center">
              {t('signup.steps.stepOne')}
            </Typography>
          </Box>
        </Hidden>
        <Typography variant="h5" align="center">
          {t('signup.steps.reviewInfo')}
        </Typography>
      </Box>
      <Paper className={classes.root}>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <InputLabel classes={{ root: classes.label }} disabled={edit}>
                {t('signup.form.firstName')}
              </InputLabel>
              <TextField
                className={classes.textField}
                type="text"
                id="firstName"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                disabled={edit}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel classes={{ root: classes.label }} disabled={edit}>
                {t('signup.form.lastName')}
              </InputLabel>
              <TextField
                className={classes.textField}
                type="text"
                id="lastName"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                disabled={edit}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel classes={{ root: classes.label }} disabled={edit}>
                {t('signup.form.email')}
              </InputLabel>
              <TextField
                className={classes.textField}
                type="email"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={edit}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel classes={{ root: classes.label }} disabled={edit}>
                {t('signup.form.mobileNumber')}
              </InputLabel>
              <TextField
                className={classes.textField}
                type="tel"
                id="phoneNum"
                name="phone_number"
                value={formik.values.phone_number}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                  inputComponent: CustomPhoneNumberFormat,
                }}
                onChange={formik.handleChange}
                disabled={edit}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
                helperText={
                  formik.touched.phone_number && formik.errors.phone_number
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <InputLabel
                classes={{ root: classes.label }}
                className={classes.sectionLabel}
                disabled={edit}
              >
                {t('signup.form.address')}
              </InputLabel>
              <Grid container>
                {edit ? (
                  <div className={classes.sectionText}>
                    <Typography>{formik.values.street}</Typography>
                    <Typography>
                      {props.userInfo.apt && `Apt #: ${formik.values.apt}`}
                    </Typography>
                    <Typography>
                      {props.userInfo.city &&
                        `${formik.values.city}, ${formik.values.state}`}
                    </Typography>
                    <Typography>{formik.values.zipcode}</Typography>
                  </div>
                ) : (
                  <div>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.textFieldAddress}
                        fullWidth
                        margin="dense"
                        type="text"
                        id="street"
                        label="Street"
                        name="street"
                        placeholder={t('signup.form.addressOne')}
                        autoComplete="address-line1"
                        value={formik.values.street}
                        onChange={formik.handleChange}
                        disabled={edit}
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        error={
                          formik.touched.street && Boolean(formik.errors.street)
                        }
                        helperText={
                          formik.touched.street && formik.errors.street
                        }
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      {props.userInfo.apt === '' ? null : (
                        <Grid item xs={5}>
                          <Box mr={3}>
                            <TextField
                              className={classes.textFieldAddress}
                              margin="dense"
                              type="text"
                              id="apt"
                              name="apt"
                              label={t('signup.form.aptUnit')}
                              placeholder={t('signup.form.aptNumber')}
                              autoComplete="shipping address-line3"
                              value={formik.values.apt}
                              onChange={formik.handleChange}
                              disabled={edit}
                              InputProps={{
                                classes: {
                                  root: classes.input,
                                },
                              }}
                              error={
                                formik.touched.apt && Boolean(formik.errors.apt)
                              }
                              helperText={
                                formik.touched.apt && formik.errors.apt
                              }
                            />
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={7}>
                        <TextField
                          className={classes.textFieldAddress}
                          margin="dense"
                          fullWidth
                          type="text"
                          id="city"
                          name="city"
                          placeholder={t('signup.form.city')}
                          autoComplete="shipping address-level2"
                          label="City"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          disabled={edit}
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={4}>
                        <Autocomplete
                          {...flatProps}
                          autoHighlight
                          disabled={edit}
                          id="state"
                          name="state"
                          placeholder={t('signup.form.state')}
                          value={formik.values.state}
                          onChange={(e, value) => {
                            formik.setFieldValue('state', value);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} margin="normal" />
                          )}
                        />
                        <FormHelperText
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                        >
                          {formik.touched.state && formik.errors.state}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        className={classes.textFieldAddress}
                        fullWidth
                        margin="dense"
                        type="text"
                        id="zipcode"
                        label={t('signup.form.zipcode')}
                        name="zipcode"
                        placeholder={t('signup.form.zipcode')}
                        value={formik.values.zipcode}
                        onChange={formik.handleChange}
                        disabled={edit}
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        error={
                          formik.touched.zipcode &&
                          Boolean(formik.errors.zipcode)
                        }
                        helperText={
                          formik.touched.zipcode && formik.errors.zipcode
                        }
                      />
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.section}>
              <InputLabel
                classes={{ root: classes.label }}
                className={classes.sectionLabel}
                disabled={edit}
              >
                {t('signup.form.accountType')}
              </InputLabel>
              {edit ? (
                <Typography className={classes.sectionText}>
                  {t(accountType[formik.values.type])}
                </Typography>
              ) : (
                <Select
                  id="type"
                  name="type"
                  classes={{
                    root: classes.select,
                  }}
                  disabled={edit}
                  value={formik.values.type}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={0}>
                    {t('dashboard.recycletekMember')}
                  </MenuItem>
                  <MenuItem value={1}>
                    {t('dashboard.recycletekBusinessMember')}
                  </MenuItem>
                </Select>
              )}
            </Grid>
            {formik.values.type === 1 ? (
              <Grid item xs={12} className={classes.section}>
                <InputLabel classes={{ root: classes.label }} disabled={edit}>
                  {t('signup.form.businessName')}
                </InputLabel>
                <TextField
                  className={classes.textField}
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={formik.values.business_name}
                  onChange={formik.handleChange}
                  disabled={edit}
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                  error={
                    formik.touched.business_name &&
                    Boolean(formik.errors.business_name)
                  }
                  helperText={
                    formik.touched.business_name && formik.errors.business_name
                  }
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Box my={3}>
                <Divider />
              </Box>
            </Grid>
            <Grid item container justify="flex-end">
              {edit ? (
                <ButtonBase className={classes.editButton} onClick={handleEdit}>
                  {t('buttons.edit')}
                </ButtonBase>
              ) : null}
              {edit ? null : (
                <ButtonBase className={classes.editButton} type="submit">
                  {t('buttons.done')}
                </ButtonBase>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Box mt={2} px={2}>
        <Typography className={classes.agreement}>
          {t('signup.bySubmittingYouAgree')}{' '}
          <a
            className={classes.link}
            target="_blank"
            href="/terms-and-conditions"
          >
            {t('signup.termsAndConditions')}
          </a>{' '}
          {t('signup.and')}{' '}
          <a className={classes.link} target="_blank" href="/privacy-policy">
            {t('signup.privacyPolicy')}
          </a>
        </Typography>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          onClick={props.handleBack}
          className={classes.button}
        >
          {t('buttons.back')}
        </Button>
        <Button
          variant="contained"
          onClick={props.handleInitiateVerification}
          disabled={props.submitting}
        >
          {t('buttons.submit')}
        </Button>
      </Box>
    </React.Fragment>
  );
}

ReviewForm.propTypes = {
  userInfo: PropTypes.shape({
    phone_number: PropTypes.string,
    zipcode: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.string,
    apt: PropTypes.string,
    state: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.number,
    street: PropTypes.string,
  }),
  handleChange: PropTypes.func,
};
