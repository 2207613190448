import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DateTime } from 'luxon';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import Section from 'components/Page/Section';
import DataDisplay from 'views/Home/DataDisplay';
import Header from 'views/Home/Header';
import Solutions from 'views/Home/Solutions';
import Video from 'components/Video/Video';
import CustomerReview from './CustomerReviews.js';

// assets
import phoneGif from 'assets/img/phoneGifReduced.gif';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  phone: {
    width: '100%',
  },
  data: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    background:
      'linear-gradient(90deg, rgba(0,142,170,1) 0%, rgba(151,215,0,1) 95%)',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [itemsRecycled, setItemsRecycled] = useState(0);
  const [metricTons, setMetricTons] = useState(0);
  const now = DateTime.local();
  const start = DateTime.fromISO('2021-03-01');

  useEffect(() => {
    const weeks = Math.floor(now.diff(start, ['weeks']).values.weeks);
    const items = weeks * 21111 + 1560543;
    const carbon = (items * 3) / 453.6;
    setItemsRecycled(items);
    setMetricTons(carbon);
  }, []);

  return (
    <Page>
      <Header />
      <Container>
        <Section textWithImage>
          <Box>
            <Typography variant="h3">
              {t('home.industryLeadingTech')}
            </Typography>
            <Typography variant="body2">
              <Trans>{t('home.industryLeadingTechParagraph')}</Trans>
            </Typography>
          </Box>
          <Box>
            <img className={classes.phone} src={phoneGif} alt="phone gif" />
          </Box>
        </Section>
      </Container>
      <Section>
        <Video
          videoLink="https://www.youtube.com/embed/aMVAiwu3J1o?si=Tm9IDJ36Gzol8A94"
          title={t('home.techDemo')}
          text={t('home.videoDescription')}
        />
      </Section>
      <Section>
        <Box className={classes.data}>
          <DataDisplay
            topText={t('home.lessThan')}
            odometerNumber={30}
            bottomText={t('home.secondsForDropOffs')}
          />
          <DataDisplay
            odometerNumber={itemsRecycled}
            bottomText={t('home.itemsRecycled')}
          />
          <DataDisplay
            odometerNumber={metricTons}
            bottomText={t('home.carbonSaved')}
          />
        </Box>
      </Section>
      <Section>
        <Solutions />
      </Section>
      <Section>
        <CustomerReview />
      </Section>
    </Page>
  );
};

export default Home;
