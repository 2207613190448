import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  deleteRecurringPickup,
  createSkipRequest,
} from 'redux/actions/pickups';

// date libraries
import { DateTime } from 'luxon';
import { rrulestr } from 'rrule';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Button,
  Collapse,
  ListItemText,
  Grid,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Utils
import SkipRecurringPickupForm from '../Forms/SkipRecurringDayForm';
import { filter } from 'lodash';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pickup: {
    color: theme.palette.primary.main,
  },
  title: {
    color: (props) =>
      props.pickup.bulk
        ? theme.palette.tertiary.main
        : theme.palette.secondary.main,
    paddingRight: theme.spacing(6),
    fontWeight: 300,
  },
  info: {
    fontWeight: 300,
  },
  editButton: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
}));

function RecurringListItem(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { pickup } = props;
  const paymentOptions = useSelector(
    (state) => state.transactions.paymentOptions,
    shallowEqual,
  );

  const [dayOfWeek, setDayOWeek] = React.useState('');
  const [intervalText, setIntervalText] = React.useState('');
  const [skipDate, setSkipDate] = React.useState(null);
  const [skipDateError, setSkipDateError] = React.useState(false);
  const [upcomingDates, setUpcomingDates] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState({});
  const [open, setOpen] = React.useState(false);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen(!open);
  };

  const handleDelete = (e) => {
    dispatch(deleteRecurringPickup(pickup.availablepickupdays_id));
  };

  const handleSkipDate = (e) => {
    e.preventDefault();
    if (skipDate === null) {
      setSkipDateError(true);
      return;
    }

    // format skip date to iso format for backend
    const isoDate = DateTime.fromFormat(skipDate, 'DDD').toISODate();
    const skipDetails = {
      date: isoDate,
      pickuprequestsrecurring_id: pickup.availablepickupdays_id,
    };
    dispatch(createSkipRequest(skipDetails));
    // clear form;
    setSkipDate(null);
  };

  const handleSelectDate = (newValue) => {
    if (skipDateError === true) {
      setSkipDateError(false);
    }
    setSkipDate(newValue);
  };

  React.useEffect(() => {
    const rrule = props.pickup.rrule;
    const exclusions = rrule.exdates().map((date) => {
      return DateTime.fromJSDate(date).toISODate();
    });
    const dates = [...props.pickup.threeMonthDates];

    let upcomingDates = [];

    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      if (!exclusions.includes(date)) {
        upcomingDates.push(
          DateTime.fromISO(date).toLocaleString({
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }),
        );
      }
    }

    setUpcomingDates(upcomingDates);

    // remove count to help format interval text
    rrule.options.count = null;
    // Format date to be day of week only
    setDayOWeek(DateTime.fromISO(props.pickup.startdate).toFormat('EEEE'));

    // Format RRule to be human readable text
    let rule = rrulestr(rrule.valueOf()[1]);
    rule.options.count = null;
    setIntervalText(rule.toText());

    // Find the payment info for that payment id
    const payment = paymentOptions.filter(
      (option) => option.id === props.pickup.paymentoptions_id,
    );
    setPaymentType(...payment);
  }, [props.pickup]);

  return (
    <div data-cy="scheduled-pickup-item-recurring">
      <ListItem
        button
        classes={{ root: classes.listRoot }}
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography>{`${dayOfWeek}s`}</Typography>
          <Typography className={classes.title}>
            {pickup.bulk
              ? `${t('schedule.bulkPickup')}${t('common.comma')} `
              : `${t('schedule.curbsidePickup')}${t('common.comma')}`}{' '}
            {intervalText}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box pt={1} pl={3} display="flex">
          <Typography>{t('schedule.ecoSacks')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {pickup.num_eco_sacks}
            </Typography>
          </Box>
        </Box>
        <Box pl={3} display="flex">
          <Typography>{t('schedule.ecoKrates')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {pickup.num_eco_krates}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Typography>{t('schedule.paymentMethod')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {paymentType.name !== 'Cash'
                ? `${t('schedule.donation')} ${paymentType.name}`
                : t('schedule.addToAccountBalance')}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Typography>{t('schedule.instructions')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {pickup.instructions === ''
                ? t('common.notApplicable')
                : `${pickup.instructions}`}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Grid container display="flex" direction="column">
            <Grid item>
              <Typography>{t('schedule.skipUpcomingPickup')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1}>
                <SkipRecurringPickupForm
                  list={upcomingDates}
                  skipDate={skipDate}
                  handleSkipDate={handleSkipDate}
                  handleSelectDate={handleSelectDate}
                  error={skipDateError}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="flex-end" py={1} pr={3}>
          <Button color="primary" onClick={handleDelete}>
            {t('buttons.delete')}
          </Button>
        </Box>
      </Collapse>
      <Divider />
    </div>
  );
}

export default RecurringListItem;
