import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(16),
    '& h3': {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
    },
  },
  noMarginTop: {
    marginTop: theme.spacing(0),
  },
  noMarginBottom: {
    marginBottom: theme.spacing(0),
  },
  textWithImage: {
    display: 'flex',
    '& div:first-child': {
      maxWidth: '700px',
    },
    '& div:last-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& div:first-child': {
        maxWidth: '100%',
      },
      '& div:last-child': {
        '& img': {
          maxWidth: '80%',
          marginTop: theme.spacing(3),
        },
      },
    },
  },
  text: {
    maxWidth: '700px',
  },
}));

export default function Section(props) {
  const classes = useStyles();

  const classNames = clsx({
    [classes.container]: true,
    [classes.textWithImage]: props.textWithImage,
    [classes.noMarginTop]: props.noMarginTop,
    [classes.noMarginBottom]: props.noMarginBottom,
    [classes.text]: props.text,
  });

  return <Box className={classNames}>{props.children}</Box>;
}
