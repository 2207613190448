import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';

// components
import ProgressCircle from './ProgressCircle';

// assets
import BasicCard from 'components/Card/BasicCard';
import aluminumCan from 'assets/img/portal/icon_can.svg';
import glassBottle from 'assets/img/portal/icon_glass.svg';
import plasticBottle from 'assets/img/portal/icon_plastic.svg';
import theme from 'themes/memberPortalTheme';
import { formatMoney } from 'utils/formatTransactions';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(21),
    lineHeight: 1,
  },
  totalCount: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(64),
    lineHeight: 1,
    marginRight: theme.spacing(2),
  },
  percentTitle: {
    textTransform: 'capitalize',
    fontWeight: 300,
  },
}));

function Percentage(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { percentages, totals, redemption_amounts_per_type_microdollars } =
    useSelector((state) => state.metrics);

  const per = [
    {
      text: 'common.aluminum',
      metric: 'aluminum',
      icon: aluminumCan,
      color: theme.palette.text.primary,
    },
    {
      text: 'common.glass',
      metric: 'glass',
      icon: glassBottle,
      color: theme.palette.secondary.main,
    },
    {
      text: 'common.plastic',
      metric: 'plastic',
      icon: plasticBottle,
      color: theme.palette.aqua.main,
    },
  ];
  return (
    <BasicCard>
      <Box p={4} display="flex" justifyContent="start" alignItems="center">
        <div data-cy="total-count">
          <Typography className={classes.totalCount}>
            {percentages.total}
          </Typography>
        </div>
        <Typography className={classes.title}>
          <Trans>{t('dashboard.totalItemsRecycled')}</Trans>
        </Typography>
      </Box>
      <Box p={4}>
        <Grid container spacing={2} justify="space-around">
          {per.map((item, index) => (
            <Grid key={index} item xs={8} md={4}>
              <ProgressCircle
                key={index}
                name={t(item.text)}
                icon={item.icon}
                amount={percentages[item.metric]}
                color={item.color}
              />
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography className={classes.percentTitle}>
                  {t(item.text)}
                </Typography>
                <div data-cy={`${item.metric}-count`}>
                  <Typography>
                    {totals[item.metric]} {t('dashboard.items')}
                  </Typography>
                </div>
                <Typography>
                  {redemption_amounts_per_type_microdollars &&
                    formatMoney(
                      redemption_amounts_per_type_microdollars.total[
                        item.metric
                      ],
                    )}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </BasicCard>
  );
}

export default Percentage;
