import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { helperText } from '../../redux/actions/customer';

// FullCalendar
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import './custom.css';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  calendarCard: {
    padding: '12px 12px 12px 12px',
    borderRadius: 20,
    boxShadow: theme.shadows[25],
    maxWidth: '900px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    },
    '& .fc-day-past': {
      opacity: '0.7',
      backgroundColor: '#f7f7f7',
    },
  },
}));

export default function Calendar(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pickups = useSelector((state) => state.pickups, shallowEqual);
  const language = useSelector((state) => state.auth.language);
  const [curbsideEvents, setCurbsideEvents] = React.useState([]);
  const { sidebarOpen } = useSelector((state) => state.sidebar);
  const classes = useStyles();
  // const { ...rest } = props;
  const calendarRef = React.createRef();

  useEffect(() => {
    handleUpdateSize();
  }, [sidebarOpen]);

  //   sets curbside background event if customer has a curbside account
  useEffect(() => {
    console.log('pickups:', pickups);
    if (pickups.serviceType.curbside) {
      setCurbsideEvents([...pickups.availablePickupEvents]);
    }
  }, [pickups]);

  // Causes FullCalendar to resize the calendar after the sidebar collapses
  const handleUpdateSize = () => {
    setTimeout(() => {
      if (calendarRef.current !== null) {
        calendarRef.current.getApi().updateSize();
      }
    }, 300);
  };

  const handleDateClick = (args) => {
    const message = t('schedule.pleaseUseSchedulerOnRight');

    if (props.scheduled) {
      dispatch(helperText(message));
    } else {
      props.handleOpenScheduled();
    }
  };

  return (
    <>
      <Paper className={classes.calendarCard}>
        <FullCalendar
          ref={calendarRef}
          locale={language.slice(0, 2)}
          plugins={[rrulePlugin, dayGridPlugin, interactionPlugin]}
          events={[
            ...pickups.recurringEvents,
            ...pickups.singleEvents,
            ...pickups.skipEvents,
            // ...pickups.confirmedEvents,
            ...curbsideEvents,
          ]}
          initialView="dayGridMonth"
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5, 6],
          }}
          dateClick={handleDateClick}
        />
      </Paper>
    </>
  );
}
