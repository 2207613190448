import { SET_METRICS, RESET_METRICS } from 'redux/actions/types';

const initialState = {
  equivalencies: {},
  per_date: {},
  totals: {},
  percentages: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_METRICS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_METRICS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
