import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { List, ListItem, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  root: {
    width: 'auto',
    paddingLeft: '0',
  },
  list: {
    marginTop: '50px',

    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
    fontSize: '1rem',
  },
  listItem: {
    marginLeft: '2rem',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
    },
  },
  navLink: {
    fontWeight: 400,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
  divider: {
    marginLeft: '2rem',
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

function SideDrawer(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const handleLinkClick = (event) => {
    if (!matches) {
      return props.handleMobileDrawerToggle(event);
    }
  };
  return (
    <div>
      <div className={classes.toolbar} />
      <List className={classes.list}>
        <ListItem classes={{ root: classes.root }} className={classes.listItem}>
          <NavLink
            exact
            to="/portal/recyclable"
            activeClassName={classes.active}
            className={classes.navLink}
            onClick={handleLinkClick}
          >
            {t('nav.whatCanYouRecycle')}
          </NavLink>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem classes={{ root: classes.root }} className={classes.listItem}>
          <NavLink
            exact
            to="/portal/howto"
            activeClassName={classes.active}
            className={classes.navLink}
            onClick={handleLinkClick}
          >
            {t('nav.howItWorks')}
          </NavLink>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem classes={{ root: classes.root }} className={classes.listItem}>
          <NavLink
            exact
            to="/portal/faq"
            activeClassName={classes.active}
            className={classes.navLink}
            onClick={handleLinkClick}
          >
            {t('nav.faqs')}
          </NavLink>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem classes={{ root: classes.root }} className={classes.listItem}>
          <NavLink
            exact
            to="/portal/locations"
            activeClassName={classes.active}
            className={classes.navLink}
            onClick={handleLinkClick}
          >
            {t('nav.recyclingLocations')}
          </NavLink>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem classes={{ root: classes.root }} className={classes.listItem}>
          <NavLink
            exact
            to="/portal/contact"
            activeClassName={classes.active}
            className={classes.navLink}
            onClick={handleLinkClick}
          >
            {t('nav.contact')}
          </NavLink>
        </ListItem>
      </List>
    </div>
  );
}

export default SideDrawer;
