import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getSupportedLanguages,
  setPreferredLanguage,
} from 'redux/actions/customer';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

// styles
const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    [theme.breakpoints.up(400)]: {
      minWidth: 350,
    },
    color: 'blue',
  },
  label: {
    color: theme.palette.primary.main,
  },
}));

function LanguageForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [selectedLocaleISOCode, setSelectedLocaleISOCode] = useState('');
  const supportedLanguages = useSelector(
    (state) => state.customer.supportedLanguages,
  );

  useEffect(() => {
    !supportedLanguages && dispatch(getSupportedLanguages());
  }, []);

  const handleClose = () => {
    props.handleClose('language');
  };

  const handleSubmit = () => {
    handleClose();
    dispatch(setPreferredLanguage(selectedLocaleISOCode));
    i18n.changeLanguage(selectedLocaleISOCode);
  };

  const handleLanguageSelect = (event) => {
    setSelectedLanguage(supportedLanguages[event.target.value].name_orig);
    setSelectedLocaleISOCode(supportedLanguages[event.target.value].id);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <DialogTitle id="form-dialog-title" color="primary">
          {t('account.form.selectLanguage')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl>
            <InputLabel
              className={classes.label}
              id="select-language-preference"
            >
              {t('account.form.language')}
            </InputLabel>
            <Select
              id="select-language-preference"
              value={selectedLanguage}
              disableUnderline
              renderValue={() => {
                return selectedLanguage;
              }}
              onChange={handleLanguageSelect}
            >
              {supportedLanguages &&
                Object.keys(supportedLanguages).map((language, index) => (
                  <MenuItem value={language} key={index}>
                    {supportedLanguages[language].name_orig}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose}>
            {t('buttons.cancel')}
          </Button>
          <Button className={classes.button} onClick={handleSubmit}>
            {t('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LanguageForm;
