import React from 'react';
// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updatePayPal } from 'redux/actions/customer';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// Components
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
  },
  title: {
    paddingBottom: '0',
    fontWeight: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  subtitle: {
    marginLeft: '5px',
    fontSize: theme.typography.pxToRem(12),
  },
  infoText: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'justify',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  addButton: {
    border: 'none',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.text.primary,
    padding: '2px 10px',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  bold: {
    fontWeight: 700,
  },
  formControl: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  text: {
    color: '#797777',
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'none',
    paddingTop: '8px',
  },
  form: {
    maxWidth: '95%',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
  signup: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.tertiary.main,
    },
    '&:focus': {
      color: theme.palette.text.primary,
    },
  },
}));

const PayPalSignup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const evtek_email = useSelector((state) => state.auth.customer.email);
  const [checked, setChecked] = React.useState(false);

  const validationSchema = yup.object({
    paypal_email: yup
      .string('Enter your email')
      .email('Please enter email in valid format')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      paypal_email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(updatePayPal(values));
      actions.resetForm();
    },
  });

  const handleCheckedChange = (event) => {
    if (checked) {
      formik.setFieldValue('paypal_email', '');
    } else {
      formik.setFieldValue('paypal_email', evtek_email);
    }
    setChecked((prev) => !prev);
  };

  React.useEffect(() => {
    handleCheckedChange();
  }, []);

  return (
    <div>
      <Box mt={3}>
        <DialogContentText className={classes.infoText}>
          Simply, provide us with the email associated with your PayPal account
          and Recycletek will securely transfer your money into your account. If you
          don’t have a PayPal account yet, its really easy to set one up.
        </DialogContentText>
      </Box>
      <div className={classes.form}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="primary">PayPal account email</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                classes={{ root: classes.formControl }}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checked}
                    onChange={handleCheckedChange}
                    name="confirm"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle1" className={classes.text}>
                    Email associated with Recycletek account is the same as PayPal
                    account email.
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PortalTextFieldNoShadow
                className={classes.input}
                fullWidth
                id="email"
                name="paypal_email"
                placeholder="Email"
                value={formik.values.paypal_email}
                onChange={formik.handleChange}
                error={
                  formik.touched.paypal_email &&
                  Boolean(formik.errors.paypal_email)
                }
                helperText={
                  formik.touched.paypal_email && formik.errors.paypal_email
                }
              />
            </Grid>
          </Grid>
          <Box my={2}>
            <Link
              className={classes.signup}
              href="https://www.paypal.com/us/webapps/mpp/account-selection"
              target="_blank"
            >
              Sign up for a PayPal Account
            </Link>
          </Box>
          <DialogActions>
            <Button
              classes={{ root: classes.button }}
              color="primary"
              variant="contained"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              classes={{ root: classes.button }}
              color="secondary"
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </div>
    </div>
  );
};

export default PayPalSignup;

PayPalSignup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
