import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Typography, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactOption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& button': {
      marginTop: theme.spacing(1),
    },
    '& p': {
      fontWeight: 300,
    },
  },
}));

export default function ContactOptions({ handleContactSelected, options }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const memberPortal = window.location.toString().includes('portal');

  return (
    <Box className={classes.container}>
      {options.map((option, index) => (
        <Box className={classes.contactOption} key={index}>
          <Typography variant="h6">{t(option.title)}</Typography>
          <Typography>
            <Box>{option.text}</Box>
          </Typography>
          {memberPortal && (
            <Button
              variant="contained"
              onClick={() => handleContactSelected(option.value)}
            >
              {t('buttons.contact')}
            </Button>
          )}
          {!memberPortal && (
            <Button onClick={() => handleContactSelected(option.value)}>
              {t('buttons.contact')}
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
}
