import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
} from '@material-ui/core';

// lib/data
import { content } from 'lib/content';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  listItemIconRoot: {
    minWidth: '45px',
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listIcon: {
    color: '#ffffff',
    backgroundColor: theme.palette.tertiary.main,
  },
  listItemText: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
  },
}));

function RulesOfReturning(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const USState = useSelector((state) => state.customer?.location.stateCode);

  const getState = () => {
    let text = `${t(content[USState]?.state)} ${t('common.state')}`;

    if (USState === 'CA') {
      text = 'CA CRV';
    }

    return text;
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          {t('what.rulesOfReturning.title')}
        </Typography>
        <List dense>
          <ListItem>
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
              <Avatar
                className={classes.smallAvatar}
                classes={{ colorDefault: classes.listIcon }}
              >
                <Typography variant="h6">1</Typography>
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={t('what.rulesOfReturning.ruleOne', {
                state: getState(),
              })}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
              <Avatar
                className={classes.smallAvatar}
                classes={{ colorDefault: classes.listIcon }}
              >
                <Typography variant="h6">2</Typography>
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={t('what.rulesOfReturning.ruleTwo')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
              <Avatar
                className={classes.smallAvatar}
                classes={{ colorDefault: classes.listIcon }}
              >
                <Typography variant="h6">3</Typography>
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={t('what.rulesOfReturning.ruleThree')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
              <Avatar
                className={classes.smallAvatar}
                classes={{ colorDefault: classes.listIcon }}
              >
                <Typography variant="h6">4</Typography>
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={t('what.rulesOfReturning.ruleFour')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
              <Avatar
                className={classes.smallAvatar}
                classes={{ colorDefault: classes.listIcon }}
              >
                <Typography variant="h6">5</Typography>
              </Avatar>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={t('what.rulesOfReturning.ruleFive')}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

export default RulesOfReturning;
