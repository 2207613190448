import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, shallowEqual } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  detail: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.tertiary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
}));

function CheckoutShipping(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const customer = useSelector((state) => state.auth.customer, shallowEqual);
  const [open, setOpen] = React.useState(true);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        classes={{ root: classes.listRoot }}
        button
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography color="primary" className={classes.title}>
            {t('ecosupplies.shippingAddress')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box py={1} pl={3}>
          <Typography>
            {t('ecosupplies.allOrdersDescription')}{' '}
            <NavLink exact to="/contact" className={classes.link}>
              {t('ecosupplies.contact')}{' '}
            </NavLink>
            {t('ecosupplies.recycletekTeamMember')}
          </Typography>
          <Box pt={2}>
            <Typography>{customer.street}</Typography>
            <Typography>{customer.housenumber}</Typography>
            <Typography>{customer.apt && `Apt #: ${customer.apt}`}</Typography>
            <Typography>
              {customer.city && `${customer.city}, ${customer.state}`}
            </Typography>
            <Typography>{customer.zipcode}</Typography>
          </Box>
          <NavLink exact to="/portal/account" className={classes.link}>
            {t('ecosupplies.updateAddress')}
          </NavLink>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default CheckoutShipping;
