import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import MemberPortal from '../../views/MemberPortal/MemberPortal';

export default function PrivateRoute({ path }) {
  const auth = useSelector((state) => state.auth);

  if (auth.isLoading) {
    return <CircularProgress />;
  } else if (!auth.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: window.location.pathname },
        }}
      />
    );
  } else {
    return <Route exact path="/portal/:page" component={MemberPortal} />;
  }
};
