import React from 'react';
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

const DashTab = withStyles((theme) => ({
  root: {
    display: 'flex',
    textTransform: 'none',
    minWidth: 72,
    fontSize: theme.typography.pxToRem(18),
    margin: `0 ${theme.spacing(3)}px`,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },

    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    '& img': {
      height: 'auto',
      width: '37px',
      margin: '0 1rem 0 0',
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
  },
}))((props) => {
  return <Tab disableRipple {...props} />;
});

export default DashTab;
