import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// assets
import recycletekLogo from 'assets/img/logo/logoOnly.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    transform: 'translate(-50%, -85%)',
    height: '40px',
    width: '36px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3px',
    backgroundColor: theme.palette.tertiary.main,
    clipPath:
      'polygon(0% 0%, 100% 0%, 100% 83%, 61% 84%, 50% 100%, 39% 84%, 0 83%)',
  },
  logo: {
    filter: 'brightness(0) invert(0.98)',
    height: '26px',
    width: 'auto',
  },
  open: {
    backgroundColor: '#008EAA',
    zIndex: 4,
  },
  soon: {
    backgroundColor: '#7C878E',
    opacity: '0.7',
    zIndex: 2,
  },
  closed: {
    backgroundColor: '#FFC845',
    zIndex: 3,
  },
}));

function Pin({ status }) {
  const classes = useStyles();

  const classNames = clsx({
    [classes.icon]: true,
    [classes.soon]: status === 'coming-soon',
    [classes.open]: status === 'open',
    [classes.closed]: status === 'closed',
  });

  return (
    <div className={classNames}>
      <img
        className={classes.logo}
        src={recycletekLogo}
        alt="recycletek logo map marker"
      />
    </div>
  );
}

export default Pin;
