import axios from 'axios';

// google maps platform address validation api

export const validateAddress = (street, apt, city, state, zipcode) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        'https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyADxFGc2Gec58t95waF6bcpgiU5tpPyuGM',
        {
          address: {
            regionCode: 'US',
            addressLines: [street, `#${apt}`, city, state, zipcode],
          },
        },
      )
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
