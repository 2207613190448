import { DateTime } from 'luxon';
const now = DateTime.local();

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function getPercent(number, total, fixed) {
  const percent = parseFloat(((number / total) * 100).toFixed(fixed));
  return percent ? percent : 0;
}

export function getTotal(numbers) {
  let total = 0;

  for (const key in numbers) {
    if (key !== 'co2_g') {
      total += numbers[key];
    }
  }

  return total;
}

export function getMetricLuxon(date) {
  let lux = DateTime.fromISO(date);
  lux = lux.isValid ? lux : DateTime.fromHTTP(date);
  return lux;
}

export function calcPercentages(totals) {
  let total = getTotal(totals);
  const per = {};
  for (const key in totals) {
    if (key !== 'co2_g') {
      per[key] = getPercent(totals[key], total, 2);
    }
  }
  per['total'] = total;
  return per;
}

export function calcMonthlyTotal(dates) {
  const total = {};
  const byContainer = {
    Aluminum: {},
    Glass: {},
    Plastic: {},
  };

  const metricDay = {};

  if (dates.length === 0) {
    const totalData = { labels: [months[now.c.month - 1]], metrics: [0] };
    const glassData = {
      type: 'Glass',
      labels: [months[now.c.month - 1]],
      metrics: [0],
    };
    const aluminumData = {
      type: 'Aluminum',
      labels: [months[now.c.month - 1]],
      metrics: [0],
    };
    const plasticData = {
      type: 'Plastic',
      labels: [months[now.c.month - 1]],
      metrics: [0],
    };
    return { totalData, glassData, aluminumData, plasticData };
  }

  for (const dropOff in dates) {
    // change date to DateTime
    const date = getMetricLuxon(dates[dropOff].datetime);
    const month = date.month;
    const type = dates[dropOff].material_type;
    const amount = dates[dropOff].count;
    if (month !== '' && type !== '') {
      const label = months[month - 1];

      // add to total by months
      total[label]
        ? (total[label] = total[label] += amount)
        : (total[label] = amount);

      // add to container type by month
      if (byContainer[type]) {
        byContainer[type][label]
          ? (byContainer[type][label] = byContainer[type][label] += amount)
          : (byContainer[type][label] = amount);
      } else {
        byContainer[type] = {};
        byContainer[type][label] = amount;
      }
    }

    if (!metricDay[dates[dropOff].datetime]) {
      metricDay[dates[dropOff].datetime] = {
        total: dates[dropOff].count,
        date: dates[dropOff].datetime,
        saved: dates[dropOff].co2_saved_g,
      };
      metricDay[dates[dropOff].datetime][dates[dropOff].material_type] =
        dates[dropOff];
    } else {
      metricDay[dates[dropOff].datetime].total =
        metricDay[dates[dropOff].datetime].total + dates[dropOff].count;
      metricDay[dates[dropOff].datetime].saved =
        metricDay[dates[dropOff].datetime].saved + dates[dropOff].co2_saved_g;
      metricDay[dates[dropOff].datetime][dates[dropOff].material_type] =
        dates[dropOff];
    }
  }

  let newTotal = {};

  // If only one month in the total add previous month to start chart at 0
  if (Object.keys(total).length === 1) {
    const index = months.indexOf(Object.keys(total)[0]);
    const prev = index === 0 ? months[months.length - 1] : months[index - 1];
    newTotal[prev] = 0;
  }

  newTotal = { ...newTotal, ...total };

  let totalData = formDataset(newTotal, 'total');

  let aluminumData = {
    type: 'Aluminum',
    ...formDataset(byContainer.Aluminum, 'aluminum'),
  };

  let glassData = { type: 'Glass', ...formDataset(byContainer.Glass, 'glass') };

  let plasticData = {
    type: 'Plastic',
    ...formDataset(byContainer.Plastic, 'plastic'),
  };

  return { totalData, glassData, aluminumData, plasticData, metricDay };
}

/**
 * Creates an array of 12 numbers to use as dataset for graphs
 * @param {object} dates an object of months with totals
 * @return {Array} An array of 12 numbers
 */
export function formDataset(dates, type) {
  const metrics = [];
  const labels = [];
  for (const [key, value] of Object.entries(dates)) {
    metrics.push(value);
    labels.push(key);
  }

  if (labels.length === 1 && type === 'total') {
    let index = months.indexOf(labels[0]);
    labels.unshift(months[index - 1]);
    metrics.unshift(0);
  }

  return { metrics, labels };
}
