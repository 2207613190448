import { createStore, applyMiddleware } from 'redux';
import * as Sentry from '@sentry/react';
import { rootReducer } from './reducers/';
import { saveState } from './localStorage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';

const middleware = [thunk];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = createStore(
  rootReducer,
  // persistedState,
  composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer)
  // composeWithDevTools(applyMiddleware(...middleware))
);

store.subscribe(() => {
  saveState({
    user: store.getState().auth,
  });
});

store.subscribe(
  throttle(() => {
    saveState({
      user: store.getState().auth,
    });
  }, 1000)
);
