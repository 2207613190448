import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
} from '@material-ui/core';

// assets
import evtekRecycleBag from 'assets/img/recycletek-bag-bg.png';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  },
  header: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    objectFit: 'contain',
    [theme.breakpoints.up('xl')]: {
      width: '100vw',
    },
  },
  headerTitle: {
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    top: '18%',
    left: '45%',
    fontSize: '1.3rem',
    '@media (min-width:300px)': {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '8rem',
    },
  },
}));

function TitleSection(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box className={classes.main}>
      <Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                className={classes.header}
                image={evtekRecycleBag}
              ></CardMedia>
              <Typography variant="h1" className={classes.headerTitle}>
                <Trans>{t('howItWorks.title')}</Trans>
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default TitleSection
