export const wasteBinTypes = [
  { name: 'Plastic', id: '1' },
  { name: 'Aluminum', id: '2' },
  { name: 'Glass', id: '3' },
  { name: 'Plastic/Aluminum', id: '4' },
];

// create an array of bag numbers as a string
export const numBags = (num) => {
  const numArray = [];
  for (let i = 0; i <= num; i++) {
    let numString = i.toString();
    numArray.push(numString);
  }

  return numArray;
};

export const weekDays = [
  'Not Sure',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const containerEstimate = ['<1500', '1500-3000', '3000-4500', '>4500'];

export const frequency = ['Not Sure', 'Weekly', 'BiWeekly', 'Monthly'];

export const rruleWeekDays = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
};

export const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
