import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Hidden, Button, Grid } from '@material-ui/core';

// Material UI Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// components
import CaptchaForm from '../Forms/CaptchaForm';
import EmailPhoneVerification from './EmailPhoneVerification';

// styles
const useStyles = makeStyles((theme) => ({
  step: {
    padding: '8px 25px',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(21),
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
    textAlign: 'center',
    maxWidth: '400px',
    margin: '10px auto 2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
      margin: '10px auto 2rem',
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  number: {
    fontWeight: 500,
  },
  numberInput: {
    fontFamily: 'monospace',
    MozAppearance: 'textfield',
    borderRadius: '6px',
    border: '1px solid',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
    margin: '4px',
    paddingLeft: '8px',
    width: '36px',
    height: '42px',
    fontSize: '32px',
    boxSizing: 'border-box',
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'lightgrey',
  },
  subtext: {
    textAlign: 'center',
    maxWidth: '300px',
    margin: '0 auto',
    fontSize: theme.typography.pxToRem(14),
  },
  resendButton: {
    border: 'none',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    color: theme.palette.tertiary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  backButton: {
    width: '100%',
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  backText: {
    marginLeft: theme.spacing(2),
  },
}));

export default function Verification(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [digits, setDigits] = React.useState('');
  const [customerEmail, setCustomerEmail] = React.useState('');
  // Captcha
  const {
    id_email_auth,
    id_phone_number_auth,
    phone_verified,
    email_verified,
  } = useSelector((state) => state.verification);

  React.useEffect(() => {
    const { phone_number, email } = props.newCustomer;
    setCustomerEmail(email);
    const digits = phone_number.slice(phone_number.length - 4);
    setDigits(digits);
  }, [props.newCustomer]);

  React.useEffect(() => {
    const { phone_number, email } = props.newCustomer;
    if (phone_number && email) {
      email_verified && phone_verified && props.handleSubmitNewCustomer();
    } else if (!phone_number && email) {
      email_verified && props.handleSubmitNewCustomer();
    } else if (!email && phone_number) {
      phone_verified && props.handleSubmitNewCustomer();
    }
  }, [email_verified, phone_verified]);

  return (
    <React.Fragment>
      <Box>
        <Hidden smUp>
          <Box mt={1}>
            <Typography className={classes.step} color="primary" align="center">
              {t('signup.steps.stepFour')}
            </Typography>
          </Box>
        </Hidden>
        {id_email_auth === '' && id_phone_number_auth === '' ? (
          <Typography variant="h5" align="center">
            {t('signup.form.accountVerification')}
          </Typography>
        ) : null}
      </Box>
      <Box mb={3}>
        {id_email_auth === '' && id_phone_number_auth === '' ? (
          <Box mt={3}>
            <Typography className={classes.title}>
              {t('signup.form.answerToReceiveVerificationEmail')}
            </Typography>
            <CaptchaForm
              userInfo={props.newCustomer}
              handleBack={props.handleBack}
            />
          </Box>
        ) : (
          <React.Fragment>
            <EmailPhoneVerification newCustomer={props.newCustomer} />
            <Box>
              <Typography className={classes.subtext}>
                {t('signup.form.itMayTakeAMinuteToReceiveCode')}{' '}
                <button
                  className={classes.resendButton}
                  onClick={() => props.handleResend(props.newCustomer)}
                >
                  {' '}
                  {t('signup.form.resendCodes')}
                </button>{' '}
              </Typography>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Box>
        <Grid container flex justify="center">
          <Grid item xs={12} sm={8}>
            <Button
              variant="contained"
              onClick={props.handleBack}
              className={classes.backButton}
            >
              <ArrowBackIcon />
              <span className={classes.backText}>{t('buttons.back')}</span>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
