import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Container } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import GoogleTranslator from 'components/GoogleTranslator/GoogleTranslator';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    margin: theme.spacing(8, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4, 0),
      fontSize: theme.typography.pxToRem(18),
    },
  },
  listTitle: {
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const PrivacyPolicy = (props) => {
  const classes = useStyles();

  return (
    <Page>
      <div className={classes.main}>
        <Container maxWidth="md" className={classes.container}>
          <Box>
            <Typography align="center" component="h2" className={classes.title}>
              Privacy Policy of EVTEK, Inc. (“Company”)
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.text}>
              Company operates https://www.evtek.co (the"Site"). This page
              informs you of our policies regarding the collection, use and
              disclosure of Personal Information we receive from users of the
              Site and our products and services.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              1. Information Use
            </Typography>
            <Typography className={classes.text}>
              We use your Personal Information for providing and improving the
              Site and associated products and services. By using the Site, you
              agree to the collection and use of information in accordance with
              this policy.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              2. Information Collection
            </Typography>
            <Typography className={classes.text}>
              While using our Site, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you. Personally identifiable information may include, but
              is not limited to your name ("Personal Information"). Information
              derived from the processing of recyclable and non-recyclable items
              and materials, including but not limited to brand, flavor, and
              size, will be associated with your Personal Information.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>3. Log Data</Typography>
            <Typography className={classes.text}>
              We collect information that your browser sends whenever you visit
              our Site ("Log Data"). This Log Data may include information such
              as your computer's Internet Protocol ("IP") address, browser type,
              browser version, the pages of our Site that you visit, the time
              and date of your visit, the time spent on those pages and other
              statistics. In addition, we may use third party services such as
              Google Analytics that collect, monitor and analyze this data.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              4. Communications
            </Typography>
            <Typography className={classes.text}>
              We may use your Personal Information to contact you with
              newsletters, marketing or promotional materials and other
              information.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>5. Cookies</Typography>
            <Typography className={classes.text}>
              A cookie is a small data packet exchanged between computer
              programs and stored on your end device (such as your desktop
              computer, notebook or smartphone) when you visit a website.
              Cookies contain a cookie ID, which acts as a unique identifier for
              each specific cookie. This ID consists of a sequence of
              characters, which can be used to link websites and servers to the
              specific Internet browser in which the cookie is stored. This
              enables the websites and servers visited to distinguish the user’s
              browser from other browsers that contain different cookies. A
              specific Internet browser can be recognized and identified via the
              unique cookie ID. We use "cookies" to collect information. You can
              instruct your browser to refuse all cookies or to indicate when a
              cookie is being sent. However, if you do not accept cookies, you
              may not be able to use some portions of our Site.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>6. Security</Typography>
            <Typography className={classes.text}>
              The security of your Personal Information is important to us, but
              no method of transmission over the Internet, or method of
              electronic storage, is 100% secure. While we strive to use
              commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>
              7. Changes To This Privacy Policy
            </Typography>
            <Typography className={classes.text}>
              This Privacy Policy is effective as of June 1st, 2021 and will
              remain in effect except with respect to any changes in its
              provisions in the future, which will be in effect immediately
              after being posted on this document and or page. We reserve the
              right to update or change our Privacy Policy at any time and this
              Privacy Policy should be checked periodically. Your continued use
              of the Service after we make any modifications to this Privacy
              Policy on this page or in this document will constitute your
              acknowledgment of the modifications and your consent to abide and
              be bound by the modified Privacy Policy. If we make any material
              changes to this Privacy Policy, we will notify you either through
              the email address you have provided to us, or by placing a
              prominent notice on our website.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography className={classes.listTitle}>8. Contact Us</Typography>
            <Typography className={classes.text}>
              If you have any questions about this Privacy Policy, please
              contact us at info@recycletek.co.
            </Typography>
          </Box>
          <GoogleTranslator />
        </Container>
      </div>
    </Page>
  );
};

export default PrivacyPolicy;
