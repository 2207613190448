import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import {
  FormControl,
  FormControlLabel,
  Typography,
  Box,
  TextField,
  Tooltip,
  Badge,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  list: {
    // padding: theme.spacing(1.5)
    margin: 0,
    paddingLeft: '12px',
  },
  charity: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  link: {
    fontWeight: 600,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '-11px',
    top: '-7px',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: theme.palette.primary.main,
  },
}))(Badge);

export default function PaymentForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Typography>{t('schedule.paymentForm.paymentMethod')}</Typography>
      <FormControl
        component="fieldset"
        error={props.error}
        className={classes.formControl}
      >
        <RadioGroup
          aria-label="payment"
          name="payment"
          value={props.value}
          onChange={props.handlePaymentChange}
        >
          <FormControlLabel
            value="charity"
            control={<Radio />}
            label={
              <React.Fragment>
                <FormLabel>
                  {t('schedule.paymentForm.donateToCharity')}
                </FormLabel>
                <HtmlTooltip
                  enterTouchDelay={0}
                  interactive
                  arrow
                  onClick={(e) => e.preventDefault()}
                  title={
                    <React.Fragment>
                      <Box p={1}>
                        <p style={{ marginBottom: 0 }}>
                          {t('schedule.paymentForm.visitOur')}{' '}
                          <NavLink
                            exact
                            to="/giving-back"
                            className={classes.link}
                            target="_blank"
                          >
                            {t('schedule.paymentForm.givingBack')}
                          </NavLink>{' '}
                          {t('schedule.paymentForm.toLearnMore')}
                        </p>
                      </Box>
                    </React.Fragment>
                  }
                >
                  <StyledBadge badgeContent={'?'} color="secondary" />
                </HtmlTooltip>
              </React.Fragment>
            }
          />

          <Box mb={1} className={classes.charity}>
            <CustomAutoComplete
              placeholder={t('schedule.paymentForm.chooseACharity')}
              disabled={props.value === 'charity' ? false : true}
              id="charity"
              list={props.list}
              value={props.charity}
              defaultValue={props.charity}
              onChange={props.handleCharityChange}
              style={{ width: 225 }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <TextField {...params.inputProps} />
                </div>
              )}
            />
            <FormHelperText error={props.charityError}>
              {props.charityError
                ? t('schedule.paymentForm.pleaseSelectACharity')
                : ''}
            </FormHelperText>
          </Box>
          {props.type !== 'curbside' && (
            <FormControlLabel
              value="Cash"
              control={<Radio disabled={props.type === 'curbside'} />}
              label={
                <React.Fragment>
                  <FormLabel className={classes.formLabel}>
                    {t('schedule.paymentForm.addToAccountBalance')}
                  </FormLabel>
                  <HtmlTooltip
                    arrow
                    enterTouchDelay={0}
                    onClick={(e) => e.preventDefault()}
                    title={
                      <React.Fragment>
                        <p>
                          {t(
                            'schedule.paymentForm.onlyAvailableForBulkPickups',
                          )}
                        </p>
                      </React.Fragment>
                    }
                  >
                    <StyledBadge badgeContent={'?'} color="secondary" />
                  </HtmlTooltip>
                </React.Fragment>
              }
            />
          )}
        </RadioGroup>
        <FormHelperText error={props.paymentError}>
          {props.paymentError
            ? t('schedule.paymentForm.pleaseSelectPaymentOption')
            : ''}
        </FormHelperText>
      </FormControl>
    </React.Fragment>
  );
}

PaymentForm.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.bool,
  value: PropTypes.string,
};
