import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Box, Hidden } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import Login from './Login';
import SignUp from './SignUp';

// assets
import logo from 'assets/img/portal/logo_blue_croped3.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    minHeight: 'calc(100vh - 80px)',

    backgroundColor: theme.palette.background.portal,
    '& > *': {
      zIndex: 100,
    },
    [theme.breakpoints.down('md')]: {
      '&:before': {
        content: '',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundPosition: 'right top',
        backgroundImage: `url(${logo})`,
        backgroundSize: '500px',
        opacity: '.125',
      },
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 70px)',
    },
    [theme.breakpoints.down('xs')]: {
      '&:before': {
        backgroundSize: '80%',
      },
    },
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    width: '500px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundSize: '90%',
  },
  actionText: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.125rem',
    marginBottom: '.5rem',
  },
  inputBox: {
    padding: theme.spacing(12),
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(3),
    },
  },
  actionButton: {
    width: '9rem',
    '&:focus': {
      color: 'white',
    },
  },
}));

export default function LoginSignUp(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Page>
      <Grid container component="main" className={classes.root}>
        {/* Column 1 */}
        <Grid item component="div" className={classes.inputBox}>
          {location.pathname === '/signup' ? <SignUp /> : <Login />}
        </Grid>
        {/* Column 2  */}
        <Hidden mdDown>
          <Grid item component="div" className={classes.action}>
            <Box p={4}>
              <div>
                <Typography align="center" className={classes.actionText}>
                  {location.pathname === '/signup'
                    ? t('login.alreadyAMember')
                    : t('login.notAMember')}
                </Typography>
              </div>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  component={Link}
                  to={location.pathname === '/signup' ? '/login' : '/signup'}
                >
                  {location.pathname === '/signup'
                    ? t('login.logIn')
                    : t('signup.signUp')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Page>
  );
}
