import React from 'react';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Box, Typography, Avatar } from '@material-ui/core';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '550px',
    backgroundColor: theme.palette.teal.main,
    borderRadius: 20,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(21),
    paddingLeft: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.yellow.main,
  },
  navLink: {
    fontWeight: 700,
    fontSize: 'inherit',
    letterSpacing: '.8px',
    color: theme.palette.yellow.main,
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: theme.palette.primary.main,
      background: 'inherit',
    },
  },
}));

function ContactCallout(props) {
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={3}>
          <Avatar
            className={classes.iconAvatar}
            classes={{ colorDefault: classes.icon }}
          >
            <QuestionAnswerIcon fontSize="large" />
          </Avatar>
          <Typography className={classes.title}>
            Didn't find the answer you need?
          </Typography>
        </Box>
        <Box px={3} className={classes.text}>
          <Typography>
            Reach out with your question through our {` `}
            <NavLink exact to="/contact" className={classes.navLink}>
              contact form
            </NavLink>
            .
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ContactCallout;
