import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import faqStyle from 'assets/jss/web-app/faqStyle';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(faqStyle);

function FAQListItem(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const services = useSelector((state) => state.customer.location.services);
  const [open, setOpen] = React.useState(false);
  const { question, answer } = props.item;
  // Opens and closes dropdown
  const handleClick = () => {
    setOpen(!open);
  };

  const showFAQ = () => {
    const allServices = props.item.service === 'all';
    const curbside = props.item.service === 'curbside' && services.curbside;
    let isShown = false;

    if (curbside) {
      isShown = true;
    } else if (allServices) {
      isShown = true;
    }

    return isShown;
  };

  return (
    <>
      {showFAQ() && (
        <>
          <ListItem
            id={props.num}
            classes={{ root: classes.listRoot }}
            button
            onClick={handleClick}
          >
            <ListItemText className={classes.titleItem}>
              <Typography className={classes.title}>{t(question)}</Typography>
            </ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pt={1} pb={3} px={3}>
              {answer}
              <Box mt={3}>
                <Typography className={classes.bold}>
                  {t('faq.needMoreHelp')}{' '}
                  <NavLink exact to="/contact" className={classes.link}>
                    <span className={classes.bold}>{t('faq.getInTouch')}</span>
                  </NavLink>
                </Typography>
              </Box>
            </Box>
          </Collapse>
          <Divider />
        </>
      )}
    </>
  );
}

export default FAQListItem;
