import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: 10,
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  testing: {
    border: '3px solid red',
  },
  focused: {},
}));

const CurbsideBadge = withStyles((theme) => ({
  badge: {
    right: '11px',
    top: '8px',
    padding: '0 4px',
    color: theme.palette.secondary.main,
  },
}))(Badge);

/**
 * Date picker with keyboard and popup input abilities
 * setSelectedDate function and setSelected to be provided by parent component
 *const [selectedDate, setSelectedDate] = useState(new Date());
 */

function ScheduleFormDatePicker(props) {
  const classes = useStyles();
  const pickups = useSelector((state) => state.pickups);

  const [max, setMax] = React.useState(null);
  const [min, setMin] = React.useState(null);
  const [curbsideDays, setCurbsideDays] = React.useState([]);

  React.useEffect(() => {
    const threeMonthDate = DateTime.local().plus({ months: 3 });
    const currentDate = DateTime.local();
    const previousMonth = DateTime.local().minus({ month: 1 });

    // sets max month that member can click to
    // currently removed - causes calendar to break on mobile
    setMax(
      `${threeMonthDate.c.month}-${threeMonthDate.daysInMonth}-${threeMonthDate.c.year}`,
    );

    // sets min month that member can click to
    // currently removed - causes calendar to break on mobile
    setMin(`${currentDate.c.month}-01-${currentDate.c.year}`);

    // curbside pic kup is available get all curbside pickup dates for the next three months
    // if (pickups.serviceType.curbside) {
    let threeMonthCurbside = [];

    pickups.availablePickupDays.map((availableDay) => {
      // get an array of date from rrule.js
      const dates = availableDay.rrule.between(
        new Date(Date.UTC(previousMonth.c.year, 1, previousMonth.c.month)),
        new Date(
          Date.UTC(
            threeMonthDate.c.year,
            threeMonthDate.c.month,
            threeMonthDate.daysInMonth,
          ),
        ),
      );

      threeMonthCurbside = [...threeMonthCurbside, ...dates];
    });

    // change all the dates into luxon dates
    const curbsideLuxonDates = threeMonthCurbside.map((date) =>
      DateTime.fromJSDate(date)
        .toUTC()
        .setZone('local', { keepLocalTime: true })
        .toISODate(),
    );

    setCurbsideDays([...curbsideLuxonDates]);
  }, [pickups]);

  const filterSunday = (date) => {
    return date.weekday === 7;
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider
        libInstance={DateTime}
        utils={LuxonUtils}
        locale={'us'}
      >
        <DatePicker
          disabled={props.type === '' ? true : false}
          autoOk
          error={props.error}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          placeholder={props.placeholder}
          value={props.selectedDate}
          InputProps={{ classes }}
          // InputAdornmentProps={{ position: 'start' }}
          onClick={props.type === '' ? props.handleClick : null}
          onChange={(date) => props.handleDateSelect(date)}
          helperText={props.error ? props.helperText : ''}
          shouldDisableDate={filterSunday}
          renderDay={(
            day,
            selectedDate,
            isInCurrentMonth,
            dayComponent,
            DayProps,
          ) => {
            if (curbsideDays.includes(day.toISODate()) && isInCurrentMonth) {
              return (
                <CurbsideBadge variant="dot" color="secondary">
                  <div data-cy="curbside-badge">{dayComponent}</div>
                </CurbsideBadge>
              );
            } else {
              return dayComponent;
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}

export default ScheduleFormDatePicker;
