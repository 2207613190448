import React from 'react';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

// components
import OdometerNumber from 'views/Home/OdometerNumber';

const useStyles = makeStyles((theme) => ({
  number: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
      marginBottom: '40px',
    },
  },
}));

const DataDisplay = ({ topText, odometerNumber, bottomText }) => {
  const classes = useStyles();

  return (
    <Box className={classes.number}>
      {topText && <Typography>{topText}</Typography>}
      <OdometerNumber number={odometerNumber} />
      {bottomText && <Typography>{bottomText}</Typography>}
    </Box>
  );
};

export default DataDisplay;
