import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateCartItem, deleteCartItem, placeOrder } from 'redux/actions/shop';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import BasicCard from 'components/Card/BasicCard';

// utils
import { formatMoney } from 'utils/formatTransactions';
import { SERVER_URL } from 'config';

// import { ALERT_FAIL } from 'redux/actions/types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0.5),
    maxWidth: '300px',
  },
  container: {
    padding: theme.spacing(2),
  },
  empty: {
    fontSize: '.8rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  name: {
    fontWeight: 300,
  },
  amount: {
    padding: '0 14px',
    fontSize: theme.typography.pxToRem(14),
    display: 'inline',
  },
  plusButton: {
    marginLeft: '.5rem',
    color: theme.palette.tertiary.main,
    display: 'inline',
  },
  minusButton: {
    marginRight: '.5rem',
    color: theme.palette.tertiary.main,
    display: 'inline',
  },
  secondary: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

function CartCard(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { cart, totalPrice } = useSelector((state) => state.cart);

  const handleChangeAmount = (id, action) => {
    dispatch(updateCartItem(id, action));
  };

  const handleDeleteItem = (id) => {
    dispatch(deleteCartItem(id));
  };

  return (
    <BasicCard>
      <div>
        <Grid item xs={12} className={classes.container}>
          <Box mt={1}>
            <Typography color="primary" variant="h5">
              {t('ecosupplies.yourCart')}
            </Typography>
          </Box>
          <Divider />
          <div>
            <List>
              {cart.length > 0 ? (
                cart.map((product, index) => {
                  return (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar src={`${SERVER_URL}${product.img_uri}`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div className={classes.name}>
                            {product.name} -{' '}
                            {formatMoney(
                              product.price_microdollars * product.amount,
                            )}
                          </div>
                        }
                        secondary={
                          <span className={classes.secondary}>
                            <IconButton
                              className={classes.minusButton}
                              size="small"
                              aria-label="minus"
                              onClick={() =>
                                handleChangeAmount(product.id, 'minus')
                              }
                            >
                              <RemoveIcon name="minus" fontSize="inherit" />
                            </IconButton>

                            <span className={classes.amount}>
                              {product.amount}
                            </span>
                            <IconButton
                              className={classes.plusButton}
                              aria-label="minus"
                              size="small"
                              onClick={() =>
                                handleChangeAmount(product.id, 'add')
                              }
                            >
                              <AddIcon name="add" fontSize="inherit" />
                            </IconButton>
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          value={product.id}
                          edge="end"
                          aria-label="delete"
                          size="medium"
                          onClick={() => handleDeleteItem(product.id)}
                        >
                          <DeleteIcon name="add" fontSize="inherit" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
              ) : (
                <Typography className={classes.empty}>
                  {t('ecosupplies.cartIsEmpty')}
                </Typography>
              )}
            </List>
          </div>
          <Divider />
          <Box data-cy="sub-total">
            <Typography variant="subtitle2">
              {t('ecosupplies.subtotal')} {formatMoney(totalPrice)}
            </Typography>
          </Box>
          <Divider />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              component={Link}
              to={'/portal/checkout'}
            >
              <Typography variant="button">{t('buttons.checkout')}</Typography>
            </Button>
          </Box>
        </Grid>
      </div>
    </BasicCard>
  );
}

export default CartCard;
