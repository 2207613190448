import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Hidden, Container, Box } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

const useStyles = makeStyles((theme) => ({
  headerImage: {
    padding: '0',
    margin: '0',
    right: '0',
    position: 'absolute',
    maskImage: 'linear-gradient(black, transparent)',
    '& img': {
      width: '100%',
      maxWidth: '423px',
      maxHeight: '850px',
    },
  },
  posts: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  postsLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  parallax: {
    width: '100%',
    height: '600px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const RecycletekTimeline = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const timelineEntries = [
    {
      year: 2017,
      primaryText: t('about.acceleratorWinner'),
      secondaryText: '',
    },
    {
      year: 2018,
      primaryText: t('about.companyIncorporated'),
      secondaryText: '',
    },
    {
      year: 2019,
      primaryText: t('about.marketResearch'),
      secondaryText: '',
    },
    {
      year: 2020,
      primaryText: t('about.ecoCountModelOne'),
      secondaryText: '',
    },
    {
      year: 2021,
      primaryText: t('about.ecoCountModelTwo'),
      secondaryText: t('about.familyAndFriendsFunded'),
    },
    {
      year: 2022,
      primaryText: t('about.ecoCountModelTwoPointFive'),
      secondaryText: t('about.calRecycleGrant'),
    },
    {
      year: 2023,
      primaryText: t('about.calRecyclePilotLaunch'),
      secondaryText: '',
    },
    {
      year: 2024,
      primaryText: t('about.ecoCountThreeManufacturing'),
      secondaryText: '',
    },
  ];

  return (
    <Timeline align="alternate" style={{ width: '100%' }}>
      {timelineEntries.map((entry) => (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="secondary"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent className={classes.timeline_entry}>
            <Typography variant="h6" component="h1">
              {entry.year}
            </Typography>
            <Typography>{entry.primaryText}</Typography>
            {entry.secondaryText && (
              <Typography>{entry.secondaryText}</Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default RecycletekTimeline;
