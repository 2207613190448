import React from 'react';
// import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Container, Toolbar } from '@material-ui/core';

import Carousel from 'components/Carousel/Carousel'

import ecoSystemImage from '../../assets/img/ecosystem.png';
import recycletekMapNA from '../../assets/img/recycletek_map_northamerica.png'
/*
import recycletekMapCA from '../../assets/img/recycletek_map_ca.png';
import recycletekMapNY from '../../assets/img/recycletek_map_ny.jpg';
*/
import recycletekTrailer from '../../assets/img/recycletek_trailer.png';
import recycletek_ecocount25m from '../../assets/img/ecocount25m.JPG'
import recycletek_ecocount25m_2 from '../../assets/img/recycletek_ecocount_2.JPG'
import recycletek_ai_vision from '../../assets/img/recycletek_ai_vision.jpg'

// components
import Page from 'components/Page/Page';
import PartnerForm from './Sections/PartnerForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeText: {
    fontSize: '1.9rem',
    fontWeight: '300',
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      fontWeight: '400',
      lineHeight: 1.5,
    },
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  videoBox: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: '2rem',
  },
  title_secondary: {
    color: theme.palette.tertiary.main,
    fontSize: '2.25rem',
    fontWeight: 400,
    marginBottom: '1.2rem',
  },
  subtitle: {
    color: theme.palette.tertiary.main,
    fontSize: '28px',
    fontWeight: '400',
  },
  textBlock: {
    textAlign: 'justify',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  }
}));

const InvestorRelations = (props) => {
  const classes = useStyles();
  // const { t, i18n } = useTranslation();

  /*
  const images_markets = [
    {
      label: '<br/><b>North America</b><<br/>Serviceable Market Volume: $11 Billion<br/>',
      imgPath: recycletekMapNA,

    },
    {
      label: '<br /><b>California</b><br /><b>1260 Redemption Centers</b><br /><br />EcoCount modernizes & streamlines<br /><br />3 Meetings = 3 Buyers',
      imgPath: recycletekMapCA,
    },
    {
      label: '<br /><b>New York</b><br /><b>1771 Redemption Centers</b><br /><br />EcoCount modernizes & streamlines<br /><br />11 Meetings = 10 Buyers',
      imgPath: recycletekMapNY,
    }
  ];
  */

  const images_current_state = [
    { videoPath: 'https://www.youtube.com/embed/ukqH6nIXDA0?si=cO7gzLexjRJEgSUj&showinfo=1&modestbranding=1' },
    { imgPath: recycletekTrailer, label: 'Recycletek EcoCount 2.5 Trailer in CA' },
    { imgPath: recycletek_ai_vision, label: 'Recycletek AI Vision' },
    { imgPath: recycletek_ecocount25m, label: 'EcoCount 2.5m' },
    { imgPath: recycletek_ecocount25m_2, label: 'EcoCount 2.5m' }
  ]

  return (
    <Page>
      <div className={classes.main}>
        <Toolbar />
        <Container maxWidth="lg" className={classes.container} style={{ marginBottom: '1.5rem' }}>
          <Typography variant="h2" className={classes.title}>
            Investor Information
          </Typography>
          <Typography className={classes.largeText}>
            Recycletek is a Deep Tech startup focused on providing software and hardware Bulk Buy-Back technologies to stakeholders across the Recycling & Redemption Industries.
            Recycletek’s technologies and services allow local Recycling/Redemption Providers (B2B) and individual consumers (B2C) to buy and sell deposit containers faster and more accurately than current leading solutions.
            B2B customers lease Recycletek’s hardware and utilize a software subscription model.
          </Typography>

          <br />

          <Typography className={classes.largeText}>
            <a href="#contact-form" className={classes.link}><i className="fa fa-arrow-right" aria-hidden="true"></i> Contact us now to learn more!</a>
          </Typography>
        </Container>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container justify="space-between" spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.subtitle}>
                Value Proposition
              </Typography>
              <Typography className={classes.textBlock} variant="caption">
                Recycletek’s value proposition is an AI-powered Bulk Buy-Back machine, EcoCount, and supporting software platform that incentivizes people & businesses to recycle their high-quality recyclables.<br /><br />
                <img src={ecoSystemImage} style={{ width: '100%' }} />

              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.subtitle}>
                Unique Selling Point
              </Typography>
              <Typography className={classes.textBlock} variant="caption">
                Our solution is designed to solve pain-points experienced by Redemption Centers, Initiators/3rd-Party Collection Agents, Super Markets and End-Users <br /><br />
                <b>&gt; Speed:</b> We aim to offer the fastest container buyback system.
                Our machines can process 1000 containers per minute - that is 4x50X faster than leading RVM and Bulk counting technologies<br /><br />
                <b>&gt; Security through transparency: </b>
                Designed to digitally document 100% of transaction-ledgers connecting recyclables, customers, machines and processors in one coherent software ecosystem.<br /><br />

                <b>&gt; Design for Excellence:</b><br />
                Our machines are designed to have the smallest footprint to speed ratio. We aim to have 50% less maintenance downtime than leading counting and buyback technologies.
              </Typography>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container justify="space-between" spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.subtitle}>
                Competitive Advantage
              </Typography>
              <Typography className={classes.textBlock} variant="caption">
                Current redemption providers use slow and unorganized systems to buy, pay, and track the number of beverage containers they purchase from customers and sell to vendors.
                Recycletek’s AI-powered technology allows redemption providers to conduct business 50X faster than leading solutions with 99% accuracy and security.
                <br /><br />
                Multiple relationships with the largest redemption centers in NY and CA provide a significant advantage for industry integration and technology deployment.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.subtitle}>
                Market opportunities
              </Typography>
              <Typography className={classes.textBlock} variant="caption">
                Our main target customers are Redemption Centers and Super Markets.
                The North American serviceable market has an estimated volume of <b>$11 Billion</b>.<br />
              </Typography>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img src={recycletekMapNA} style={{ width: '50%' }}></img>
              </div>
              <Typography className={classes.textBlock} variant="caption">
                We estimate a obtainable-market volume of $4 Billion for New York and California (our current bases of operation).

              </Typography>
              {/* <Carousel images={images_markets} autoplay={false} style="imgtext" /> */}
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container justify="space-between" spacing={5}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography gutterBottom className={classes.subtitle}>
                Current status
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Carousel images={images_current_state} autoplay={false} style="img" />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography className={classes.textBlock} variant="caption">
                Recycletek services customers in New York and California with 5 deployed EcoCount systems at the moment.
                We are working with a contract manufacturer to develop our first mass-produced systems in April/May of 2024.<br />
                <br />
                We received 3 non-dilutive Grants and raised a SAFE Note to build our prototypes and software and successfully built an exceptional Team with complementing backgrounds and skills to deliver excellence in everything we do. <br />
                <br />
                Customers love our technology - we already have a lot of pre-orders and letters of intent, and we are constantly integrating their feedback to make our ecosystem even better!
              </Typography>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container justify="space-between" spacing={5}>

            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.subtitle}>
                Revenue Model
              </Typography>
              <Typography className={classes.textBlock} variant="caption">
                The primary revenue source is earned through leasing of our AI Vision processing technology to Redemption Providers (such as redemption centers and super markets) and the associated enterprise software subscriptions.
                <br /><br />
                Secondary sources of revenue consist of premium-user subscriptions, consumer data sales, e-commerce commissions, and ad revenue.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.subtitle}>
                Funding and Use of Investments
              </Typography>
              <Typography className={classes.textBlock} variant="caption">
                Recycletek achieved a great product-market-fit and is currently transitioning into the Growth phase.<br /><br />
                We are looking to raise $6 Million to fund and increase key staff to continue delivering an exceptional customer experience,
                scale up research and development to further improve performance and roll out new features and services,
                and launch marketing- and sales-initiatives to drive revenue in the next 36 months.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <div id="contact-form"></div>
        <PartnerForm />
      </div>
    </Page>
  );
};

export default InvestorRelations;
