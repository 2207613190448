import { DateTime } from 'luxon';
import { t } from 'i18next';
import { nearestRecurringDate, sortSkippedDates } from 'utils/dateFunctions';

import {
  RECURRING_COLOR,
  RECURRING_DAYS_COLOR,
  SINGLE_COLOR,
  SKIP_COLOR,
} from './eventConfig';

// const now = DateTime.local();

export function createAvailableDaysEvent(availablePickupDays) {
  const availableDaysFcEvents = availablePickupDays.map((day) => {
    return {
      id: day.id,
      title: t('schedule.curbsideAvailable'),
      display: 'background',
      color: RECURRING_DAYS_COLOR,
      editable: false,
      rrule: {
        freq: day.frequency,
        interval: day.interval,
        dtstart: day.startdate,
        until: day.until,
      },
    };
  });
  return availableDaysFcEvents;
}

/**
 * Determines title of pickup event
 * @param {Object} date object from backend with key values of bulk and curbside
 * @return {String} title of either "Bulk" or "Curbside"
 */
export function createPickupTitle(date) {
  const title = date.bulk
    ? t('schedule.bulkPickup')
    : t('schedule.curbsidePickup');
  return title;
}

/**
 * Converts an array of single or skip pickup requests to FullCalendar Events
 * @param {Array} pickups Array of pickup requests, either skip or single
 * @param {Boolean} isSkip Is the array skip or single requests
 * @return {Array}        An array of FullCalendar Events
 */
export function createSingleEvents(pickups, isSkip) {
  return pickups.map((pickup) => {
    const eventTitle = createPickupTitle(pickup);
    return {
      title: isSkip ? t('schedule.skippedPickup') : eventTitle,
      date: pickup.date,
      allDay: true,
      color: isSkip ? SKIP_COLOR : SINGLE_COLOR,
    };
  });
}

/**
 * Creates an array of recurring events that will be rendered on the calendar
 * @param {Array} recurringPickups An array of recurring pickup requests
 * @return {Array} An array of FullCalendar Events
 */
export function createRecurringEvents(recurringPickups, skippedPickups) {
  const skippedDates = sortSkippedDates(skippedPickups);
  const recurringFcEvents = recurringPickups.map((pickup) => {
    const eventSkips = skippedDates[pickup.availablepickupdays_id]
      ? [...skippedDates[pickup.availablepickupdays_id]]
      : [];

    // Get the nearest recurring date
    const nearestDate = nearestRecurringDate(pickup).toISODate();
    return {
      id: pickup.availablepickupdays_id,
      title: t('schedule.recurringPickup'),
      color: RECURRING_COLOR,
      editable: false,
      rrule: {
        freq: pickup.frequency,
        interval: pickup.interval,
        dtstart: nearestDate,
        until: pickup.until,
      },
      exdate: eventSkips,
    };
  });
  return recurringFcEvents;
}

export function formatEvents(data) {
  const recurringEvents = createRecurringEvents(data.recurring, data.to_skip);
  const singleEvents = createSingleEvents(data.single, false);
  const skipEvents = createSingleEvents(data.to_skip, true);
  return { recurringEvents, singleEvents, skipEvents };
}
