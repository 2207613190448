import React from 'react';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Container, Hidden, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  video: {
    aspectRatio: '16 / 9',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));

const Video = ({ videoLink, title, text }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <iframe
            className={classes.video}
            src={videoLink}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Grid>
        <Hidden xsDown>
          <Grid item md={4}>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body2">{text}</Typography>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default Video;
