import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Box } from '@material-ui/core';

// components
import InfoCard from 'components/InfoCard/InfoCard';
import CustomList from 'components/CustomList/CustomList';
import SectionHeader from 'components/Header/SectionHeader';

// assets
import ecoCountOperator from 'assets/img/ecocount-operator.png';
import redemptionCenter from 'assets/img/redemption-center.jpg';
import dataGraphic from 'assets/img/data-graphic.png';
import ecoPod from 'assets/img/ecopod.png';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  card: {
    maxWidth: '540px',
    minWidth: '540px',
    minHeight: '120px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '440px',
      minWidth: '440px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  cardContent: {
    minHeight: '375px',
    [theme.breakpoints.down('md')]: {
      minHeight: '400px',
    },
  },
  cardImg: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '90%',
      height: 'auto',
      maxWidth: '300px',
      maxHeight: '200px',
      borderRadius: '14px',
    },
  },
  footer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& button:first-child': {
        marginBottom: theme.spacing(4),
      },
    },
  },
}));

const Solutions = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const solutions = [
    {
      header: t('home.solutions.one.header'),
      image: ecoCountOperator,
      description: t('home.solutions.one.description'),
      points: [
        t('home.solutions.one.points.p1'),
        t('home.solutions.one.points.p2'),
        t('home.solutions.one.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
        {
          text: t('home.buttons.signup'),
          link: '/signup',
        },
      ],
    },
    {
      header: t('home.solutions.two.header'),
      image: redemptionCenter,
      description: t('home.solutions.two.description'),
      points: [
        t('home.solutions.two.points.p1'),
        t('home.solutions.two.points.p2'),
        t('home.solutions.two.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
        {
          text: t('home.buttons.products'),
          link: '/products',
        },
      ],
    },
    {
      header: t('home.solutions.three.header'),
      image: dataGraphic,
      description: t('home.solutions.three.description'),
      points: [
        t('home.solutions.three.points.p1'),
        t('home.solutions.three.points.p2'),
        t('home.solutions.three.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
      ],
    },
    {
      header: t('home.solutions.four.header'),
      image: ecoPod,
      description: t('home.solutions.four.description'),
      points: [
        t('home.solutions.four.points.p1'),
        t('home.solutions.four.points.p2'),
        t('home.solutions.four.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
        {
          text: t('home.buttons.products'),
          link: '/products',
        },
      ],
    },
  ];

  const handleClick = (link) => {
    history.push(link);
  };

  return (
    <Container maxWidth="lg">
      <SectionHeader text="home.whatCanRecycletekDo" />
      <Box className={classes.cards}>
        {solutions.map((solution) => (
          <InfoCard heading={solution.header}>
            <Box className={classes.card}>
              <Box className={classes.cardContent}>
                <Typography>{solution.description}</Typography>
                <CustomList points={solution.points} />
                <Box className={classes.cardImg}>
                  <img src={solution.image} alt="Recycletek graphic" />
                </Box>
              </Box>
              <Box className={classes.footer}>
                {solution.buttons.map((button) => (
                  <Button onClick={() => handleClick(button.link)}>
                    {button.text}
                  </Button>
                ))}
              </Box>
            </Box>
          </InfoCard>
        ))}
      </Box>
    </Container>
  );
};

export default Solutions;
