import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, IconButton } from '@material-ui/core';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';

// Components
import CartCard from './CartCard';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: '#EFF9D7',
    },
  },
}));

function ShoppingCartPopOver(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cartOpen = Boolean(anchorEl);
  const id = cartOpen ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton
        className={classes.button}
        edge="end"
        aria-label="delete"
        size="medium"
        onClick={handleClick}
      >
        <LocalMallOutlinedIcon color="primary" />
      </IconButton>
      <Popover
        id={id}
        open={cartOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CartCard handleCheckout={props.handleCheckout} />
      </Popover>
    </div>
  );
}

export default ShoppingCartPopOver;
