import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, Interval } from 'luxon';
import { RRule, RRuleSet } from 'rrule';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Avatar } from '@material-ui/core';

import { getCurrentDate } from 'utils/dateFunctions';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getUserPickups } from 'redux/actions/pickups';
import {
  createSkipRequest,
  deletePickup,
  confirmPickup,
} from 'redux/actions/pickups';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(3),
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  icon: {
    width: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
    },
  },
  circle: {
    backgroundColor: (props) => props.background,
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  info: {
    fontSize: theme.typography.pxToRem(21),
    lineHeight: 1.25,
    fontWeight: 400,
    color: theme.palette.tertiary.main,
  },
  confirmButton: {
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 10px',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 10px',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  confirmedText: {
    fontWeight: 300,
  },
}));

function LatestPickupInfo(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { nearest, confirmations } = useSelector(
    (state) => state.pickups,
    shallowEqual,
  );
  const [formatedDate, setFormatedDate] = React.useState('');
  const [difference, setDifference] = React.useState('');
  const [confirm, setConfirm] = React.useState(false);
  const [needsConfirmed, setNeedsConfirmed] = React.useState(false);

  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (confirmations[nearest.id]) {
      setNeedsConfirmed(true);
      setConfirm(confirmations[nearest.id]);
    }
    const now = getCurrentDate();
    const nearestLuxon = DateTime.fromISO(nearest.date);
    if (Object.keys(nearest).length !== 0) {
      const diff = nearestLuxon.diff(now, ['days']);
      if (diff.values.days === 0) {
        setDifference(t('dashboard.today'));
      } else if (diff.values.days === 1) {
        setDifference(t('dashboard.inOneDay', { value: diff.values.days }));
      } else {
        setDifference(t('dashboard.inManyDays', { value: diff.values.days }));
      }

      setFormatedDate(
        nearestLuxon.toLocaleString({
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      );
    }
  }, [nearest, confirmations]);

  const handleDelete = (e) => {
    setDisabled(true);
    if (!confirm.availablepickupdays_id) {
      dispatch(deletePickup(confirm.date, 'single_pickup'));
      setDisabled(false);
    } else {
      const skipDetails = {
        date: confirm.date,
        pickuprequestsrecurring_id: confirm.availablepickupdays_id,
      };
      dispatch(createSkipRequest(skipDetails));
      setDisabled(false);
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setDisabled(true);
    const confirmation_id = e.target.value;
    dispatch(confirmPickup({ confirmation_id }));
    setDisabled(false);
  };

  return (
    <div className={classes.root}>
      <Box>
        <Avatar
          className={classes.iconAvatar}
          classes={{ colorDefault: classes.circle }}
        >
          <img className={classes.icon} src={props.icon} />
        </Avatar>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <Typography className={classes.title}>
          {t('dashboard.nextPickupIs')} {difference}
        </Typography>
        <Typography className={classes.info}>{formatedDate}</Typography>
        {needsConfirmed ? (
          <React.Fragment>
            {confirm.confirmed === '0' ? (
              <Box className={classes.buttonBox}>
                <button
                  className={classes.cancelButton}
                  onClick={handleDelete}
                  disabled={disabled}
                >
                  {t('dashboard.cancel')}
                </button>
                <button
                  value={confirm.id}
                  className={classes.confirmButton}
                  onClick={handleConfirm}
                  disabled={disabled}
                >
                  {t('dashboard.confirm')}
                </button>
              </Box>
            ) : (
              <Box>
                <Typography className={classes.confirmedText}>
                  {t('dashboard.pickupIsConfirmed')}
                </Typography>
              </Box>
            )}
          </React.Fragment>
        ) : null}
      </Box>
    </div>
  );
}

export default LatestPickupInfo;
