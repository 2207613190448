import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Redirect, NavLink } from 'react-router-dom';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getShippingOptions, getOrderTotal } from 'redux/actions/shop';
import { getTransactionHistory } from 'redux/actions/transactions';
import { ALERT_FAIL, SET_SHIPPING_OPTIONS } from 'redux/actions/types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  ListItemAvatar,
  Box,
  Grid,
  List,
  ListItemText,
  Typography,
  ListItem,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import theme from 'themes/memberPortalTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Material UI Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Components
import MemberPortalCard from 'components/Card/MemberPortalCard';
import CheckoutShipping from './Sections/CheckoutShipping';
import ShippingOptions from './Sections/ShippingOptions';
import CheckoutPayments from './Sections/CheckoutPayments';
import CustomStepper from 'components/Stepper/CustomStepper';
import BasicCard from 'components/Card/BasicCard';

// utils
import { formatMoney } from 'utils/formatTransactions';
import { SERVER_URL } from 'config';
import ThankYou from './Sections/Thankyou';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    maxWidth: '1400px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 100px',
      maxWidth: '1500px',
    },
  },
  checkoutDetails: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  orderDetails: {
    marginBottom: theme.spacing(3),
  },

  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  detail: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  link: {
    display: 'inline-flex',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.tertiary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  shoppingLink: {
    marginLeft: theme.spacing(1),
  },
  step: {
    width: '100%',
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  card: {
    padding: '12px 12px 12px 12px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    },
  },
  stepContainer: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  orderSummary: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
  spinnerText: {
    color: theme.palette.primary.main,
  },
  spinner: {
    height: '100vh',
  },
  button: {
    border: 'none',
    background: 'inherit',
    color: theme.palette.tertiary.main,
    marginLeft: 'auto',
    display: 'block',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

function Checkout(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { cart, totalPrice } = useSelector((state) => state.cart);
  const { checkoutOptions } = useSelector((state) => state.store);
  const customer = useSelector((state) => state.auth.customer, shallowEqual);
  const [redirect, setRedirect] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [orderList, setOrderList] = React.useState([]);
  const [shipInfo, setShipInfo] = React.useState({});
  const [complete, setComplete] = React.useState(false);
  const [orderNumber, setOrderNumber] = React.useState('');
  const steps = [t('ecosupplies.shipping'), t('ecosupplies.payment')];

  // changes what step of signup user is on
  const handleChangeActiveStep = () => {
    setActiveStep((prev) => prev + 1);
  };
  // changes what step of signup user is on
  const handleBack = () => {
    setShipInfo({});
    setActiveStep((prev) => prev - 1);
  };

  const handleComplete = () => {
    // get the order number
    dispatch(getTransactionHistory()).then((res) => {
      const number = res.data[0].note.replace(/\D/g, '');
      setOrderNumber(number);
      setComplete(true);
    });
  };

  // Submit shipping options and get tax and total
  const handleSelectShipping = async (event, value, type, shipID) => {
    event.preventDefault();
    if (value === '') {
      dispatch({
        type: ALERT_FAIL,
        payload: t('ecosupplies.pleaseSelectShippingOption'),
      });
    } else {
      const ship_id = {};
      if (type === 'shipment_id') {
        ship_id.shipment_id = shipID;
        ship_id.rate_id = value;
      } else {
        ship_id[type] = value;
      }

      setShipInfo({ ...ship_id });
      // get order tax, order total
      dispatch(getOrderTotal({ order_list: [...orderList], ...ship_id }))
        .then((res) => {
          const options = res.data;
          dispatch({
            type: SET_SHIPPING_OPTIONS,
            payload: { options },
          });
          handleChangeActiveStep();
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: ALERT_FAIL,
            payload: t('ecosupplies.errorProcessing'),
          });
        });
    }
  };

  const handleRedirect = () => {
    setRedirect(true);
  };

  React.useEffect(() => {
    if (cart.length === 0) {
      return;
    }
    const order_list = cart.map((product) => ({
      product_id: product.id,
      count: product.amount,
    }));

    setOrderList([...order_list]);

    const handleGetShippingOptions = async () => {
      setIsLoading(true);
      await dispatch(getShippingOptions(order_list));
      setIsLoading(false);
    };
    handleGetShippingOptions();
  }, []);

  React.useEffect(() => {
    if (cart.length === 0) {
      dispatch({
        type: ALERT_FAIL,
        payload: t('ecosupplies.pleasePlaceItemInCart'),
      });
      setRedirect(true);
    }
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicCard background={theme.palette.background.default}>
            <div className={classes.card}>
              <Typography variant="h4" className={classes.cardTitle}>
                {t('ecosupplies.shippingDetails')}
              </Typography>
              <List>
                <CheckoutShipping handleRedirect={handleRedirect} />
              </List>
              {!isLoading ? (
                <List>
                  <ShippingOptions
                    handleRedirect={handleRedirect}
                    handleSelectShipping={handleSelectShipping}
                  />
                </List>
              ) : null}
            </div>
          </BasicCard>
        );
      case 1:
        return (
          <BasicCard>
            <div className={classes.card}>
              <Typography variant="h4" className={classes.cardTitle}>
                {t('ecosupplies.paymentOptions')}
              </Typography>

              <List>
                <CheckoutPayments
                  handleRedirect={handleRedirect}
                  orderList={orderList}
                  shipInfo={shipInfo}
                  handleComplete={handleComplete}
                />
              </List>
            </div>
          </BasicCard>
        );
      default:
        return (
          <div className={classes.orderDetails}>
            <BasicCard>
              <List>
                <CheckoutShipping handleRedirect={handleRedirect} />
              </List>
              <List>
                <ShippingOptions
                  handleRedirect={handleRedirect}
                  handleSelectShipping={handleSelectShipping}
                />
              </List>
            </BasicCard>
          </div>
        );
    }
  };

  if (redirect) {
    return <Redirect push to="/portal/ecosupplies" />;
  } else if (isLoading) {
    return (
      <Box
        pt={8}
        className={classes.spinner}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
        <Typography align="center" className={classes.spinnerText}>
          <Trans>{t('ecosupplies.loadingShippingOptions')}</Trans>
        </Typography>
      </Box>
    );
  } else if (complete) {
    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography className={classes.text}>
                <NavLink
                  exact
                  to="/portal/ecosupplies"
                  className={classes.link}
                >
                  <ArrowBackIcon />
                  <span className={classes.shoppingLink}>
                    {t('ecosupplies.returnToEcoSupplies')}
                  </span>
                </NavLink>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <ThankYou orderNumber={orderNumber} />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Box>
            <Typography className={classes.text}>
              <NavLink exact to="/portal/ecosupplies" className={classes.link}>
                <ArrowBackIcon />
                <span className={classes.shoppingLink}>
                  {t('ecosupplies.continueShopping')}
                </span>
              </NavLink>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            className={classes.step}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {/* <Stepper activeStep={activeStep} steps={steps} /> */}
            <CustomStepper activeStep={activeStep} steps={steps} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} className={classes.stepContainer}>
          {getStepContent(activeStep)}
        </Grid>
        <Grid item xs={12} md={5} className={classes.orderSummary}>
          <MemberPortalCard
            open={matches}
            background={`${theme.palette.primary.main}`}
            color={`${theme.palette.primary.contrastText}`}
            iconColor={`${theme.palette.primary.contrastText}`}
            title={t('ecosupplies.orderSummary')}
          >
            <React.Fragment>
              <List>
                {cart.length &&
                  cart.map((product, index) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar src={`${SERVER_URL}${product.img_uri}`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div className={classes.name}>
                            {product.name} -{' '}
                            {formatMoney(
                              product.price_microdollars * product.amount,
                            )}
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
              </List>

              <Divider />
              <Box my={1}>
                <Typography variant="subtitle2">
                  {t('ecosupplies.subtotal')} {formatMoney(totalPrice)}
                </Typography>
                <Typography variant="subtitle2">
                  {activeStep === 1 &&
                    `${t('ecosupplies.deliveryCharge')} ${formatMoney(
                      checkoutOptions.positions.shipping.amount_dollars *
                        1_000_000,
                    )}`}
                </Typography>
                <Typography variant="subtitle2">
                  {activeStep === 1 &&
                    `${t('ecosupplies.tax')} ${formatMoney(
                      checkoutOptions.positions.tax.amount_dollars * 1_000_000,
                    )}`}
                </Typography>
                <Divider />
                {activeStep === 0 ? (
                  <Typography variant="subtitle2">
                    {t('ecosupplies.totalToBeCalculatedNext')}
                  </Typography>
                ) : null}
                <Box pt={2}>
                  <Typography variant="h6">
                    {activeStep === 1 &&
                      `${t('ecosupplies.total')} ${formatMoney(
                        checkoutOptions.positions.total.amount_dollars *
                          1_000_000,
                      )}`}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          </MemberPortalCard>
          {activeStep === 1 ? (
            <Box my={2}>
              <MemberPortalCard
                open={matches}
                background={`${theme.palette.primary.main}`}
                color={`${theme.palette.primary.contrastText}`}
                iconColor={`${theme.palette.primary.contrastText}`}
                title={t('ecosupplies.shipmentSummary')}
              >
                <React.Fragment>
                  <Box mb={2}>
                    <Typography>{t('ecosupplies.shippingAddress')}</Typography>
                    <Box pt={2} pl={2}>
                      <Typography>{customer.street}</Typography>
                      <Typography>{customer.housenumber}</Typography>
                      <Typography>
                        {customer.apt && `Apt #: ${customer.apt}`}
                      </Typography>
                      <Typography>
                        {customer.city && `${customer.city}, ${customer.state}`}
                      </Typography>
                      <Typography>{customer.zipcode}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography>{t('ecosupplies.shipmentType')}</Typography>
                    <Box pt={2} pl={2}>
                      <Typography>
                        {checkoutOptions.positions.shipping &&
                          checkoutOptions.positions.shipping.description}
                      </Typography>
                      <button onClick={handleBack} className={classes.button}>
                        {t('ecosupplies.updateShipmentType')}
                      </button>
                    </Box>
                  </Box>
                </React.Fragment>
              </MemberPortalCard>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

export default Checkout;
