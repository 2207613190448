import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { deletePickup } from 'redux/actions/pickups';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Button,
  Collapse,
  ListItemText,
} from '@material-ui/core';

// Date
import { DateTime } from 'luxon';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pickup: {
    color: theme.palette.primary.main,
  },
  title: {
    color: (props) =>
      props.pickup.bulk
        ? theme.palette.tertiary.main
        : theme.palette.secondary.main,
    paddingRight: theme.spacing(6),
    fontWeight: 300,
  },
  info: {
    fontWeight: 300,
  },
  editButton: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
}));

function PickupListItem(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { pickup } = props;
  const paymentOptions = useSelector(
    (state) => state.transactions.paymentOptions,
    shallowEqual,
  );
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState('');
  const [paymentType, setPaymentType] = React.useState({});

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen(!open);
  };

  const handleDelete = (e) => {
    dispatch(deletePickup(pickup.date, 'single_pickup'));
  };

  React.useEffect(() => {
    // Format the date to be more readable "Thur, 4/19"
    const readableDate = DateTime.fromISO(props.pickup.date).toLocaleString({
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
    });
    setDate(readableDate);

    // Find the payment info for that payment id
    const payment = paymentOptions.filter(
      (option) => option.id === props.pickup.paymentoptions_id,
    );
    setPaymentType(...payment);
  }, [props.pickup]);

  return (
    <div data-cy="scheduled-pickup-item">
      <ListItem
        classes={{ root: classes.listRoot }}
        button
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography>{date}</Typography>
          <Typography className={classes.title}>
            {pickup.bulk
              ? t('schedule.bulkPickup')
              : t('schedule.curbsidePickup')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box pt={1} pl={3} display="flex">
          <Typography>{t('schedule.ecoSacks')}</Typography>
          <Box pl={3}>
            <Typography className={classes.info}>
              {pickup.num_eco_sacks}
            </Typography>
          </Box>
        </Box>
        <Box pl={3} display="flex">
          <Typography>{t('schedule.ecoKrates')}</Typography>
          <Box pl={3}>
            <Typography className={classes.info}>
              {pickup.num_eco_krates}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Typography>{t('schedule.paymentMethod')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {paymentType.name !== 'Cash'
                ? `${t('schedule.donation')} ${paymentType.name}`
                : t('schedule.addToAccountBalance')}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Typography>{t('schedule.instructions')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {pickup.instructions === ''
                ? t('common.notApplicable')
                : `${pickup.instructions}`}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" py={1} pr={3}>
          <Button color="primary" onClick={handleDelete}>
            {t('buttons.delete')}
          </Button>
        </Box>
      </Collapse>
      <Divider />
    </div>
  );
}

export default PickupListItem;
