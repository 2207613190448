import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// @material-ui/core components
import { Toolbar, Box, Paper, Typography, Grid } from '@material-ui/core';

// assets
import verifiedIcon from 'assets/img/portal/verified.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1000px',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  root: {
    maxWidth: '1280px',
    borderRadius: 20,
    padding: theme.spacing(6),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  image: {
    maxHeight: '80px',
    width: 'auto',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxHeight: '40px',
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
  },
  thin: {
    fontWeight: 300,
  },
  number: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  hyperLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    marginBottom: '1rem',
    width: '50px',
  },
}));

const ThankYou = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Toolbar />
      <div className={classes.container}>
        <Paper className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.card}>
                <img className={classes.icon} src={verifiedIcon} />
                <Box mb={2}>
                  <Typography className={classes.cardText}>
                    Order number: {props.orderNumber}
                  </Typography>
                </Box>
                <Typography
                  variant="h1"
                  color="primary"
                  className={classes.number}
                >
                  Thank you!
                </Typography>
                <Typography color="primary" className={classes.title}>
                  Your{' '}
                  <span className={classes.thin} color="primary">
                    Eco
                  </span>
                  Supplies order has been completed.
                </Typography>
                <Typography color="primary" className={classes.title}>
                  Please check your email for an order confirmation.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default ThankYou;
