import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// styles
const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: 0,
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    [theme.breakpoints.up(400)]: {
      minWidth: 350,
    },
  },
}));

function PasswordForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShow((prev) => !prev);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirm((prev) => !prev);
  };

  const handleCancel = () => {
    formik.handleReset();
    props.handleClose('password');
  };

  const { password, newPassword, confirmPassword } = props.validation;

  const validationSchema = yup.object({
    newPassword,
    confirmPassword,
  });

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
    },
    // validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      const { new_password } = values;
      dispatch(updateCustomer({ password: new_password }));
      actions.resetForm();
      props.handleClose('password');
    },
  });

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t('account.form.changePassword')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Grid container spacing={3}>
              {/* <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  <PortalInputLabel
                    classes={{ root: classes.label }}
                    id="password"
                  >
                    Current Password
                  </PortalInputLabel>
                  <PortalTextFieldNoShadow
                    fullWidth
                    type="password"
                    id="password"
                    name="current_password"
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    endAdornment={
                      <InputAdornment
                        className={classes.adornment}
                        position="end"
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid> */}
              <Grid item container xs={12} spacing={3}>
                <Grid item xs={12} sm={6}>
                  <PortalInputLabel
                    classes={{ root: classes.label }}
                    id="password"
                  >
                    {t('account.form.newPassword')}
                  </PortalInputLabel>
                  <PortalTextFieldNoShadow
                    fullWidth
                    type={!show ? 'password' : 'text'}
                    id="password"
                    name="new_password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    endAdornment={
                      <InputAdornment
                        className={classes.adornment}
                        position="end"
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PortalInputLabel
                    classes={{ root: classes.label }}
                    id="confirmPassword"
                  >
                    {t('account.form.confirmPassword')}
                  </PortalInputLabel>
                  <PortalTextFieldNoShadow
                    fullWidth
                    type={!showConfirm ? 'password' : 'text'}
                    id="confirmPassword"
                    name="confirm_password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    endAdornment={
                      <InputAdornment
                        className={classes.adornment}
                        position="end"
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirm ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} className={classes.button}>
              {t('buttons.cancel')}
            </Button>
            <Button className={classes.button} type="submit">
              {t('buttons.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default PasswordForm;
