import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';

// styles
const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: 0,
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    [theme.breakpoints.up(400)]: {
      minWidth: 350,
    },
  },
}));

function BusinessName(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.auth.customer);

  const handleCancel = () => {
    formik.handleReset();
    props.handleClose('business');
  };

  const { business_name } = props.validation;

  const validationSchema = yup.object({
    business_name,
  });

  const formik = useFormik({
    initialValues: {
      businessName: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(updateCustomer(values));
      actions.resetForm();
      props.handleClose('business');
    },
  });

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t('account.form.changeBusinessName')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PortalInputLabel
                  classes={{ root: classes.label }}
                  id="business_name"
                ></PortalInputLabel>
                <PortalTextFieldNoShadow
                  fullWidth
                  type="text"
                  id="business_name"
                  name="business_name"
                  placeholder={customer.business_name}
                  value={formik.values.business_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.business_name &&
                    Boolean(formik.errors.business_name)
                  }
                  helperText={
                    formik.touched.business_name && formik.errors.business_name
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} className={classes.button}>
              {t('buttons.cancel')}
            </Button>
            <Button className={classes.button} type="submit">
              {t('buttons.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default BusinessName;
