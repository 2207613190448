import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    paddingRight: theme.spacing(6),
    fontWeight: 300,
  },
  info: {
    fontWeight: 300,
  },
  subscript: {
    verticalAlign: 'sub',
  },
  plastic: {
    color: theme.palette.aqua.main,
  },
  glass: {
    color: theme.palette.secondary.main,
  },
}));

export default function MetricsByPickupInfo({
  type,
  material,
  containerCount,
  emissionsAvoided,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const materialType = clsx({
    [classes.glass]: type === 'glass',
    [classes.plastic]: type === 'plastic',
  });

  return (
    <Box pt={1} pl={3} display="flex" flexDirection="column">
      <Typography className={materialType}>{material}</Typography>
      <Box pl={2}>
        <Typography className={classes.info}>
          {t('metrics.containers')} {containerCount}
        </Typography>
        <Typography className={classes.info}>
          CO<span className={classes.subscript}>2</span>{' '}
          {t('metrics.emissionsAvoidedColon')} {emissionsAvoided} Kg
        </Typography>
      </Box>
    </Box>
  );
}
