import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { SERVER_URL } from 'config.js';
import HubspotForm from 'react-hubspot-form'

import { useDispatch } from 'react-redux';
import { ALERT_FAIL, ALERT_SUCCESS } from 'redux/actions/types';

// API
import Customer from 'api/CustomerService';

import ContactForm from 'components/ContactForm/ContactForm';

// components
import { Parallax } from 'react-parallax';

// @material-ui/core components
import {
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  TextField,
  Button,
} from '@material-ui/core';

// assets
import plasticBottles from 'assets/img/plastic-bottles.jpg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  card: {
    display: 'flex',
    padding: '1rem 1rem',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
    border: '2px solid',
    borderColor: theme.palette.grey.main,
    maxWidth: 800,
    position: 'absolute',
    left: 'calc(50% - 400px)',
    zIndex: 200,
    [theme.breakpoints.down('sm')]: {
      left: '5%',
      width: '90%',
      position: 'relative',
    },
  },
  title: {
    fontSize: '36px',
    color: theme.palette.tertiary.main,
  },
  body: {
    marginBottom: '2rem',
    lineHeight: 1.5,
    fontSize: '18px',
    fontWeight: '400',
  },
  container_parallax: {
    width: '100vw',
    height: '500px',
    marginTop: '5rem',
    marginBottom: '10rem',
    [theme.breakpoints.down('xs')]: {
      height: '300px',
      margin: 0,
    },
  },
  divider: {
    width: '50%',
    margin: '1rem 0',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

function PartnerForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [selectedContactEmail, setSelectedContactEmail] = useState('');

  const clearForm = () => {
    setSelectedContactEmail('');
  };

  const options = [
    {
      title: 'contact.sales',
      text: 'sales@recycletek.co',
      value: 'sales@recycletek.co',
    },
    {
      title: 'contact.general',
      text: 'info@recycletek.co',
      value: 'info@recycletek.co',
    },
  ];

  const [values, setValues] = React.useState({
    email: '',
    listname: 'investor',
  });

  const handleChange = (event) => {
    const { value, name } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (values.email === '') {
      dispatch({
        type: ALERT_FAIL,
        payload: t('contact.form.emailIsRequired'),
      });
      return;
    }
    Customer.joinList(values)
      .then((res) => {
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('contact.form.successMsg'),
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: ALERT_FAIL,
            payload: t('contact.form.errorMsg'),
          });
        }
      });
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography variant="h5" className={classes.title}>
              Learn more about Investing in Recycletek
            </Typography>

            <Divider className={classes.divider} />

            {/*
            Download Further documents:<br />
            <Grid container style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}>
              <Grid xs={12} md={6}>
                <a href="#" className={classes.link}> <i class="fa fa-download"></i> Pitch-Deck</a> &nbsp;
              </Grid>
              <Grid xs={12} md={6}>
                <a href="#" className={classes.link}><i class="fa fa-download"></i> Executive Summary</a>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
  */}
            Get in touch with us to schedule a meeting or receive our Pitch-Deck and Executive Summary.<br /><br />
            {/*
            <ContactForm
              emailAddress={'info@recycletek.co'}
              options={options}
              clear={clearForm}
            />
          */}

            <HubspotForm
              portalId='43541926'
              formId='bb27fcc5-387c-42de-881f-29228fb8ae1d'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </CardContent>
        </Card>
        <div>
          <Parallax
            blur={0}
            strength={800}
            bgImage={plasticBottles}
            className={classes.container_parallax}
          ></Parallax>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PartnerForm;
