import {
  ALERT_SUCCESS,
  ALERT_FAIL,
  ALERT_WARNING,
  CLOSE_ALERT,
} from '../actions/types.js';

const initialState = {
  snackbarOpen: false,
  snackbarType: '',
  snackbarMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: action.payload,
      };
    case ALERT_FAIL:
      return {
        snackbarOpen: true,
        snackbarType: 'error',
        snackbarMessage: action.payload,
      };
    // not used yet
    case ALERT_WARNING:
      return {
        snackbarOpen: true,
        snackbarType: 'warning',
        snackbarMessage: action.payload,
      };
    case CLOSE_ALERT:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};
