// formats the google validation api response so it can be submitted to db

export const formatAddressComponents = (addressComponents) => {
  const address = {};
  const street = {};

  addressComponents.map((component) => {
    const componentText = component.componentName?.text;
    const type = component.componentType;

    if (type === 'street_number') {
      street.number = componentText;
    } else if (type === 'route') {
      street.name = componentText;
    } else if (type === 'locality') {
      address.city = componentText;
    } else if (type === 'administrative_area_level_1') {
      address.state = componentText;
    } else if (type === 'subpremise') {
      address.apt = componentText;
    } else if (type === 'postal_code') {
      address.zipcode = componentText;
    }
  });

  address.street = `${street.number} ${street.name}`;

  return address;
};
