import { SET_CHARITIES_LIST } from 'redux/actions/types';

const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHARITIES_LIST:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};
