import React from 'react';
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
    maxWidth: '500px',
  },
}));

function EcoSuppliesHeader(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box mt={3}>
      <Box mb={2}>
        <Typography variant="h3" color="primary">
          {t('common.eco')}
          <span className={classes.name}>{t('common.supplies')}</span>
        </Typography>
        <Typography className={classes.subtitle}>
          {t('ecosupplies.ecosuppliesDescription')}
        </Typography>
      </Box>
    </Box>
  );
}

export default EcoSuppliesHeader;
