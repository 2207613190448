import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ALERT_FAIL, SET_VERIFYING } from 'redux/actions/types.js';
import {
  verificationStart,
  verificationRequest,
  resetVerification,
} from 'redux/actions/verification';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Card, Button } from '@material-ui/core';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '300px',
    borderRadius: 15,
    margin: '0 auto',
    paddingBottom: theme.spacing(2),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  input: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem',
      marginBottom: theme.spacing(2),
    },
  },
  captchaContainer: {
    display: 'flex',
    height: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '200px',
    margin: '0 auto',
    padding: '1rem 1rem 1.5rem',
  },
  captchaImageContainer: {
    flex: 1,
  },
  captchaImage: {
    width: '100%',
    height: 'auto',
  },
  questionContainer: {
    padding: '1rem',
  },
  question: {
    color: theme.palette.tertiary.main,
    textAlign: 'center',
  },
}));

const validationSchema = yup.object({
  answer: yup.string().required('Can not be left blank'),
});

export default function CaptchaForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleUpdateCaptcha = (userInfo) => {
    const { email, phone_number } = userInfo;
    dispatch(resetVerification()).then(() => {
      dispatch({ type: SET_VERIFYING, payload: true });
      dispatch(verificationRequest({ email, phone_number }))
        .then((res) => {
          dispatch({ type: SET_VERIFYING, payload: false });
        })
        .catch((err) => {
          const msg = t('signup.form.errorCreating');
          dispatch({ type: SET_VERIFYING, payload: false });
          dispatch({
            type: ALERT_FAIL,
            payload:
              err.response.status === 400 ? `${err.response.data.msg}` : msg,
          });
        });
    });
  };

  // Captcha
  const { images, question } = useSelector((state) => state.verification);
  const formik = useFormik({
    initialValues: {
      answer: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      dispatch(verificationStart(values.answer)).catch((err) => {
        setSubmitting(false);
        dispatch({ type: SET_VERIFYING, payload: false });
        if (err.response.status === 403) {
          dispatch({
            type: ALERT_FAIL,
            payload: `${err.response.data.msg}, ${t(
              'signup.form.pleaseTryAgain',
            )}`,
          });
          handleUpdateCaptcha(props.userInfo);
        } else {
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.errorCreating'),
          });
          props.handleBack();
        }
      });
      resetForm({});
    },
  });

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Box className={classes.questionContainer}>
          <Typography className={classes.question}>{question}</Typography>
        </Box>
        <div className={classes.captchaContainer}>
          {images.length > 0 &&
            images.map((image, index) => (
              <div key={index} className={classes.captchaImageContainer}>
                <img
                  className={classes.captchaImage}
                  src={`data:image/png;base64, ${image}`}
                />
              </div>
            ))}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <PortalTextFieldNoShadow
                className={classes.input}
                fullWidth
                id="answer"
                name="answer"
                placeholder={t('signup.form.answer')}
                value={formik.values.answer}
                onChange={formik.handleChange}
                error={formik.touched.answer && Boolean(formik.errors.answer)}
                helperText={formik.touched.answer && formik.errors.answer}
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {t('buttons.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </React.Fragment>
  );
}
