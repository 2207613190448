import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// components
import ContainerIcons from './ContainerIcons';

// lib/data
import { content } from 'lib/content';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '800px',
    borderRadius: 20,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(21),
  },
  text: {
    textDecoration: 'underline',
  },
  type: {
    maxWidth: '400px',
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItemIconRoot: {
    minWidth: '30px',
  },
  listIcon: {
    color: '#ffffff',
    backgroundColor: theme.palette.tertiary.main,
  },
}));

function BeverageGuide(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const USState = useSelector((state) => state.customer?.location.stateCode);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          {t(content[USState]?.state)} {t('what.stateRulesTitle')}
        </Typography>
        <ContainerIcons />
        <Box mb={3} ml={2} className={classes.type}>
          <Typography className={classes.subtitle} color="primary" variant="h5">
            {t('what.acceptableBeverageTypes')}
          </Typography>
          <Box ml={2}>
            <Typography>
              <b>{t('what.generalRule')}</b> -{' '}
              {t(content[USState]?.acceptedContainers.generalRule)}
            </Typography>
          </Box>
          <List dense>
            {content[USState]?.acceptedContainers.list.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <CheckCircleRoundedIcon color="secondary" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={t(item)}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box mb={3} ml={2}>
          <Typography className={classes.subtitle} color="primary" variant="h5">
            {t('what.unacceptedBeverageTypes')}
          </Typography>
          <Box ml={2}>
            <Typography>
              <b>{t('what.generalRule')}</b> -{' '}
              {t(content[USState]?.unacceptedContainers.generalRule)}
            </Typography>
          </Box>
          <List dense>
            {content[USState]?.unacceptedContainers.list.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <HighlightOffIcon color="error" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={t(item)}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box ml={2}>
          <Typography className={classes.subtitle} color="primary" variant="h5">
            {t('what.otherUnacceptedBeverageTypes')}
          </Typography>
          <List dense>
            {content[USState]?.otherUnacceptedContainers.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <HighlightOffIcon color="error" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={t(item)}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BeverageGuide;
