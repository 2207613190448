import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// mui components
import { Stepper, Step, StepLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  step: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    '& $completed': {
      color: theme.palette.secondary.main,
    },
    '& $active': {
      color: theme.palette.tertiary.main,
    },
  },
  // needed so that the &$active tag works
  active: {},
  completed: {},
  alternativeLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  labelContainer: {
    maxWidth: '90px',
  },
}));

export default function CustomStepper(props) {
  const classes = useStyles();

  return (
    <Stepper
      className={classes.stepper}
      activeStep={props.activeStep}
      alternativeLabel
    >
      {props.steps.map((label) => (
        <Step
          classes={{
            root: classes.step,
            completed: classes.completed,
            alternativeLabel: classes.active,
          }}
          key={label}
        >
          <StepLabel
            classes={{
              alternativeLabel: classes.alternativeLabel,
              labelContainer: classes.labelContainer,
            }}
            StepIconProps={{
              classes: {
                root: classes.step,
                completed: classes.completed,
                active: classes.active,
                disabled: classes.disabled,
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

CustomStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};
