import { DateTime } from 'luxon';
import {
  SET_CART,
  DELETE_CART,
  SET_STORE,
  SET_SHIPPING_OPTIONS,
  UPDATE_SHIPPING_OPTIONS,
} from 'redux/actions/types';

const shopState = {
  products: [],
  checkoutOptions: {},
};

export function store(state = shopState, action) {
  switch (action.type) {
    case SET_STORE:
      return {
        ...state,
        products: [...action.payload.products],
      };
    case SET_SHIPPING_OPTIONS:
      return {
        ...state,
        checkoutOptions: {
          ...state.checkoutOptions,
          ...action.payload.options,
        },
      };
    default:
      return state;
  }
}

const cartState = {
  cart: [],
  totalItems: 0,
  totalPrice: 0,
};

export const cart = (state = cartState, action) => {
  switch (action.type) {
    case SET_CART:
      return {
        ...state,
        cart: [...action.payload.cart],
        totalPrice: action.payload.totalPrice,
        totalItems: action.payload.totalItems,
      };
    case DELETE_CART:
      return {
        ...cartState,
      };
    default:
      return state;
  }
};
