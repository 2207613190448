import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/core components
import {
  CardContent,
  Card,
  CardMedia,
  Typography,
  Container
} from '@material-ui/core'

// assets
import evtekProcess from 'assets/img/tech/recycletek-process-icons.png';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginBottom: '2rem',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  },
  header: {
    height: 'auto',
    width: '100%',
  },
  headerTitle: {
    fontSize: '4rem',
    fontWeight: '300',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  boldTitle: {
    fontWeight: 600,
  },
}));

const TitleSection = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.titleContainer}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <CardMedia
              component="img"
              className={classes.header}
              image={evtekProcess}
            ></CardMedia>
          </CardContent>
        </Card>
        <Typography color="primary" className={classes.headerTitle}>
          {t('tech.modernDayRecyclingTitle')}
        </Typography>
      </Container>
    </React.Fragment>
  );
}

export default TitleSection
