import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Box, Hidden } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import ResetPasswordForm from './Forms/ResetPasswordForm';

// assets
import logo from 'assets/img/portal/logo_blue_croped3.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    minHeight: 'calc(100vh - 80px)',

    backgroundColor: theme.palette.background.portal,
    '& > *': {
      zIndex: 100,
    },

    '&:before': {
      content: `''`,
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundPosition: 'right top',
      backgroundImage: `url(${logo})`,
      backgroundSize: '500px',
      opacity: '.125',
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 70px)',
    },
    [theme.breakpoints.down('xs')]: {
      '&:before': {
        backgroundSize: '80%',
      },
    },
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    width: '500px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundSize: '90%',
  },
  actionText: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.125rem',
    marginBottom: '.5rem',
  },
  inputBox: {
    padding: theme.spacing(12),
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(3),
    },
  },
  actionButton: {
    width: '9rem',
    '&:focus': {
      color: 'white',
    },
  },
}));

export default function LoginSignUp(props) {
  const classes = useStyles();

  return (
    <Page>
      <Grid container component="main" className={classes.root}>
        {/* Column 1 */}
        <Grid item component="div" className={classes.inputBox}>
          <ResetPasswordForm />
        </Grid>
      </Grid>
    </Page>
  );
}
