import theme from 'themes/memberPortalTheme';

const faqStyle = {
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  titleItem: {
    color: theme.palette.primary.main,
    padding: '.5rem 0',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    paddingRight: theme.spacing(3),
  },
  info: {
    fontWeight: 300,
  },
  bold: {
    fontWeight: 700,
  },
  link: {
    fontWeight: 400,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.dark,
    '&:hover,&:focus': {
      color: theme.palette.primary.main,
      background: 'inherit',
    },
  },
  image: {
    width: '100%',
    maxWidth: '200px',
    height: 'auto',
    padding: theme.spacing(0, 1),
  },
};

export default faqStyle;
