import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  FormControl,
} from '@material-ui/core';

import { createCustomRecurringPickUpRequest } from 'redux/actions/pickups';
import { ALERT_FAIL, ALERT_SUCCESS } from 'redux/actions/types';

// components
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';

// Lib
import { weekDays, frequency, containerEstimate } from 'lib/formValues';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
  },
  title: {
    paddingBottom: '0',
    fontWeight: 300,
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  subtitle: {
    marginLeft: '5px',
    fontSize: theme.typography.pxToRem(12),
  },
  infoText: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'justify',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  addButton: {
    border: 'none',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.text.primary,
    padding: '2px 10px',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  bold: {
    fontWeight: 700,
  },
}));

const BusinessPickupForm = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const boxShadow = 'none';

  const { open, handleClose } = props;
  const [disabled, setDisabled] = React.useState(false);
  const [dayValue, setDayValue] = React.useState([]);
  const [recurring, setRecurring] = React.useState([]);
  const [containers, setContainers] = React.useState(null);
  const [additional_info, setAdditional_info] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const values = {
      pickupdays: dayValue,
      frequency: recurring,
      number_of_containers: containers,
      additional_info: additional_info,
    };
    dispatch(createCustomRecurringPickUpRequest(values))
      .then((res) => {
        setDayValue([]);
        setRecurring([]);
        setAdditional_info('');
        setContainers(null);
        setDisabled(false);
        handleClose();
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('schedule.businessForm.successMsg'),
        });
      })
      .catch((error) => {
        dispatch({
          type: ALERT_FAIL,
          payload: t('schedule.businessForm.errorMsg'),
        });
      });
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form>
        <DialogContent>
          <Box>
            <Typography className={classes.title} color="primary" variant="h4">
              {t('schedule.businessForm.contactAnEco')}
              <span className={classes.bold}>
                {t('schedule.businessForm.specialist')}
              </span>
            </Typography>
          </Box>
          <Box mt={3}>
            <DialogContentText className={classes.infoText}>
              {t('schedule.businessForm.pleaseAnswerAFewQuestions')}
            </DialogContentText>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="primary">
                {t('schedule.businessForm.whatDaysDoYouPreferPickups')}
              </Typography>
              <CustomAutoComplete
                multiple={true}
                boxShadow={boxShadow}
                placeholder={t('schedule.businessForm.dayOfWeek')}
                disabled={false}
                id="weekDay"
                list={weekDays}
                value={dayValue}
                onChange={(event, newValue) => {
                  setDayValue(newValue);
                }}
                style={{ maxWidth: '100%' }}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <TextField {...params.inputProps} />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {t('schedule.businessForm.howOftenWillNeedPickups')}
              </Typography>
              <CustomAutoComplete
                multiple={true}
                boxShadow={boxShadow}
                placeholder={t('schedule.businessForm.frequency')}
                disabled={false}
                id="frequency"
                list={frequency}
                value={recurring}
                onChange={(event, newValue) => {
                  setRecurring(newValue);
                }}
                style={{ maxWidth: '100%' }}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <TextField {...params.inputProps} />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {t('schedule.businessForm.estimateNumberOfContainers')}
              </Typography>
              <CustomAutoComplete
                boxShadow={boxShadow}
                placeholder="< 1500"
                disabled={false}
                id="containers"
                list={containerEstimate}
                value={containers}
                onChange={(event, newValue) => {
                  setContainers(newValue);
                }}
                style={{ maxWidth: 150 }}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <TextField {...params.inputProps} />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {t('schedule.businessForm.additionalInformation')}
              </Typography>
              <FormControl fullWidth>
                <PortalTextFieldNoShadow
                  name="message"
                  placeholder={t(
                    'schedule.businessForm.needEveningPickupTimes',
                  )}
                  multiline
                  rows={3}
                  value={additional_info}
                  onChange={(e) => setAdditional_info(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color="primary"
            variant="contained"
            onClick={handleClose}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            classes={{ root: classes.button }}
            disabled={disabled}
            color="secondary"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
          >
            {t('buttons.confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BusinessPickupForm;

BusinessPickupForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
