import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function ProgressSpinner(props) {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <CircularProgress color="secondary" />
      <Typography>{props.text}</Typography>
    </Box>
  );
}

export default ProgressSpinner;
