import React from 'react';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector } from 'react-redux';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

// assets

// utils
import AccountInfoIcon from '../Sections/AccountInfoIcon';
import CustomMoneyInput from 'components/CustomNumberFormat/CustomMoneyInput';
import PortalTextField from 'components/TextField/PortalTextField';
import { formatMoney } from 'utils/formatTransactions';

const useStyles = makeStyles((theme) => ({
  root: { paddingBottom: ' 20px' },
  button: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  bold: {
    fontWeight: 400,
  },
  balance: {
    marginTop: '2rem',
  },
  form: {
    margin: '2rem 0 3rem',
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(18),
  },
  inputHeight: {
    height: '50px',
  },
  inputSublabel: {
    marginTop: '1rem',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
  },
  inputSublabelBold: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    fontWeight: '700',
  },
  account: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
  },
  updateLink: {
    display: 'block',
    textAlign: 'center',
    marginTop: '.5rem',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover,&:focus': {
      color: theme.palette.primary.main,
      background: 'inherit',
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  confirmButton: {
    order: 1,
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
}));

function PayPalTransferForm(props) {
  const classes = useStyles();
  const { account_balance } = useSelector((state) => state.transactions);

  const validationSchema = yup.object({
    amount: yup
      .number('Please enter a number')
      .required('Please enter an amount')
      .test(
        'test-minimum-transfer',
        'Transfer amount must be above $0.25',
        (value) => {
          if (value <= 0.25) {
            return false;
          }
          return true;
        },
      )
      .test(
        'transfer-amount',
        'Transfer amount cannot exceed your balance',
        (value) => {
          if (value > account_balance / 1000000) {
            return false;
          }
          return true;
        },
      ),
  });

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      props.handleAmount(values.amount);
      actions.resetForm();
      props.handleChangeActiveStep();
    },
  });

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        align-items="center"
        className={classes.balance}
      >
        <AccountInfoIcon />
        <Box
          display="flex"
          justifyContent="center"
          items="center"
          ml={2}
          flexDirection="column"
        >
          <Typography className={classes.accountTitle}>
            Account Balance:
          </Typography>
          <Typography className={classes.accountInfo}>
            {formatMoney(account_balance)}
          </Typography>
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className={classes.form}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography className={classes.inputLabel} color="primary">
            Transfer Amount
          </Typography>
          <PortalTextField
            inputProps={{
              min: 0,
              style: { textAlign: 'center', height: '30px' },
            }}
            id="amount"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            inputComponent={CustomMoneyInput}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
          <Box mt={4}>
            <Typography className={classes.inputSublabelBold}>
              Transfer up to $1,000 per month
            </Typography>
          </Box>
        </Box>
        <Box className={classes.action}>
          <Button
            className={classes.confirmButton}
            classes={{ root: classes.button }}
            disabled={formik.isSubmitting}
            color="secondary"
            variant="contained"
            type="submit"
          >
            Confirm Amount
          </Button>
        </Box>
      </form>
    </div>
  );
}

export default PayPalTransferForm;
