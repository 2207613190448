// TODO: New name for this reducer. Customer field exists in auth reducer

import {
  CUSTOMER_PENDING,
  CUSTOMER_SMS_SENT,
  CUSTOMER_CODE_VERIFIED,
  CHECKING_CUSTOMER_CODE,
  CUSTOMER_2FA_EMAIL_SENT,
  SET_CUSTOMER_STATECODE,
  SET_CUSTOMER_COORDINATES,
  SET_CUSTOMER_SERVICES,
  SET_CUSTOMER_STATECOORDS,
  SET_SUPPORTED_LANGUAGES,
  RESET_CUSTOMER,
  SET_ACHIEVEMENTS,
  SET_ASSOCIATED_REDEMPTION_CENTER,
} from '../actions/types.js';

const initialState = {
  pending: false,
  location: {
    stateCode: '',
    coords: {
      lat: '',
      lon: '',
    },
    services: {},
    stateCoords: {},
  },
  achievements: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_PENDING:
      return {
        ...state,
        pending: action.payload,
      };
    case CUSTOMER_SMS_SENT:
      return {
        ...state,
        SMSCodeSent: true,
      };
    case CUSTOMER_2FA_EMAIL_SENT:
      return {
        ...state,
        email2FASent: true,
      };
    case CUSTOMER_CODE_VERIFIED:
      return {
        ...state,
        verifyingCode: false,
        codeVerified: true,
      };
    case CHECKING_CUSTOMER_CODE:
      return {
        ...state,
        verifyingCode: true,
      };
    case SET_CUSTOMER_STATECODE:
      return {
        ...state,
        location: {
          ...state.location,
          stateCode: action.payload,
        },
      };
    case SET_CUSTOMER_COORDINATES:
      return {
        ...state,
        location: {
          ...state.location,
          coords: {
            lat: action.payload.lat,
            lon: action.payload.lon,
          },
        },
      };
    case SET_CUSTOMER_SERVICES:
      return {
        ...state,
        location: {
          ...state.location,
          services: action.payload,
        },
      };
    case SET_CUSTOMER_STATECOORDS:
      return {
        ...state,
        location: {
          ...state.location,
          stateCoords: action.payload,
        },
      };
    case SET_SUPPORTED_LANGUAGES:
      return {
        ...state,
        supportedLanguages: action.payload,
      };
    case SET_ACHIEVEMENTS:
      return {
        ...state,
        achievements: action.payload,
      };
    case SET_ASSOCIATED_REDEMPTION_CENTER:
      return {
        ...state,
        redemptionCenter: action.payload,
      };
    case RESET_CUSTOMER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
