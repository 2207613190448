import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// components
import Page from 'components/Page/Page';
import TitleSection from './Section/TitleSection';
import SEOTags from 'components/SEOTags/SEOTags';
import InfoCard from 'components/InfoCard/InfoCard';
import RedemptionCenterLocations from 'views/HowItWorks/Section/RedemptionCentersSection';
import CallToAction from 'components/CallToAction/CallToAction';
import Section from 'components/Page/Section';
import StickyNav from 'views/HowItWorks/Section/StickyNav';
import SectionHeader from 'components/Header/SectionHeader';
import GettingPaidSteps from 'views/HowItWorks/Section/GettingPaidSteps';
import CustomerServices from 'views/HowItWorks/Section/CustomerServices';

// assets
import truckGif from 'assets/img/truckGifReduced.gif';
import ecoSystem from 'assets/img/recycletek-ecosystem.png';
import ecoPod from 'assets/img/ecopod.png';
import recyclingTogether from 'assets/img/recycling-together.png';

const useStyles = makeStyles((theme) => ({
  cards: {
    minWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    },
  },
  card: {
    maxWidth: '235px',
    minWidth: '235px',
    minHeight: '120px',
    height: 'max-content',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  footer: {
    marginTop: theme.spacing(4),
  },
  services: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  service: {
    width: '260px',
    margin: theme.spacing(2),
    '& .MuiTypography-body2': {
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-body1': {
      fontWeight: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ecoSystem: {
    height: '300px',
    width: 'auto',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  community: {
    height: '200px',
    width: 'auto',
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(6),
  },
  textImage: {
    width: '80%',
  },
  scrollAnchor: {
    scrollMarginTop: '200px',
  },
}));

const HowItWorks = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  const centers = [
    {
      title: t('how.center.one.title'),
      points: [
        t('how.center.one.points.p1'),
        t('how.center.one.points.p2'),
        t('how.center.one.points.p3'),
        t('how.center.one.points.p4'),
      ],
    },
    {
      title: t('how.center.two.title'),
      points: [
        t('how.center.two.points.p1'),
        t('how.center.two.points.p2'),
        t('how.center.two.points.p3'),
      ],
    },
    {
      title: t('how.center.three.title'),
      points: [
        t('how.center.three.points.p1'),
        t('how.center.three.points.p2'),
        t('how.center.three.points.p3'),
      ],
    },
    {
      title: t('how.center.four.title'),
      points: [t('how.center.four.points.p1'), t('how.center.four.points.p2')],
    },
  ];

  const retailers = [
    {
      title: t('how.retailer.one.title'),
      description: t('how.retailer.one.description'),
    },
    {
      title: t('how.retailer.two.title'),
      description: t('how.retailer.two.description'),
    },
    {
      title: t('how.retailer.three.title'),
      description: t('how.retailer.three.description'),
    },
    {
      title: t('how.retailer.four.title'),
      description: t('how.retailer.four.description'),
    },
  ];

  return (
    <>
      <SEOTags
        title="How Reverse Vending Machine Works - Recycletek"
        description="We have reinvented recycling by radically improving the system of collection, identification, and redemption through our reverse vending machine technology."
      />
      <Page>
        <TitleSection />
        <Container maxWidth="lg">
          <Section textWithImage>
            <Box>
              <Typography variant="h3">{t('how.beneficialForAll')}</Typography>
              <Typography variant="body2">
                <Trans>{t('how.beneficialForAllParagraph')}</Trans>
              </Typography>
            </Box>
            <Box>
              <img
                className={classes.textImage}
                src={truckGif}
                alt="recycling truck"
              />
            </Box>
          </Section>
        </Container>
        <StickyNav />
        <Container>
          <Box id="customers" className={classes.scrollAnchor}>
            <SectionHeader text="how.solutionsForReturnCustomers" />
            <Section noMarginTop>
              <Typography variant="h3">
                {t('how.gettingPaidIsSimple')}
              </Typography>
              <GettingPaidSteps />
              <Section>
                <RedemptionCenterLocations />
              </Section>
              <Section>
                <CustomerServices />
              </Section>
              <CallToAction
                text={t('how.startRecycling')}
                buttonText={t('how.signUp')}
                link="/signup"
                center
              />
            </Section>
          </Box>
          <Box id="redemption-centers" className={classes.scrollAnchor}>
            <SectionHeader text="how.solutionsForRedemptionCenters" />
            <Section textWithImage noMarginTop>
              <Box>
                <Typography variant="h3">{t('how.boostEfficiency')}</Typography>
                <Typography variant="body2">
                  <Trans>{t('how.boostEfficiencyParagraph')}</Trans>
                </Typography>
              </Box>
              <Box>
                <img
                  className={classes.textImage}
                  src={ecoSystem}
                  alt="Recycletek flowchart"
                />
              </Box>
            </Section>
            <Section>
              <Box className={classes.cards}>
                {centers.map((solution) => (
                  <InfoCard reducedPadding heading={solution.title}>
                    <Box className={classes.card}>
                      <List dense>
                        {solution.points.map((point) => (
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText variant="h4" primary={point} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </InfoCard>
                ))}
              </Box>
            </Section>
            <CallToAction
              text={t('how.productCatalog')}
              buttonText="Products"
              link="/products"
              center
            />
          </Box>
          <Box id="retailers" className={classes.scrollAnchor}>
            <SectionHeader text="how.solutionsForRetailers" />
            <Section textWithImage noMarginTop>
              <Box>
                <Typography variant="h3">
                  {t('how.unparalledExperience')}
                </Typography>
                <Typography variant="body2">
                  <Trans>{t('how.unparalleledParagraph')}</Trans>
                </Typography>
              </Box>
              <Box>
                <img
                  className={classes.textImage}
                  src={ecoPod}
                  alt="Recycletek EcoPod"
                />
              </Box>
            </Section>
            <Section>
              <Box className={classes.cards}>
                {retailers.map((solution) => (
                  <InfoCard breakHeading heading={solution.title}>
                    <Box className={classes.card}>
                      <Typography>{solution.description}</Typography>
                    </Box>
                  </InfoCard>
                ))}
              </Box>
            </Section>
            <CallToAction
              text={t('how.productCatalog')}
              buttonText={t('how.products')}
              link="/products"
              center
            />
          </Box>
          <Box id="municipalities" className={classes.scrollAnchor}>
            <SectionHeader text="how.solutionsForMunicipalities" />
            <Section textWithImage noMarginTop>
              <Box>
                <Typography variant="h3">{t('how.minimizeWaste')}</Typography>
                <Typography variant="body2">
                  {t('how.minimizeWasteParagraph')}
                </Typography>
              </Box>
              <Box>
                <img
                  className={classes.textImage}
                  src={recyclingTogether}
                  alt="Recycling together in a group"
                />
              </Box>
            </Section>
            <CallToAction
              text={t('how.productCatalog')}
              buttonText={t('how.products')}
              link="/products"
              center
            />
            <Section />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default HowItWorks;
