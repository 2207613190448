import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import {
  CardContent,
  Card,
  CardMedia,
  Typography,
  Box,
} from '@material-ui/core';

// Components
import EcoTitle from 'components/Typography/EcoTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
    '@media (max-width:450px)': {
      width: '100%',
    },
  },
  media: {
    maxHeight: '250px',
    height: 'auto',
    display: 'flex',
    objectFit: 'contain',
  },
  content: {
    flex: '1 0 auto',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  card: {
    display: 'flex',
  },
  textBlock: {
    textAlign: 'justify',
  },
}));

const EcoContainerCard = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { image, alt, regular, bold, text } = props.EcoContainerInfo;

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={image}
          title={alt}
          component="img"
        />
        <CardContent>
          <Box mb={2}>
            <EcoTitle
              regular={t(regular)}
              bold={t(bold)}
              variant="h4"
              color="primary"
            />
          </Box>
          <Typography className={classes.textBlock} variant="caption">
            {t(text)}
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EcoContainerCard
