import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControlLabel,
  Typography,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export default function ConfirmForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {props.type !== '' ? (
        <Grid item>
          <React.Fragment>
            <FormControlLabel
              classes={{ root: classes.formControl }}
              control={
                <Checkbox
                  checked={props.confirm}
                  onChange={props.handleConfirm}
                  name="confirm"
                  color="primary"
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  style={{ color: '#797777', textTransform: 'none' }}
                >
                  {props.type === 'bulk'
                    ? t('schedule.confirmForm.bulkRequirements')
                    : t('schedule.confirmForm.curbsideRequirements')}
                </Typography>
              }
            />
            <FormHelperText error={props.confirmError}>
              {props.confirmError
                ? t('schedule.confirmForm.pleaseConfirm')
                : null}
            </FormHelperText>
          </React.Fragment>
        </Grid>
      ) : null}
    </React.Fragment>
  );
}
