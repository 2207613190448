import { useState } from 'react';

const defaultValues = {
  charity: null,
  confirm: false,
  ecokrates: '0',
  ecosacks: '0',
  superecosacks: '0',
  instructions: '',
  payment: '',
  orderSupplies: false,
  recurringChecked: false,
  recurringDays: '',
  selectedDate: null,
  type: '',
};

const defaultErrors = {
  charityError: false,
  confirmError: false,
  itemCountError: {
    error: false,
    helperText: '',
  },
  paymentError: false,
  orderSuppliesError: false,
  recurringCheckedError: {
    error: false,
    helperText: '',
  },
  selectedDateError: {
    error: false,
    helperText: '',
  },
  typeError: false,
};

export default function useSchedulePickup() {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultErrors);

  // #To Do: eliminate  functions duplicate functions

  const setType = (newValues) => {
    // sets type and clears date and recurring checked
    setValues({ ...defaultValues, ...newValues });
  };

  const setTypeError = (typeError) => {
    setErrors((prev) => ({ ...prev, typeError }));
  };

  const setSelectedDate = (newValues) => {
    // sets selectedDate with new date and always resets recurringChecked to false
    setValues((prev) => ({ ...prev, ...newValues }));
  };

  const setSelectedDateError = (newErrors) => {
    // sets selectedDateError and always resets recurringCheckedError to false
    setErrors((prev) => ({ ...prev, ...newErrors }));
  };

  const setRecurringChecked = (newValues) => {
    // sets recurringChecked and recurringDays
    setValues((prev) => ({ ...prev, ...newValues }));
  };

  const setRecurringCheckedError = (recurringCheckedError) => {
    setErrors((prev) => ({ ...prev, recurringCheckedError }));
  };

  const setItemCount = (name, value) => {
    // sets either ecosacks or ecokrates
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const setItemCountError = (itemCountError) => {
    setErrors((prev) => ({ ...prev, itemCountError }));
  };

  const setCharity = (charity) => {
    setValues((prev) => ({ ...prev, charity }));
  };

  const setCharityError = (charityError) => {
    setErrors((prev) => ({ ...prev, charityError }));
  };

  const setPayment = (values) => {
    // sets payment and resets charity
    setValues((prev) => ({ ...prev, ...values }));
  };

  const setPaymentError = (newErrors) => {
    // sets both paymentError and charityError
    setErrors((prev) => ({ ...prev, ...newErrors }));
  };

  const setOrderSupplies = () => {
    setValues((prev) => ({ ...prev, orderSupplies: !prev.orderSupplies }));
  };

  const setConfirm = () => {
    setValues((prev) => ({ ...prev, confirm: !prev.confirm }));
  };

  const setConfirmError = () => {
    setErrors((prev) => ({ ...prev, confirmError: !prev.confirmError }));
  };

  const setFormValidationErrors = (errors) => {
    setErrors((prev) => ({ ...prev, ...errors }));
  };

  // general function to reset any single value
  const setValue = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const resetErrors = () => {
    setErrors(defaultErrors);
  };

  return {
    values,
    errors,
    setType,
    setTypeError,
    setSelectedDate,
    setSelectedDateError,
    setRecurringChecked,
    setRecurringCheckedError,
    setItemCount,
    setItemCountError,
    setPayment,
    setPaymentError,
    setCharity,
    setCharityError,
    setValue,
    setOrderSupplies,
    setConfirm,
    setConfirmError,
    setFormValidationErrors,
    resetValues,
    resetErrors,
  };
}
