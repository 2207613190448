import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import { green, red, yellow } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#004f71',
      dark: '#003F5A',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#97D700',
      light: '#EAF7CC',
      dark: '#78AC00',
    },
    tertiary: {
      main: '#008EAA',
    },
    red: {
      main: '#BE4D00',
    },
    orange: {
      main: '#DE7C00',
    },
    yellow: {
      main: '#ffC845',
    },
    aqua: {
      main: '#05C3DD',
      light: '#CDF3F8',
      dark: '#04AFC6',
    },
    teal: {
      main: '#34A798',
    },
    purple: {
      main: '#893B67',
    },
    grey: {
      main: '#E6E7E9',
      light: '#efefee',
      dark: '#7C878E',
    },
    background: {
      default: '#fff',
      portal: '#f7f7f7',
    },
    text: {
      primary: '#797777',
    },
    warning: {
      main: yellow[500],
      dark: yellow[700],
      light: yellow[50],
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
});

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    ...rawTheme.palette,
  },
  shadows: [...rawTheme.shadows, '0px 0px 10px 5px rgba(7,7,7,0.07)'],
  typography: {
    fontFamily: 'HelveticaNeue, Helvetica, Arial, sans-serif',
    h3: {
      fontWeight: 300,
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: rawTheme.palette.primary.main,
        '& button': {
          backgroundColor: 'inherit',
          padding: '2px 10px',
          '&:hover': {
            backgroundColor: rawTheme.palette.primary.dark,
          },
        },
      },
    },

    MuiPickersDay: {
      day: {
        color: rawTheme.palette.primary.main,
      },
      daySelected: {
        backgroundColor: rawTheme.palette.tertiary.main,
        color: rawTheme.palette.primary.contrastText,
      },
      dayDisabled: {
        color: rawTheme.palette.grey.dark,
      },
      current: {
        color: rawTheme.palette.aqua.main,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: rawTheme.palette.primary.main,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: '330px',
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '12px',
      },
      contained: {
        backgroundColor: '#97D700',
        color: 'white',
        textAlign: 'center',
        textTransform: 'inherit',
        boxShadow: 'none',
        padding: '8px 36px',
        fontWeight: 500,
        fontSize: '18px',

        '&:hover': {
          backgroundColor: '#008EAA',
          color: 'white',
        },
      },
    },
  },
});

const memberPortalTheme = responsiveFontSizes(theme);

export default memberPortalTheme;
