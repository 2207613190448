import React from "react";
import axios from "axios";

import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Hidden, Container, Box } from '@material-ui/core';

// components
import BlogCard from 'components/BlogCard/BlogCard';
import Page from 'components/Page/Page';
import InfoCard from 'components/InfoCard/InfoCard';
import CallToAction from 'components/CallToAction/CallToAction';
import ContactForm from 'components/ContactForm/ContactForm';
import Section from 'components/Page/Section';
import Video from 'components/Video/Video';

// assets
import NonProfitIcon from 'assets/img/non_profit.png';
import QuotationIcon from 'assets/img/quotation_marks.png';
import BagDropImage from 'assets/img/non_profits_bagdrop.png';
import BagDropCommunity from 'assets/img/non_profits_community_1.png';
import BagDropScaling from 'assets/img/non_profits_community.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  teaserText: {
    fontSize: '1.9rem',
    fontWeight: 300,
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      lineHeight: 1.5,
    },
  },
  cta: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  bulletText: {
    fontWeight: 500,
  },
  rtlist: {
    listStyle: 'none',
    marginLeft: 0,
    paddingInlineStart: 0,
  },
  rtlistitem: {
    marginBottom: '1em',
  },
  profile_image: {
    width: '95%',
    maxWidth: '523px',
    maxHeight: '950px',
    paddingRight: '15px',
  },
  founderName: {
    fontSize: '2rem',
    lineHeight: '2rem',
  },
  caption: {
    fontSize: '1.125rem',
    fontWeight: 300,
    lineHeight: '2rem',
    textAlign: 'justify',
  },
  founderTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '3rem',
  },
  container_right: {
    width: '100%',
    marginTop: '6rem',
    marginBottom: '5rem',
    paddingLeft: '12.5vw',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginTop: theme.spacing(2),
    },
  },
  container_parallax: {
    width: '100vw',
    height: '600px',
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  image: {
    width: '100%',
    maxWidth: '523px',
    maxHeight: '950px',
    float: 'right',
  },
  profile: {
    textAlign: 'center',
    maxWidth: '300px',
  },
  round_image: {
    width: '80%',
    minWidth: '100px',
    borderRadius: '50%',
    border: '5px solid #fff',
    boxShadow: '0 0 0 3px #aaa',
    margin: '50px 10px 20px 10px',
  },
  round_image_lg: {
    width: '100%',
    height: 'auto',
    minWidth: '100px',
    borderRadius: '50%',
    border: '5px solid #fff',
    boxShadow: '0 0 0 3px #aaa',
    margin: '50px 10px 20px 10px',
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: 300,
    lineHeight: '3.2rem',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeline_entry: {
    marginTop: '-2rem',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  card: {
    maxWidth: '320px',
  },
  videoBox: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const NonProfits = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const contactform_options = [
    {
      title: 'contact.nonprofits',
      text: 'adam@recycletek.co',
      value: 'adam@recycletek.co',
    },
  ];

  return (
    <Page>
      <div className={classes.main}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2">
                {t('nonprofits.turnRecyclingIntoFunds')}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                'flex-wrap': 'wrap',
                alignContent: 'center',
              }}
            >
              <Typography className={classes.teaserText}>
                <Trans>{t('nonprofits.hostABottleDrop')}</Trans>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <img
                src={NonProfitIcon}
                alt="Non-Profit Icon"
                style={{ width: '50%' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">
                {t('nonprofits.keyBenefits')}
              </Typography>
              <ul className={classes.rtlist}>
                <li className={classes.rtlistitem}>
                  <span className={classes.bulletText}>
                    {t('nonprofits.stateSponsored')}
                  </span>
                  <br /> {t('nonprofits.stateSponsoredText')}
                </li>
                <li className={classes.rtlistitem}>
                  <span className={classes.bulletText}>
                    {t('nonprofits.simpleAndEasy')}
                  </span>
                  <br /> {t('nonprofits.simpleAndEasyText')}
                </li>
                <li className={classes.rtlistitem}>
                  <span className={classes.bulletText}>
                    {t('nonprofits.donations')}
                  </span>
                  <br /> {t('nonprofits.donationsText')}
                </li>
                <li className={classes.rtlistitem}>
                  <span className={classes.bulletText}>
                    {t('nonprofits.financialSupport')}
                  </span>
                  <br /> {t('nonprofits.financialSupportText')}
                </li>
                <li className={classes.rtlistitem}>
                  <span className={classes.bulletText}>
                    {t('nonprofits.roomForGrowth')}
                  </span>
                  <br /> {t('nonprofits.roomForGrowthText')}
                </li>
              </ul>
            </Grid>
          </Grid>

          <Section>
            <Typography variant="h2">{t('nonprofits.howItWorks')}</Typography>
            <Box className={classes.cards}>
              <InfoCard heading={t('nonprofits.stepOne.heading')}>
                <Box className={classes.card}>
                  <Typography>{t('nonprofits.stepOne.text')}</Typography>
                  <img
                    src={BagDropImage}
                    alt="Bagdrop Program"
                    style={{ width: '100%', marginTop: '15px' }}
                  />
                </Box>
              </InfoCard>

              <InfoCard heading={t('nonprofits.stepTwo.heading')}>
                <Box className={classes.card}>
                  <Typography>{t('nonprofits.stepTwo.text')}</Typography>
                  <img
                    src={BagDropCommunity}
                    alt="Bagdrop Program"
                    style={{ width: '100%', marginTop: '15px' }}
                  />
                </Box>
              </InfoCard>

              <InfoCard heading={t('nonprofits.stepThree.heading')}>
                <Box className={classes.card}>
                  <Typography>{t('nonprofits.stepThree.text')}</Typography>
                  <img
                    src={BagDropScaling}
                    alt="Bagdrop Program"
                    style={{ width: '100%', marginTop: '15px' }}
                  />
                </Box>
              </InfoCard>
            </Box>
          </Section>

          <Section>
            <Video
              videoLink="https://youtube.com/embed/bsM67JPkGuU?rel=0&showinfo=1&modestbranding=1"
              title={t('nonprofits.shortVideo')}
              text={t('nonprofits.videoDescription')}
            />
          </Section>

          <Grid item xs={12}>
            <Typography variant="h2">{t('nonprofits.testimonial')}</Typography>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '50px' }}>
                {' '}
                {/* style={{'textAlign':'center'}}> */}
                {/* <span style={{'fontSize':'120px', 'fontFamily':'Times New Roman'}}>&quot;</span> */}
                {/*<img src={QuotationIcon} alt="Quotation Icon" style={{'width': '50%'}} />*/}
                <Grid container>
                  <Grid item xs={1}>
                    <div
                      style={{
                        height: '100%',
                        borderRight: '5px solid #0398b8',
                      }}
                    >
                      <img
                        src={QuotationIcon}
                        alt="Quotation Icon"
                        style={{ width: '80%' }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={11} style={{ paddingLeft: '15px' }}>
                    <i>{t('nonprofits.testimonialText')}</i>
                    <br />
                    <div style={{ float: 'right' }}>
                      Debbie from{' '}
                      <span style={{ fontWeight: 500 }}>
                        Angels of Long Island
                      </span>
                      <br style={{ float: 'none' }} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2">
              {t('nonprofits.readyToRaiseFunds')}
            </Typography>
            <Typography variant="body2">
              {t('nonprofits.reachOutToUs')}{' '}
              <a href="tel:6317667067" className={classes.link}>
                631.766.7067
              </a>{' '}
              {t('nonprofits.or')}{' '}
              <a href="mailto:adam@recycletek.co" className={classes.link}>
                adam@recycletek.co
              </a>{' '}
              {t('nonprofits.orUseTheContactForm')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginTop: '50px', marginBottom: '50px' }}
          >
            <ContactForm
              emailAddress="adam@recycletek.co"
              options={contactform_options}
            />
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default NonProfits;

NonProfits.propTypes = {
  children: PropTypes.node,
};
