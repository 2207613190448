// lib
import React from 'react';
import { Route } from 'react-router-dom';

// components
import ScheduleHeader from '../Schedule/ScheduleHeader';
import RecyclableHeader from '../Recyclable/RecyclableHeader';
import HowToHeader from '../HowTo/HowToHeader';
import DashboardHeader from '../Dashboard/DashboardHeader';
import LocationsHeader from '../Locations/LocationsHeader';
import EcoWalletHeader from '../EcoWallet/EcoWalletHeader';
import AccountHeader from '../Account/AccountHeader';
import EcoSuppliesHeader from '../EcoSupplies/EcoSuppliesHeader';
import FAQHeader from '../FAQ/FAQHeader';
import MetricsHeader from '../Metrics/MetricsHeader';

export default function Header() {
  return (
    <>
      <Route path="/portal/schedule" component={ScheduleHeader} />
      <Route path="/portal/metrics" component={MetricsHeader} />
      <Route path="/portal/dashboard" component={DashboardHeader} />
      <Route path="/portal/ecowallet" component={EcoWalletHeader} />
      <Route path="/portal/ecosupplies" component={EcoSuppliesHeader} />
      <Route path="/portal/recyclable" component={RecyclableHeader} />
      <Route path="/portal/locations" component={LocationsHeader} />
      <Route path="/portal/account" component={AccountHeader} />
      <Route path="/portal/howto" component={HowToHeader} />
      <Route path="/portal/faq" component={FAQHeader} />
    </>
  );
}
