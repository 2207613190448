import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, List } from '@material-ui/core';

import FAQListItem from './FAQListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '800px',
    borderRadius: 20,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
}));

function FAQList(props) {
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardContent>
        <List>
          {props.faqs.map((item, index) => (
            <FAQListItem key={index} item={item} num={index} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

export default FAQList;
