import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  services: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  service: {
    width: '260px',
    margin: theme.spacing(2),
    '& .MuiTypography-body2': {
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-body1': {
      fontWeight: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const CustomerServices = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3">{t('how.recycletekServices')}</Typography>
      <Box className={classes.services}>
        <Box className={classes.service}>
          <Typography variant="body2">{t('how.walkInCashOut')}</Typography>
          <Typography>{t('how.walkInCashOutParagraph')}</Typography>
        </Box>
        <Box className={classes.service}>
          <Typography variant="body2">{t('how.rewards')}</Typography>
          <Typography>{t('how.rewardsParagraph')}</Typography>
        </Box>
        <Box className={classes.service}>
          <Typography variant="body2">{t('how.pickupService')}</Typography>
          <Typography>{t('how.pickupServiceParagraph')}</Typography>
        </Box>
        <Box className={classes.service}>
          <Typography variant="body2">{t('how.bagDrop')}</Typography>
          <Typography>{t('how.bagDropParagraph')}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default CustomerServices;
