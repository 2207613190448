import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  text: {
    fontWeight: 300,
    maxWidth: '465px',
    textAlign: 'justify',
  },
}));

function LocationsHeader(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt={3}>
      <Box mb={2}>
        <Typography variant="h3" color="primary">
          Recycling<span className={classes.name}> Locations</span>
        </Typography>
        <Typography className={classes.subtitle}>
          {t('locations.locationsSubtitle')}
        </Typography>
        <Typography className={classes.text}>
          {t('locations.holidayHours')}
        </Typography>
      </Box>
    </Box>
  );
}

export default LocationsHeader;
