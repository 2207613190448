import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export default function CustomPhoneNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      format="+1 (###) ###-####"
      allowEmptyFormatting
      mask="_"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

CustomPhoneNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
