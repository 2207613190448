import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(18),
    textAlign: 'left',
    fontWeight: 300,
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(21),
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.tertiary.main,
      background: 'inherit',
    },
  },
}));

function DropOffOnlyCard(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.card}>
      <Typography variant="h5" color="primary">
        {' '}
        {t('schedule.dropOffOnly.serviceAreaNotAvailable')}
      </Typography>
      <Typography className={classes.text}>
        {t('schedule.dropOffOnly.canStillTakeAdvantage')}{' '}
        <NavLink exact to="/portal/locations" className={classes.link}>
          {t('schedule.dropOffOnly.dropOffLocations')}
        </NavLink>{' '}
        {t('schedule.dropOffOnly.findOneNearYou')}
      </Typography>
    </div>
  );
}

export default DropOffOnlyCard;

DropOffOnlyCard.propTypes = {
  children: PropTypes.node,
};
