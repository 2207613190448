import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { Toolbar, Box, Paper, Typography, Hidden } from '@material-ui/core';

// components
import Arrow from 'components/Arrow/Arrow';

// assets
import ecoSupplies from 'assets/img/portal/howtoicons_ecosupplies.svg';
import containers from 'assets/img/portal/howtoicons_containers.svg';
import dollarSign from 'assets/img/portal/howtoicons_dollarSign.svg';
import sort from 'assets/img/portal/howtoicons_ecosuppliesandcontainers.svg';
import truck from 'assets/img/portal/howtoicons_truck.svg';
import rightArrow from 'assets/img/portal/rightarrow.svg';
import mobileRightArrow from 'assets/img/portal/mobilearrow_right.svg';
import mobileLeftArrow from 'assets/img/portal/mobilearrow_left.svg';
import leftArrow from 'assets/img/portal/leftarrow.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1000px',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  root: {
    maxWidth: '1280px',
    borderRadius: 20,
    padding: theme.spacing(6),
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  row: {
    maxWidth: '950px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
    '&:last-child': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(7),
      },
    },
  },
  section: {
    maxWidth: '950px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    '&:last-child': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(7),
    },
  },
  section_right: {
    maxWidth: '950px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(7),
    },
  },
  image: {
    maxHeight: '80px',
    width: 'auto',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxHeight: '40px',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
      whiteSpace: 'normal',
    },
  },
  thin: {
    fontWeight: 300,
  },
  number: {
    fontSize: theme.typography.pxToRem(50),
    fontWeight: 400,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  largeCard: {
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '500px',
    },
  },
  smallCard: {
    maxWidth: '500px',
  },
  cardText: {
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      hyphens: 'auto',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  hyperLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

const HowToStep = ({ size, src, altText, step, header, text }) => {
  const classes = useStyles();

  return (
    <>
      <div className={size === 'lrg' ? classes.largeCard : classes.smallCard}>
        <div className={classes.cardTitle}>
          <img className={classes.image} src={src} alt={altText} />
          <Box>
            <Typography className={classes.number} color="primary">
              {step}
            </Typography>
            <Typography variant="h4" color="primary" className={classes.title}>
              {header}
            </Typography>
          </Box>
        </div>
        <div>
          <Typography className={classes.cardText}>{text}</Typography>
        </div>
      </div>
    </>
  );
};

export default HowToStep;
