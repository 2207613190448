import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  profile: {
    textAlign: 'center',
    maxWidth: '300px',
  },
  round_image: {
    width: '80%',
    minWidth: '100px',
    borderRadius: '50%',
    border: '5px solid #fff',
    boxShadow: '0 0 0 3px #aaa',
    margin: '50px 14px 20px 14px',
  },
}));

export default function Employee({ name, position, image }) {
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={6} className={classes.profile}>
      <img className={classes.round_image} src={image} alt="Employee image" />
      <Typography variant="body2" color="primary">
        {name}
      </Typography>
      <Typography variant="caption">{position}</Typography>
    </Grid>
  );
}
