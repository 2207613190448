import React from 'react';

// @material-ui/core components
import { Grid } from '@material-ui/core';

// Components
import EcoContainerCard from 'components/EcoContainerCard/EcoContainerCard';

// assets
import ecoBin from 'assets/img/tech/ecoBin.svg';
import ecoContainer from 'assets/img/tech/ecoContainer.svg';
import ecoSacks from 'assets/img/tech/ecoSacks.svg';
import ecoKrate from 'assets/img/tech/ecoKrate.svg';

const EcoContainerInfo = [
  {
    image: ecoSacks,
    alt: 'EcoSack',
    regular: 'tech.eco',
    bold: 'tech.sack',
    text: 'tech.ecoSackBody',
  },
  {
    image: ecoContainer,
    alt: 'EcoCubes',
    regular: 'tech.eco',
    bold: 'tech.cube',
    text: 'tech.ecoCubeBody',
  },
  {
    image: ecoBin,
    alt: 'EcoBin',
    regular: 'tech.eco',
    bold: 'tech.bin',
    text: 'tech.ecoBinBody',
  },
  {
    image: ecoKrate,
    alt: 'EcoKrate',
    regular: 'tech.eco',
    bold: 'tech.krate',
    text: 'tech.ecoKrateBody',
  },
];

const EcoContainerSection = () => {
  return (
    <React.Fragment>
      <Grid container justify="center" spacing={5}>
        <Grid item xs={12} container justify="center" spacing={5}>
          <Grid item sm={12} md={6} container justify="center">
            <EcoContainerCard EcoContainerInfo={EcoContainerInfo[0]} />
          </Grid>
          <Grid item sm={12} md={6} container justify="center">
            <EcoContainerCard EcoContainerInfo={EcoContainerInfo[1]} />
          </Grid>
        </Grid>
        <Grid item xs={12} container justify="center" spacing={5}>
          <Grid item sm={12} md={6} container justify="center">
            <EcoContainerCard EcoContainerInfo={EcoContainerInfo[2]} />
          </Grid>
          <Grid item sm={12} md={6} container justify="center">
            <EcoContainerCard EcoContainerInfo={EcoContainerInfo[3]} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EcoContainerSection;
