import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { Box, Typography } from '@material-ui/core';

// assets
import signupIcon from 'assets/img/signup-icon.png';
import containerIcon from 'assets/img/container-icon.png';
import getPaidIcon from 'assets/img/get-paid-icon.png';

const useStyles = makeStyles((theme) => ({
  steps: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    '& .MuiTypography-body2': {
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    maxWidth: '300px',
    textAlign: 'center',
    '& .MuiTypography-body1': {
      fontWeight: 300,
    },
    '& img': {
      height: '100px',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
}));

const GettingPaidSteps = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.steps}>
      <Box className={classes.step}>
        <img src={signupIcon} alt="sign up icon" />
        <Typography variant="body2">{t('how.signUp')}</Typography>
        <Typography>{t('how.freeAccount')}</Typography>
      </Box>
      <Box className={classes.step}>
        <img src={containerIcon} alt="bottle icon" />
        <Typography variant="body2">{t('how.bringYourContainers')}</Typography>
        <Typography>{t('how.walkInOrDropOff')}</Typography>
      </Box>
      <Box className={classes.step}>
        <img src={getPaidIcon} alt="dollar sign icon" />
        <Typography variant="body2">{t('how.getPaid')}</Typography>
        <Typography>{t('how.payoutOptions')}</Typography>
      </Box>
    </Box>
  );
};

export default GettingPaidSteps;
