import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Container, Box } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import TitleSection from './Sections/TitleSection';

// assets
import plasticInOcean from 'assets/img/plasticInOcean.png';
import anglesOfLongIsland from 'assets/img/Angles-of-Long-Island.jpg';
import boyScoutsOfAmerica from 'assets/img/boy-scouts-of-america.png';

const useStyles = makeStyles((theme) => ({
  largeText: {
    fontSize: '1.9rem',
    fontWeight: 300,
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      lineHeight: 1.5,
    },
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  fullWidthContainer: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    boxShadow: theme.shadows[4],
    maxWidth: '500px',
  },
  textBlock: {
    textAlign: 'justify',
  },
  margin: {
    marginBottom: '10rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(8),
    },
  },
  bold: {
    fontWeight: 500,
  },
}));

const HowItWorks = (props) => {
  const classes = useStyles();

  return (
    <Page>
      <div className={classes.main}>
        <div className={classes.fullWidthContainer}>
          <TitleSection />
        </div>
        <Container
          maxWidth="lg"
          className={`${classes.container} ${classes.margin}`}
        >
          <Typography className={classes.largeText}>
          Recycletek is dedicated to making the world a cleaner, more sustainable
            place for all its inhabitants. Recycletek believes that sustainability
            goes beyond the realm of keeping our oceans clean and our greenhouse
            gas emissions low. We believe that a more sustainable earth must
            start with a more sustainable society and that is why we have teamed
            up with Non-Profit organizations that are dedicated to uplifting the
            communities in which we serve. Recycletek directly works with Non-profit
            organizations to help them fundraise through bottle and can
            collection programs. Additionally we encourage all members to donate
            partial amounts of their proceeds to charities of their choosing.
          </Typography>
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={8}>
            <Grid
              item
              sm={12}
              md={6}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <img
                className={classes.image}
                src={anglesOfLongIsland}
                alt="Angels of Long Island"
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Box>
                <Typography color="primary" variant="h3">
                  Angels of Long Island
                </Typography>
                <Typography className={classes.textBlock} variant="caption">
                  The Angels of Long Island is a Patchogue based 501-C3
                  Non-Profit. The Angels of Long Island provide supplemental
                  support and resources to people who are struggling through a
                  crisis. Their goal is to grow our organization and increase
                  our free membership to assist us in opening additional
                  locations.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={8}>
            <Grid
              item
              sm={12}
              md={6}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <img
                className={classes.image}
                src={plasticInOcean}
                alt="Money Gif"
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Box>
                <Typography color="primary" variant="h3">
                  Clean Oceans Initiative
                </Typography>
                <Typography className={classes.textBlock} variant="caption">
                  <span className={classes.bold}>
                    {`100% For The Planet - Donate today for beach cleanups in
                    your area. `}
                  </span>
                  Recycletek is on a mission to reduce ocean pollution. 100% of your
                  donation will go directly to cleaning trash and recyclable
                  items from beaches in your community or a community of your
                  choice. We do not take any administrative or other fees. Your
                  donation directly funds the purchase of equipment for
                  volunteer beach pickers, such as gloves, bags and vests.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={8}>
            <Grid
              item
              sm={12}
              md={6}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <img
                className={classes.image}
                src={boyScoutsOfAmerica}
                alt="Boy Scouts of America"
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Box>
                <Typography color="primary" variant="h3">
                  Boy Scouts of America
                </Typography>
                <Typography className={classes.textBlock} variant="caption">
                  The Boy Scouts of America has over 2.2 million youth members
                  between the ages of 5 and 21 and approximately 800,000
                  volunteers with a mission to help youngsters be future ready
                  through character development and values-based leadership
                  training. We currently service Troop 175 of West Babylon.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default HowItWorks;
