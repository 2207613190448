import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
// @material-ui/icons
import Avatar from '@material-ui/core/Avatar';

// assets
import CanIcon from 'assets/img/portal/icon_can.svg';
import GlassIcon from 'assets/img/portal/icon_glass.svg';
import PlasticIcon from 'assets/img/portal/icon_plastic.svg';

const useStyles = makeStyles((theme) => ({
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  can: {
    width: '23px',
  },
  canIcon: {
    backgroundColor: theme.palette.grey.light,
  },
  plastic: {
    width: '19px',
  },
  plasticTitle: {
    color: theme.palette.aqua.dark,
  },
  plasticIcon: {
    backgroundColor: theme.palette.aqua.light,
  },
  glass: {
    width: '17px',
  },
  glassTitle: {
    color: theme.palette.secondary.dark,
  },
  glassIcon: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

function ContainerIcons(props) {
  const classes = useStyles(props);

  return (
    <Box ml={2} mb={3} display="flex" justifyContent="flex-start">
      <Box mr={2} display="flex" flexDirection="column" alignItems="center">
        <Avatar
          className={classes.iconAvatar}
          classes={{ colorDefault: classes.canIcon }}
        >
          <img className={classes.can} src={CanIcon} alt="can" />
        </Avatar>
        <Typography>Aluminum</Typography>
      </Box>
      <Box mr={3} display="flex" flexDirection="column" alignItems="center">
        <Avatar
          className={classes.iconAvatar}
          classes={{ colorDefault: classes.plasticIcon }}
        >
          <img className={classes.plastic} src={PlasticIcon} alt="plastic" />
        </Avatar>
        <Typography className={classes.plasticTitle}>Plastic</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Avatar
          className={classes.iconAvatar}
          classes={{ colorDefault: classes.glassIcon }}
        >
          <img className={classes.glass} src={GlassIcon} alt="glass" />
        </Avatar>
        <Typography className={classes.glassTitle}>Glass</Typography>
      </Box>
    </Box>
  );
}

export default ContainerIcons;
