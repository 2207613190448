/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Typography, Container, Toolbar } from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import EcoTitle from 'components/Typography/EcoTitle';
import TitleSection from './Sections/TitleSection';
import EcoSystemSection from './Sections/EcoSystemSection';
import EcoContainerSection from './Sections/EcoContainerSection';
import SEOTags from 'components/SEOTags/SEOTags';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  ecoContainer: {
    marginBottom: '7rem',
    '&:last-child': {
      marginTop: '0',
      marginBottom: '14rem',
    },
  },
  videoBox: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  largeText: {
    fontSize: '1.9rem',
    fontWeight: '300',
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      fontWeight: '400',
      lineHeight: 1.5,
    },
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.0625rem',
    },
  },
  ecoImage: {
    width: '100%',
    height: 'auto',
    maxWidth: '250px',
  },
  textBlock: {
    textAlign: 'justify',
  },
}));

const Technology = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <>
      <SEOTags
        title="Modern Day Recycling Technology - Recycletek"
        description="Walk into our affiliated redemption centers with as little as 1 item to begin making an environmental impact and earn money with our modern day recycling."
      />
      <Page>
        <div className={classes.main}>
          <Toolbar />
          <TitleSection />
          <Container maxWidth="lg" className={classes.container}>
            <Typography className={classes.largeText}>
              {t('tech.modernDayRecyclingBody')}
            </Typography>
          </Container>

          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.videoBox}>
              <iframe
                className={classes.video}
                src="https://www.youtube.com/embed/ukqH6nIXDA0?si=cO7gzLexjRJEgSUj&rel=0&showinfo=1&modestbranding=1"
                muted="true"
                title="Recycling Reimagined"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </Container>

          <Container maxWidth="lg" className={classes.container}>
            <EcoTitle
              regular={t('tech.recycletekEco')}
              bold={t('tech.system')}
              variant="h3"
              color="primary"
            />
            <Typography className={classes.subContent}>
              {t('tech.recycletekEcoSystemBody')}
            </Typography>
          </Container>
          <EcoSystemSection />
          <Container maxWidth="lg" className={classes.container}>
            <EcoContainerSection />
          </Container>
        </div>
      </Page>
    </>
  );
};

export default Technology;
