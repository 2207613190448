// Transaction Ledger object
// customer_id: "1"
// error: null
// id: "2"
// new_customer_balance: 500
// note: null
// payout_amount: 1000
// previous_customer_balance: 1500
// task_id: "1"
// timestamp: 1625516956
// total_redemption_amount: 0

// Date
import { DateTime } from 'luxon';

export function formatMoney_alreadyInDollar(money) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(money);
}

export function formatMoney(money) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(money / 1_000_000);
}

export function getAccountBalance(transactionHistory) {
  if (transactionHistory.length === 0) {
    return 0;
  }
  const last = transactionHistory[0];
  return last.new_customer_balance;
}

export function getLatestTransaction(transactionHistory) {
  if (transactionHistory.length === 0) {
    return 0;
  }

  let index = 0;
  let latest;
  let date;

  for (let i = 0; i < transactionHistory.length; i++) {
    const tran = transactionHistory[i];
    const tranDate = DateTime.fromMillis(tran.timestamp * 1000);
    if (tran.transactiontype_id === 3) {
      index = i;
      date = tranDate;
      return tran;
    } else return { note: 'N/A', total_redemption_amount: 0 };
  }
}

let transactionTypes = {
  withdrawal: 1,
  donation: 2,
  deposit: 3,
  shop: 4,
};

export function formatTransactionHistory(transactionHistory) {
  let history = [];

  for (let i = 0; i < transactionHistory.length; i++) {
    const tran = transactionHistory[i];

    const transaction = {};
    transaction.amount = tran.total_transaction_value_microdollars;
    transaction.note = tran.note;

    switch (tran.transactiontype_id) {
      case 1:  // withdrawal
        transaction.color = 'deduction';
        break;
      case 2:  // donation
        transaction.amount = tran.redemption_amount_microdollars;
        transaction.color = 'neutral';
        break;
      case 3:  // deposit
        transaction.color = 'credit';
        break;
      case 4:  // shop
        if (tran.amount === 0) {
          transaction.color = 'neutral';
        } else {
          transaction.color = 'deduction';
        }
        break;
      default:
        ;
    }

    const transactionObject = {
      description: '',
      note: '',
      balance: '',
      date: '',
    };

    transaction.type = tran.transactiontype_id;
    transaction.description = tran.transactiontype;
    transaction.balance = tran.new_customer_balance;
    transaction.id = tran.id;
    transaction.type = tran.transactiontype_id;
    transaction.date = DateTime.fromMillis(tran.timestamp).toLocaleString();

    history.push(transaction);
  }
  return history;
}
