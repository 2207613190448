import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Material UI components
import {
  AppBar,
  Toolbar,
  Hidden,
  Drawer,
  IconButton,
  Box,
} from '@material-ui/core';

// Material UI Icons
import Menu from '@material-ui/icons/Menu';

// Components
import UpdateHeaderLink from 'components/Header/UpdateHeaderLink';
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';

// assets
import logo from 'assets/img/logo/brand-signature-tm.png';
import AccountMenu from 'components/Header/AccountMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none',
  },
  appBarTitle: {
    display: 'flex',
    flexGrow: 1,
  },
  toolbar: {
    alignItems: 'center',
    height: '80px',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      height: '70px',
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: '16rem',
    [theme.breakpoints.down('xs')]: {
      width: '14rem',
    },
  },
  drawerPaper: {
    width: 200,
  },
  logoLink: {
    display: 'inline-flex',
  },
  languageSelectSmall: {
    marginLeft: theme.spacing(2),
  },
}));

const Nav = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  return (
    <header className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box className={classes.appBarTitle}>
            <Link className={classes.logoLink} to="/">
              <img className={classes.logo} src={logo} alt="Recycletek Logo" />
            </Link>
          </Box>
          <Hidden smDown implementation="css">
            <Box>
              <UpdateHeaderLink />
            </Box>
          </Hidden>
          <div>
            <Hidden mdUp>
              <Box display="flex" alignItems="center">
                {auth.isAuthenticated ? <AccountMenu /> : null}
                <Box className={classes.languageSelectSmall}>
                  <LanguageSelect />
                </Box>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Box>
            </Hidden>
            <Hidden mdUp implementation="js">
              <Drawer
                variant="temporary"
                anchor={'right'}
                open={mobileOpen}
                classes={{
                  paper: classes.drawerPaper,
                }}
                onClose={handleDrawerToggle}
              >
                <div>
                  <Toolbar />
                  <UpdateHeaderLink handleDrawerToggle={handleDrawerToggle} />
                </div>
              </Drawer>
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Nav;
