import { SET_METRICS } from './types';

import { SERVER_URL } from 'config.js';
import { configureConfigHeader } from 'utils/utils.js';
import axios from 'axios';
import { calcPercentages, calcMonthlyTotal } from 'utils/metricsFunctions';

export const getMetrics = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  if (config.headers.Authorization) {
    return axios
      .get(`${SERVER_URL}/customer/stats`, config)
      .then((res) => {
        const data = res.data;
        data.percentages = calcPercentages(res.data.totals);
        data.stats = calcMonthlyTotal(res.data.per_date);
        dispatch({
          type: SET_METRICS,
          payload: { ...data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
