import React, { useState } from 'react';
//import { TextField, Grid, Button, Box, Typography } from '@material-ui/core';
import { Box, MobileStepper, Paper, Button, Grid, Typography } from '@material-ui/core';
//import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
//import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeText: {
    fontSize: '1.9rem',
    fontWeight: '300',
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      fontWeight: '400',
      lineHeight: 1.5,
    },
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  videoBox: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: '2rem',
  },
  title_secondary: {
    color: theme.palette.tertiary.main,
    fontSize: '2.25rem',
    fontWeight: 400,
    marginBottom: '1.2rem',
  },
  subtitle: {
    color: theme.palette.tertiary.main,
    fontSize: '28px',
    fontWeight: '400',
  },
  textBlock: {
    textAlign: 'justify',
  },
  carousel_btn: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.secondary.main,
    },
  }
}));

const Carousel = (props) => {
  const classes = useStyles();

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const style = props.style;  // "imgtext"|"img"
  const autoplay = props.autoplay; // true|false
  const images = props.images;

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>

      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={10000}
        autoplay={autoplay}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="generic"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                {style === "imgtext" &&
                  <Grid container>
                    <Grid item xs={4}>
                      <img src={step.imgPath} style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.textBlock} variant="caption" dangerouslySetInnerHTML={{ __html: step.label }}></Typography>
                    </Grid>
                  </Grid>
                }

                {style === "img" &&
                  <Grid container>
                    <Grid item xs={12}>
                      {step.imgPath &&
                        <img src={step.imgPath} style={{ width: '100%', aspectRatio: '16 / 9' }} alt={step.label} />
                      }
                      {step.videoPath &&
                        <iframe
                          style={{ width: '100%', aspectRatio: '16 / 9' }}
                          src={step.videoPath}
                          muted="true"
                          title="Recycling Reimagined"
                          frameborder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen="allowfullscreen"
                        ></iframe>
                      }
                    </Grid>
                  </Grid>
                }
              </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            className={classes.carousel_btn}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              '<'
            ) : (
              ' >'
            )}
          </Button>
        }
        backButton={
          <Button className={classes.carousel_btn} size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              '>'
            ) : (
              '< '
            )}
            Prev
          </Button>
        }
      /></>

  )
}

export default Carousel;
