import { ContactsOutlined } from '@material-ui/icons';
import { DateTime, Interval } from 'luxon';
import { RRule, RRuleSet } from 'rrule';

const now = DateTime.local();
const threeMonthDate = DateTime.local().plus({ months: 3 });
const zone = 'America/New_York';

/**
 * Formats the date to have a time of O hr, 0 min, 0 sec, 0 milli
 * @param {string} HTTP date format
 * @returns {object} luxon date object
 */

export const formatHTTP = (httpDate) => {
  const lux = DateTime.fromHTTP(httpDate).toUTC().startOf('day');
  return lux;
};

/**
 * Formats the date to have a time of O hr, 0 min, 0 sec, 0 milli
 * @param {DateTime} date date as a luxon DateTime object
 * @returns {string} javascript date string
 */

export const formatJSDate = (luxonDate) => {
  const lux = luxonDate.toJSDate();
  lux.setHours(0, 0, 0, 0);
  return lux;
};

/**
 * Formats date to luxon date object from ISO date
 * @param {string} ISO formatted date
 * @returns {object} luxon date object
 */

export const formatISO = (date) => {
  const lux = DateTime.fromISO(date);
  return lux;
};

/**
 * Returns todays date with a time of O hr, 0 min, 0 sec, 0 milli
 * @returns {DateTime} date date as a luxon DateTime object
 */

export const getCurrentDate = () => {
  let now = DateTime.local().toJSDate();
  now.setHours(0, 0, 0, 0);
  const luxZeroTime = DateTime.fromJSDate(now);

  return luxZeroTime;
};
const newDate = getCurrentDate();

/**
 * Checks if a date is less than 48 hours from current day
 * @param {DateTime} date date as a luxon DateTime object
 * @returns {Boolean} True or False
 */
export const checkIsLessThan48Hours = (date, type) => {
  const now = DateTime.local().startOf('day');
  const curbside = { hour: 18, minute: 0 };
  const bulk = { hour: 8, minute: 0 };
  const curbsidePicked = { hour: 18, minute: 1 };
  const bulkPicked = { hour: 8, minute: 1 };
  const time = type === 'curbside' ? curbside : bulk;
  const timePicked = type === 'curbsidePicked' ? curbsidePicked : bulkPicked;

  // current time and day in EST
  const current = DateTime.fromObject({
    zone,
  });

  // 24hrs from current day in EST with time set as cut off time
  let nextDay = current.plus({ days: 1 }).set(time);

  // find the next pickup day that is not less than 24hrs from current time
  const cutOff =
    current.plus({ days: 1 }) < nextDay
      ? nextDay
      : current.plus({ days: 2 }).set(time);

  // selected date with time set as cut off time for that type of pickup
  const dateEST = DateTime.fromISO(date).setZone(zone).set(timePicked);

  return dateEST < cutOff;
};

/**
 * Checks if a date falls on a specific weekday
 * @param {DateTime} date date as a luxon DateTime object
 * @param {Integer} weekdayNum  The weekday we want to check
 * @returns {Boolean} True or False if the days are the same
 */
export function isSameWeekday(weekdayNum, date) {
  return weekdayNum === date.weekday;
}

/**
 * Checks if a date exists in an rrule from an array of objects with rrules
 * @param {DateTime} date date as a luxon DateTime object
 * @param {array} days  array of rrules
 * @returns {Object} object with an exists key of true or false and availableDay key null or day info it exists in
 */
export const existsInRRule = (date, availableArray) => {
  let inRRule = {
    exists: false,
    availableDay: null,
  };
  // ensure the time is set to 0 for the selected day.
  const selectedDate = date.toUTC().startOf('day');

  for (let i = 0; i < availableArray.length; i++) {
    let availableDay = availableArray[i];

    // ensure the time is set to 0 for available day start date
    const luxDate = DateTime.fromISO(availableDay.startdate).startOf('day');

    // check that its the same day of the week. If true check to see if the day occurs with in that recurring available day rrule
    if (luxDate.weekday === selectedDate.weekday) {
      const before = availableDay.rrule.before(selectedDate.toJSDate(), true);
      const availableDate = DateTime.fromJSDate(before).toUTC().startOf('day');
      if (availableDate.hasSame(selectedDate, 'day')) {
        inRRule = { exists: true, availableDay };
      }
    }
  }
  return inRRule;
};

/**
 * Gets next rrule date
 * @param {Object} pickup info
 * @returns {DateTime} Luxon date of nearest date
 */
export const nearestRecurringDate = (pickup) => {
  const rrule = createRRule(pickup);
  const now = DateTime.local().startOf('day');

  // Get the nearest occurring date to use as start date
  const nearest = DateTime.fromJSDate(rrule.after(now.toJSDate()))
    .toUTC()
    .startOf('day');

  return nearest;
};

/**
 * Creates a RRuleSet with exdates if passed skip
 * @param {object} day object from backend
 * @param {Array} skip array of isodate formated skipped days from backend
 * @returns {RRuleSet}  rrulest with rrule and exdates if exist
 */
export const createRRule = (day, skip) => {
  const rruleSet = new RRuleSet();
  const lux = DateTime.fromISO(day.startdate).startOf('day');
  const event = new Date(lux);
  event.setHours(0, 0, 0, 0);
  const jsMonth = lux.month - 1;

  rruleSet.rrule(
    new RRule({
      freq: RRule[day.frequency],
      interval: day.interval,
      dtstart: new Date(Date.UTC(lux.year, jsMonth, lux.day)),
    })
  );

  if (skip) {
    for (let i = 0; i < skip.length; i++) {
      let skipDate = skip[i];
      const jsDate2 = DateTime.fromISO(skipDate).startOf('day');
      const jsDate = DateTime.fromISO(skipDate)
        .setZone('local', {
          keepLocalTime: true,
        })
        .startOf('day')
        .toJSDate();

      rruleSet.exdate(jsDate);
    }
  }

  return rruleSet;
};

/**
 * Creates three months of dates included in rruleset
 * @param {object}  rruleset
 * @returns {Array} array of luxon dates
 */
export function createThreeMonthDates(rrule) {
  const now = DateTime.local().startOf('day');

  const threeMonthDate = DateTime.local().startOf('day').plus({ months: 3 });

  // get upcoming dates for the next three months
  const upcomingDates = rrule
    .between(now.toJSDate(), threeMonthDate.toJSDate())
    .map((date) =>
      DateTime.fromJSDate(date)
        .toUTC()
        .setZone('local', { keepLocalTime: true })
        .toISODate()
    );
  return upcomingDates;
}

/**
 * Creates RRule for available days
 * @param {Array}  array of available pickups that the customer can subscribe to
 * @returns {Array} array of rruleset
 */
export const createAvailableDaysRRule = (days) => {
  const availableDays = [...days];
  const rrules = [];
  for (let i = 0; i < availableDays.length; i++) {
    const day = { ...availableDays[i] };
    day.rrule = createRRule(day);
    rrules.push(day);
  }
  return rrules;
};

/**
 * Creates RRule for curbside pickup
 * @param {Array}  array of available pickups that the customer can subscribe to
 * @returns {array} array of rrulesets
 */
export const createRecurringDaysRRule = (days, skipdays) => {
  const availableDays = [...days];
  const rrules = [];
  const skipDates = sortSkippedDates(skipdays);
  for (let i = 0; i < availableDays.length; i++) {
    const day = { ...availableDays[i] };
    const skip = skipDates[day.availablepickupdays_id]
      ? skipDates[day.availablepickupdays_id]
      : undefined;
    day.rrule = createRRule(day, skip);
    const threeMonthDates = createThreeMonthDates(day.rrule);
    day.threeMonthDates = threeMonthDates;
    rrules.push(day);
  }
  return rrules;
};

/**
 * Creates RRule for curbside pickup
 * @param {Array}  array of available pickups that the customer can subscribe to
 * @returns {array} array of rrulesets
 */
export const createRecurringDaysRRule2 = (days, skipdays) => {
  const availableDays = [...days];
  const rrules = [];
  // rrulesObj is used for forming confirmation dates info
  // To Do switch from using rrules array to object in all functions
  const rrulesObj = {};
  const skipDates = sortSkippedDates(skipdays);

  for (let i = 0; i < availableDays.length; i++) {
    const day = { ...availableDays[i] };
    const skip = skipDates[day.availablepickupdays_id]
      ? skipDates[day.availablepickupdays_id]
      : undefined;
    day.rrule = createRRule(day, skip);
    day.threeMonthDates = createThreeMonthDates(day.rrule);
    rrules.push(day);
    rrulesObj[day.id] = { ...day };
  }

  return [rrules, rrulesObj];
};

/**
 * Converts an integer representing DoW from 1-7(mon-sun) to 0-6(sun-sat)
 * @param {Integer} dayInt Integer from 1-7 representing DoW (mon-sun)
 * @returns {Integer} Returns an integer between 0-6 representing DoW (sun-sat)
 */
export function convertWeekday(dayInt) {
  return dayInt === 7 ? 0 : dayInt;
}

/**
 * Checks if a clicked date falls on a specific weekday
 * @param {Integer} requestedDay The weekday that we are interested in
 * @param {Integer} currWeekday  The weekday we want to check
 * @returns {Boolean} True or False if the days are the same
 */
// export function isSameWeekday(currWeekday, requestedDay) {
//   return convertWeekday(currWeekday) === convertWeekday(requestedDay);
// }

/**
 * Extracts the day, month and year from luxon DateTime object
 * @param {DateTime} date A luxon DateTime object
 * @returns {Array}       An array containing the day, month and year
 */
export function transformLuxonDate(date) {
  // date is luxon DateTime object
  if (!(date instanceof DateTime))
    throw new TypeError('Date must be luxon DateTime object.');
  const day = date.day;
  const month = date.month;
  const year = date.year;
  return [day, month, year];
}

/**
 * Extracts the day, month and year from a date string in the format dd/mm/yyyy
 * @param {String} dateString A date in the format 'dd/mm/yyyy'
 * @returns {Array}           An array containing the day, month and year
 */
export function transformDateString(dateString) {
  if (!dateString.includes('/'))
    throw new Error('Date must be in dd/mm/yyyy format.');
  const [day, month, year] = dateString.split('/');

  return [day, month, year];
}

/**
 * Generates an array of dates from a starting date
 * @param {DateTime} firstDate The starting date as a luxon DateTime object
 * @param {Number} count The amount of dates to create
 * @param {Number} frequency The number of days between each date
 * @returns {Array}       An array of luxon DateTime objects
 */
export function createDates(firstDate, count, frequency) {
  // firstDate is Luxon DateTime object
  if (!(firstDate instanceof DateTime))
    throw new TypeError('Date must be luxon DateTime object.');
  const datesArray = [];
  for (let i = 0; i < count; i++) {
    i === 0
      ? datesArray.push(firstDate)
      : datesArray.push(firstDate.plus({ days: i * frequency }));
  }
  return datesArray;
}

/**
 * Converts a day, month and year into dd/mm/yyyy for Flask backend
 * @param {DateTime} date date as a luxon DateTime object
 * @param {Integer} month  Integer representing month
 * @param {Integer} year Current year
 * @returns {String}  formatted date string for backend
 */
export function formatDateForBackend(day, month, year) {
  return `${day}/${month}/${year}`;
}

/**
 * Sorts skipped dates by pickuprequestsrecurring_id
 * @param {Array} skipped dates
 * @returns {Object}  sorted skipped dates
 */
export function sortSkippedDates(skipArray) {
  const sorted = {};
  for (let i = 0; i < skipArray.length; i++) {
    let id = skipArray[i].pickuprequestsrecurring_id;
    let date = skipArray[i].date;
    sorted[id] ? sorted[id].push(date) : (sorted[id] = [date]);
  }
  return sorted;
}

/**
 * Formats picks up to be confirmed/have been confirmed to be objects with date as the key
 * @param {Array} confirmations An array of pickups to be/have been confirmed
 * @param {Array} recurring An array of pickups to be/have been confirmed
 * @param {Array} single An array of pickups to be/have been confirmed
 * @return {Object} An object of dates to be/have been confirmed
 */

export function createConfirmations(confirmations, recurring, single, to_skip) {
  const confirmationDates = {};
  const singleObj = {};
  let nearest = {};
  let needConfirmation = false;
  if (single.length > 0) {
    // nearest = DateTime.fromISO(single[0].date);
    nearest.date = single[0].date;
    nearest.id = single[0].id;
    nearest.pickup = single[0];
    for (let i = 0; i < single.length; i++) {
      singleObj[single[i].id] = { ...single[i] };
    }
  }

  // get an array of all the recurring pickup dates
  const recurringPickups = recurring[1];
  let recurringDates = [];
  if (Object.keys(recurringPickups).length > 0) {
    for (const [key, value] of Object.entries(recurringPickups)) {
      let nearestDate = DateTime.fromISO(value.threeMonthDates[0]);

      if (single.length > 0 && nearestDate < DateTime.fromISO(single[0].date)) {
        nearest.date = value.threeMonthDates[0];
        nearest.id = value.id;
        nearest.pickup = value;
      }
      recurringDates = [...recurringDates, ...value.threeMonthDates];
    }
  }

  for (let i = 0; i < confirmations.length; i++) {
    const id = confirmations[i].pickup_id;

    // set need confirmation flag true if a pickup needs a confirmation
    if (!parseInt(confirmations[i].confirmed)) {
      /* To Do add check for current day */
      needConfirmation = true;
    }

    const date = confirmations[i].pickup_date;
    let dateInfo = {};

    if (recurringDates.includes(date) && !singleObj[id]) {
      dateInfo = { ...recurringPickups[id] };
      confirmationDates[id] = {
        id: confirmations[i].id,
        bulk: dateInfo.bulk,
        curbside: dateInfo.curbside,
        instructions: dateInfo.instructions,
        num_eco_krates: dateInfo.num_eco_krates,
        num_eco_sacks: dateInfo.num_eco_sacks,
        paymentoptions_id: dateInfo.paymentoptions_id,
        date: date,
        availablepickupdays_id: dateInfo.availablepickupdays_id,
        confirmed: confirmations[i].confirmed,
        pickup_id: id,
      };
    } else if (singleObj[id]) {
      dateInfo = { ...singleObj[id] };
      confirmationDates[id] = {
        id: confirmations[i].id,
        bulk: dateInfo.bulk,
        curbside: dateInfo.curbside,
        instructions: dateInfo.instructions,
        num_eco_krates: dateInfo.num_eco_krates,
        num_eco_sacks: dateInfo.num_eco_sacks,
        paymentoptions_id: dateInfo.paymentoptions_id,
        date: date,
        confirmed: confirmations[i].confirmed,
        pickup_id: id,
      };
    }
  }

  return { confirmationDates, needConfirmation, nearest };
}

/**
 * Formats all pickups
 * @param {Array} recurringPickups An array of recurring pickup requests
 * @return {Array} An array of FullCalendar Events
 */

export function formatPickups(data) {
  const { recurring, single, to_skip, confirmations } = data;
  const recurringPickups = createRecurringDaysRRule2(recurring, to_skip);
  const confirmationObj = createConfirmations(
    confirmations,
    recurringPickups,
    single,
    to_skip
  );

  const pickups = {
    confirmations: confirmationObj.confirmationDates,
    needConfirmation: confirmationObj.needConfirmation,
    recurring: recurringPickups[0],
    single,
    to_skip,
    nearest: confirmationObj.nearest,
  };

  return pickups;
}

/**
 * Checks if its been over 48hrs since dwolla sign up failed
 * @param {String} date in iso timestamp format year/mon/day
 * @returns {Boolean}
 */
export function checkDwollaTimestamp(timestamp) {
  const dwollaLuxon = formatISO(timestamp);
  const now = getCurrentDate();
  const diff = now.diff(dwollaLuxon, 'days').toObject();

  if (diff.days >= 3) {
    return true;
  } else {
    return false;
  }
}
