import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import { Box, Grid, Typography, Container } from '@material-ui/core';

// components
import EcoTitle from 'components/Typography/EcoTitle';

// assets
import ecoVision from 'assets/img/tech/recycletek-ecovision.png';
import ecoSort from 'assets/img/tech/recycletek-ecosort.png';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '600px',
  },
  ecoText: {
    textAlign: 'justify',
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

const EcoSystem = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} justify="center">
          <Grid
            item
            sm={12}
            md={6}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <img className={classes.image} src={ecoVision} alt="EcoVision" />
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Box className={classes.ecoText}>
              <EcoTitle
                regular={t('tech.eco')}
                bold={t('tech.vision')}
                variant="h3"
                color="primary"
              />
              <Typography variant="caption">
                {t('tech.ecoVisionBody')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container justify="center" spacing={3}>
          <Grid
            item
            sm={12}
            md={6}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Box className={classes.ecoText}>
              <EcoTitle
                regular={t('tech.eco')}
                bold={t('tech.sort')}
                variant="h3"
                color="primary"
              />
              <Typography variant="caption">{t('tech.ecoSortBody')}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <img className={classes.image} src={ecoSort} alt="EcoSort" />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default EcoSystem;
