import React from 'react';

// mui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Radio } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: '4px',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginBottom: '2px',
      marginRight: '2px',
    },
  },
  address: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(6),
  },
}));

export default function AddressOverviewOption({
  text,
  address,
  zipcode,
  selectOption,
  checked,
  recommendedAddress,
}) {
  const classes = useStyles();

  const handleSelected = () => {
    if (recommendedAddress) {
      selectOption('recommended');
    } else if (!recommendedAddress) {
      selectOption('original');
    }
  };

  return (
    <Box className={classes.container} onClick={handleSelected}>
      <Box className={classes.radio}>
        <Radio checked={checked} />
        <Typography>{text}</Typography>
      </Box>

      {!recommendedAddress && (
        <Box className={classes.address}>
          <Typography>
            {address.street} {address.apt && `#${address.apt}`}
          </Typography>
          <Typography>
            {address.city}, {address.state} {zipcode}
          </Typography>
        </Box>
      )}
      {recommendedAddress && (
        <Box className={classes.address}>
          <Typography>{recommendedAddress[0]}</Typography>
          <Typography>
            {recommendedAddress[1]}, {recommendedAddress[2]}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
