import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Redux
import { useDispatch } from 'react-redux';
import { checkPromo } from 'redux/actions/auth';
import { ALERT_SUCCESS, ALERT_FAIL } from 'redux/actions/types.js';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button } from '@material-ui/core';

// components
import PortalTextField from 'components/TextField/PortalTextField';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

// styles
const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '250px',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
  },
  button: {
    '&:disabled': {
      backgroundColor: theme.palette.grey.dark,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const validationSchema = yup.object({
  promoCode: yup.string().required('Please enter promotional code'),
});

export default function PromotionForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      promoCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { promoCode } = values;
      dispatch(checkPromo(promoCode))
        .then((res) => {
          dispatch({
            type: ALERT_SUCCESS,
            payload: t('signup.form.thanksForInterest'),
          });
          props.handleSetPromo(true);
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
          resetForm();
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.incorrectCode'),
          });
        });
    },
  });

  return (
    <div>
      <ScrollToTop />
      <Box mt={5}>
        <Box mb={3}>
          <Typography className={classes.title}>
            {t('signup.form.ourMemberPortalOnlyAvailToCustomer')}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <PortalTextField
                className={classes.input}
                fullWidth
                id="promoCode"
                name="promoCode"
                placeholder={t('signup.form.promotionalCode')}
                value={formik.values.promoCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.promoCode && Boolean(formik.errors.promoCode)
                }
                helperText={formik.touched.promoCode && formik.errors.promoCode}
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {t('buttons.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

PromotionForm.propTypes = {
  handleSetPromo: PropTypes.func,
};
