import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography, Box } from '@material-ui/core';

import recycletekLogo from '../../assets/img/logo/recycletek_logo_mono_gray.png';
import SocialIcons from '../SocialIcons';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey.main,
    zIndex: theme.zIndex.drawer + 1,
    paddingBottom: theme.spacing(2),
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      textAlign: 'center',
    },
  },
  sectionLeft: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  sectionCenter: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& img': {
      maxWidth: '15rem',
      height: 'auto',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& img': {
        maxWidth: '8rem',
        height: 'auto',
        marginBottom: theme.spacing(0),
      },
    },
  },
  sectionRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(0),
      justifyContent: 'center',
      paddingBottom: theme.spacing(4),
      order: '-1',
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  addressInfo: {
    fontWeight: 300,
    lineHeight: '1.3',
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: 300,
    },
  },
  follow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  copyRight: {
    fontSize: '1rem',
  },
  footer: {
    textAlign: 'center',
  },
  policyLink: {
    marginLeft: theme.spacing(1),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const additionalLinks = [
    {
      text: 'footer.contact',
      path: '/contact',
    },
    {
      text: 'footer.investorRelations',
      path: '/investor',
    },
  ];

  const Address = ({ title, info }) => {
    return (
      <>
        <Typography className={classes.title}>{t(title)}</Typography>
        <Typography className={classes.addressInfo}>
          <Trans>{t(info)}</Trans>
        </Typography>
      </>
    );
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.main}>
        <Box className={classes.sectionLeft}>
          <Address title="footer.newYork" info="footer.contactInfoNY" />
          <Address title="footer.california" info="footer.contactInfoCA" />
        </Box>
        <Box className={classes.sectionCenter}>
          <img src={recycletekLogo}></img>
          <Box>
            <Typography className={classes.follow}>
              {t('footer.followUs')}
            </Typography>
            <SocialIcons />
          </Box>
        </Box>
        <Box className={classes.sectionRight}>
          <Box>
            <Typography className={classes.title}>
              {t('footer.additionalLinks')}
            </Typography>
            {additionalLinks.map((link) => (
              <NavLink exact to={link.path} className={classes.link}>
                <Typography>{t(link.text)}</Typography>
              </NavLink>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Typography className={classes.copyRight} variant="caption">
          &copy; {1900 + new Date().getYear()} {t('footer.legal')}{' '}
          <NavLink
            exact
            to="/terms-and-conditions"
            className={`${classes.link} ${classes.policyLink}`}
          >
            {t('footer.termsAndConditions')}
          </NavLink>{' '}
          <NavLink
            exact
            to="/privacy-policy"
            className={`${classes.link} ${classes.policyLink}`}
          >
            {t('footer.privacyPolicy')}
          </NavLink>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
