import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocationCode } from '../../redux/actions/auth';
import { useTranslation } from 'react-i18next';
import {
  SET_CUSTOMER_STATECODE,
  SET_CUSTOMER_SERVICES,
  SET_CUSTOMER_STATECOORDS,
} from '../../redux/actions/types';
import { locations, locationServices, stateCoords } from './locations';
import { makeStyles } from '@material-ui/core/styles';

import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStylesInput = makeStyles((theme) => ({
  margin: {
    paddingRight: theme.spacing(7),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
}));

const GeoLocator = ({ isOpen, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const USState = useSelector((state) => state.auth.customer?.state);
  const customerLocation = useSelector(
    (state) => state.customer?.location.stateCode,
  );
  const [open, setOpen] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const classes = useStylesInput();

  // when dashboard or component first loads and geolocator opens automatically
  useEffect(() => {
    !customerLocation && isLocationNeeded();
  }, []);

  // when the geolocator is opened manually to adjust the selected state
  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  const handleClose = (event, reason) => {
    isOpen && close();
    reason !== 'backdropClick' && setOpen(false);
  };

  const setValidUSState = (stateCode) => {
    dispatch({
      type: SET_CUSTOMER_STATECODE,
      payload: stateCode,
    });
    dispatch({
      type: SET_CUSTOMER_SERVICES,
      payload: locationServices[stateCode],
    });
    dispatch({
      type: SET_CUSTOMER_STATECOORDS,
      payload: stateCoords[stateCode],
    });
    handleClose();
  };

  const getLocation = () => {
    const successCallback = async (position) => {
      const state = await getLocationCode(
        position.coords.latitude,
        position.coords.longitude,
      );
      const stateCode = state.slice(-2);
      const countryCode = state.slice(0, 2);
      const validUSState =
        countryCode === 'US' && locations.includes(stateCode);

      return validUSState ? setValidUSState(stateCode) : setOpen(true);
    };

    const errorCallback = () => {
      return setOpen(true);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  const isLocationNeeded = () => {
    const locationNotServiced = USState && !locations.includes(USState);
    const locationUnknown = !USState;
    const locationServiced = locations.includes(USState);

    if (locationNotServiced) {
      setOpen(true);
    } else if (locationUnknown) {
      getLocation();
    } else if (locationServiced) {
      setValidUSState(USState);
    }
  };

  const handleSelectState = (event) => {
    setSelectedState(event.target.value);
    setValidUSState(event.target.value);
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {t('selectLocation.header')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('selectLocation.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormControl fullWidth className={classes.margin}>
            <InputLabel id="state-label">
              {t('selectLocation.state')}
            </InputLabel>
            <Select
              labelId="select-state"
              id="select-state"
              value={selectedState}
              onChange={handleSelectState}
            >
              {locations.map((location, index) => (
                <MenuItem value={location} key={index}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeoLocator;
