import React from 'react';
import clsx from 'clsx';

// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
} from '@material-ui/core';

// @material-ui/icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { flexibleCompare } from '@fullcalendar/react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '400px',
    borderRadius: 15,
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  header: {
    width: '100%',
    backgroundColor: (props) => props.background,
    color: (props) => props.color,
    padding: '5px 5px',
  },
  headerRoot: { margin: 0 },
  headerTitle: {
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  expandButton: {
    color: (props) => props.iconColor,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandButtonRoot: {
    padding: 0,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  icon: {
    fontSize: theme.typography.pxToRem(50),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  button: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(18),
    color: (props) => props.color,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

function MemberPortalCard(props) {
  const { title } = props;
  const classes = useStyles(props);
  const [expanded, setExpanded] = React.useState(props.open);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  React.useEffect(() => {
    setExpanded(props.open);
  }, [props.open]);

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        classes={{
          action: classes.headerRoot,
          title: classes.headerTitle,
        }}
        title={
          <div
            tabIndex="0"
            role="button"
            onClick={props.handleOpen ? props.handleOpen : handleExpandClick}
            className={classes.button}
          >
            <IconButton
              className={clsx(classes.expand, classes.expandButton, {
                [classes.expandOpen]: expanded,
              })}
              classes={{ root: classes.expandButtonRoot }}
              // onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ArrowDropDownIcon className={classes.icon} />
            </IconButton>
            {title}
          </div>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{props.children}</CardContent>
      </Collapse>
    </Card>
  );
}

export default MemberPortalCard;

MemberPortalCard.propTypes = {
  children: PropTypes.node,
};
