import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, shallowEqual } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  infoContainer: {
    marginTop: theme.spacing(2),
  },
}));

function CalendarHeader(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const service = useSelector(
    (state) => state.pickups.serviceType,
    shallowEqual,
  );
  const available = useSelector(
    (state) => state.customer.location.services.curbside,
  );
  const pickups = useSelector((state) => state.pickups, shallowEqual);

  return (
    <Box mt={3}>
      <Typography variant="h3" color="primary">
        {t('schedule.header.pickup')}
        <span className={classes.name}> {t('schedule.header.scheduler')}</span>
      </Typography>
      {service.bulk && available ? (
        <Typography className={classes.subtitle}>
          {service.curbside && t('schedule.header.yourServiceAreaCurbsideBulk')}
          {!service.curbside && t('schedule.header.yourServiceAreaBulk')}
        </Typography>
      ) : (
        <Typography className={classes.subtitle}>
          {t('schedule.header.yourServiceAreaNotAvailable')}
        </Typography>
      )}
      <Grid className={classes.infoContainer} container spacing={3}></Grid>
    </Box>
  );
}

export default CalendarHeader;
