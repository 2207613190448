import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  createSkipRequest,
  deletePickup,
  confirmPickup,
} from 'redux/actions/pickups';

// date libraries
import { DateTime } from 'luxon';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  pickup: {
    color: theme.palette.primary.main,
  },
  title: {
    color: (props) =>
      props.pickup.bulk
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    fontWeight: 300,
  },
  info: {
    fontWeight: 300,
  },
  details: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300,
  },
  detailButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  confirmButton: {
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 10px',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 10px',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
}));

function ConfirmPickupItem(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(props);
  const { pickup } = props;
  const paymentOptions = useSelector(
    (state) => state.transactions.paymentOptions,
    shallowEqual,
  );
  const [date, setDate] = React.useState('');
  const [paymentType, setPaymentType] = React.useState({});

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen(!open);
  };
  const [disabled, setDisabled] = React.useState(false);

  const handleDelete = (e) => {
    setDisabled(true);
    if (!pickup.availablepickupdays_id) {
      dispatch(deletePickup(pickup.date, 'single_pickup'));
      setDisabled(false);
    } else {
      const skipDetails = {
        date: pickup.date,
        pickuprequestsrecurring_id: pickup.availablepickupdays_id,
      };
      dispatch(createSkipRequest(skipDetails));
      setDisabled(false);
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setDisabled(true);
    const confirmation_id = e.target.value;
    dispatch(confirmPickup({ confirmation_id }));
    setDisabled(false);
  };

  React.useEffect(() => {
    // Format the date to be more readable "Thur, 4/19"
    const readableDate = DateTime.fromISO(props.pickup.date).toLocaleString({
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
    });
    setDate(readableDate);

    let payment = [];
    if (pickup.bulk || pickup.curbside) {
      // Find the payment info for that payment id
      payment = paymentOptions.filter(
        (option) => option.id === props.pickup.paymentoptions_id,
      );
    }

    setPaymentType(...payment);
  }, [props.pickup]);

  return (
    <React.Fragment>
      <ListItem classes={{ root: classes.listRoot }}>
        <Box>
          <Typography color="primary">{date}</Typography>
          {!pickup.bulk && !pickup.curbside ? null : (
            <div>
              <Box>
                <button className={classes.detailButton} onClick={handleClick}>
                  {t('dashboard.details')}
                  {open ? <ExpandLess /> : <ExpandMore />}
                </button>
              </Box>
            </div>
          )}
        </Box>
        {pickup.confirmed === '0' ? (
          <Box className={classes.buttonBox}>
            <button
              className={classes.cancelButton}
              onClick={handleDelete}
              disabled={disabled}
            >
              {t('dashboard.cancel')}
            </button>
            <button
              value={pickup.id}
              className={classes.confirmButton}
              onClick={handleConfirm}
              disabled={disabled}
            >
              {t('dashboard.confirm')}
            </button>
          </Box>
        ) : (
          <Box>
            <Typography color="primary">
              {t('dashboard.confirmCaps')}
            </Typography>
          </Box>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box pl={3} display="flex">
          <Typography>{t('dashboard.pickupType')}</Typography>
          <Box pl={2}>
            <Typography className={classes.title}>
              {pickup.bulk
                ? t('dashboard.bulkPickup')
                : t('dashboard.curbsidePickup')}
            </Typography>
          </Box>
        </Box>
        {/* <Typography className={classes.title}></Typography> */}
        <Box pt={1} pl={3} display="flex">
          <Typography>{t('dashboard.ecoSacks')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {' '}
              {pickup.num_eco_sacks}
            </Typography>
          </Box>
        </Box>
        <Box pl={3} display="flex">
          <Typography>{t('dashboard.ecoKrates')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {' '}
              {pickup.num_eco_krates}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Typography>{t('dashboard.paymentMethod')}</Typography>
          <Box pl={2}>
            <Typography className={classes.info}>
              {paymentType && paymentType.name !== 'Cash'
                ? `${t('dashboard.donation')} ${paymentType.name}`
                : t('dashboard.addToBalance')}
            </Typography>
          </Box>
        </Box>
        <Box pt={1} pl={3}>
          <Typography>{t('dashboard.instructions')}</Typography>
          <Box pl={2} mb={1}>
            <Typography className={classes.info}>
              {pickup.instructions === ''
                ? t('dashboard.notApplicable')
                : `${pickup.instructions}`}
            </Typography>
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default ConfirmPickupItem;
