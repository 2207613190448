import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-verification-code-input';
import { useFormik } from 'formik';

// Redux
import { phoneVerification } from 'redux/actions/verification';
import { useSelector, useDispatch } from 'react-redux';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Typography,
  Button,
  FormHelperText,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import Verified from '../Section/Verified';

// components
import { usePreventWindowUnload } from '../../../utils/hooks/usePreventWindowUnload';

// styles
const useStyles = makeStyles((theme) => ({
  step: {
    padding: '8px 25px',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(21),
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 400,
    maxWidth: '400px',
    paddingLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: '10px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  number: {
    fontWeight: 500,
  },
  numberInput: {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
    '& input': {
      '&:focus': {
        borderColor: theme.palette.tertiary.main,
        caretColor: theme.palette.tertiary.main,
      },
    },
  },
  subtext: {
    textAlign: 'center',
    maxWidth: '300px',
    margin: '0 auto',
    fontSize: theme.typography.pxToRem(14),
  },
  resendButton: {
    border: 'none',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    color: theme.palette.tertiary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function PhoneVerificationForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // Captcha
  const { phone_verified } = useSelector((state) => state.verification);

  usePreventWindowUnload(!phone_verified && true);

  const formik = useFormik({
    initialValues: {
      verification_code: '',
    },
    // validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      dispatch(phoneVerification(values.verification_code));
    },
  });

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" justifyContent="center">
        <PhoneIcon color="primary" />
        <Typography className={classes.title}>
          {t('signup.form.phoneVerification')}
        </Typography>
      </Box>
      {!phone_verified ? (
        <form onSubmit={formik.handleSubmit}>
          <Grid container justify="center" className={classes.form} spacing={1}>
            <Grid item>
              <ReactCodeInput
                id="verification_code"
                name="email verification code"
                type="number"
                autoFocus={false}
                fields={4}
                className={classes.numberInput}
                onChange={(e) => formik.setFieldValue('verification_code', e)}
              />
              <FormHelperText
                error={
                  formik.touched.ver_code && Boolean(formik.errors.ver_code)
                }
              >
                {formik.touched.ver_code && formik.errors.ver_code}
              </FormHelperText>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                {t('buttons.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Verified name={t('signup.form.phoneNumber')} />
      )}
    </React.Fragment>
  );
}

PhoneVerificationForm.propTypes = {
  handleChange: PropTypes.func,
  handleServiceAreaCheck: PropTypes.func,
};
