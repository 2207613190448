import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  CardContent,
  Card,
} from '@material-ui/core';
// @material-ui/icons
import Edit from '@material-ui/icons/Edit';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import AccountPayPalSignupForm from './AccountPayPalSignupForm';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    borderRadius: 10,
    boxShadow: theme.shadows[25],
    padding: theme.spacing(2, 2, 3),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
  },
  content: {
    flex: '1',
  },
  input: {
    marginBottom: theme.spacing(1),
    transition: 'all .3s ease-out',
    paddingLeft: '7px',
    width: 'auto',
    '&..MuiSelect-icon': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      marginBottom: 0,
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  },
  sectionText: {
    paddingLeft: '7px',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  button: {
    cursor: 'pointer',
    display: 'inline-flex',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  form: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  paypalImage: {
    height: '14px',
  },
  paypal: {
    marginLeft: '10px',
    backgroundColor: '#ffc439',
    padding: '0px 10px 0px 10px',
    borderRadius: '200px',
  },
}));

export default function PayPalEmailForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const pickups = useSelector((state) => state.pickups, shallowEqual);
  const [edit, setEdit] = React.useState(true);

  const handleEdit = () => {
    setEdit((prevState) => !prevState);
  };

  const handleCancel = () => {
    formik.handleReset();
    handleEdit();
  };

  const { paypal_email } = props.validation;

  const validationSchema = yup.object({
    paypal_email,
  });

  const formik = useFormik({
    initialValues: {
      paypal_email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(updateCustomer(values));
      setEdit(true);
    },
  });
  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Typography className={classes.title}>
          {t('account.form.payPalTransferEmail')}
          <span className={classes.paypal}>
            <img
              className={classes.paypalImage}
              src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png"
              border="0"
              alt="PayPal Logo"
            />
          </span>
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Box className={classes.content}>
            {!props.customer.paypal_email ? (
              <AccountPayPalSignupForm
                validation={props.validation}
                customer={props.customer}
              />
            ) : (
              <Box>
                {edit ? (
                  <div className={classes.sectionText}>
                    <Typography>{props.customer.paypal_email}</Typography>
                  </div>
                ) : (
                  <Box>
                    <PortalInputLabel
                      classes={{ root: classes.label }}
                      id="paypal_email"
                    >
                      {t('account.form.payPalEmail')}
                    </PortalInputLabel>
                    <PortalTextFieldNoShadow
                      fullWidth
                      type="text"
                      id="paypal_email"
                      name="paypal_email"
                      placeholder="Paypal Email"
                      value={formik.values.paypal_email}
                      onChange={formik.handleChange}
                      disabled={edit}
                      error={
                        formik.touched.paypal_email &&
                        Boolean(formik.errors.paypal_email)
                      }
                      helperText={
                        formik.touched.paypal_email &&
                        formik.errors.paypal_email
                      }
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {props.customer.paypal_email ? (
            <Box className={classes.buttons}>
              <Box py={2}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                {edit ? (
                  <Box
                    className={classes.editButton}
                    display="flex"
                    alignItems="center"
                  >
                    <button className={classes.button} onClick={handleEdit}>
                      <Edit className={classes.icon} fontSize="small" />
                      <span>{t('account.form.updateEmail')}</span>
                    </button>
                  </Box>
                ) : null}
                {edit ? null : (
                  <div>
                    <button className={classes.button} onClick={handleCancel}>
                      {t('buttons.cancel')}
                    </button>
                    <button className={classes.button} type="submit">
                      {t('buttons.submit')}
                    </button>
                  </div>
                )}
              </Box>
            </Box>
          ) : null}
        </form>
      </Card>
    </React.Fragment>
  );
}
