import { DateTime } from 'luxon';
import {
  ADD_TRANSACTION,
  CONFIRM_DWOLLA,
  SET_DWOLLA,
  PROCESSING_TRANSACTION,
  SET_TRANSACTION_INFO,
  SET_PAYMENTOPTIONS,
  SET_TRANSFER_MESSAGE,
  RESET_DWOLLA,
  RESET_TRANSACTIONS,
  SET_PAYMENT_DATA,
} from 'redux/actions/types';

const initialState = {
  account_balance: 0,
  latest_deposit: 0,
  transactionHistory: [],
  paymentOptions: [],
  paymentData: {},
  processing: false,
  transferMessage: '',
  dwolla: {
    customer_id: null,
    dwolla_funding: null,
    dwolla_verified: false,
    id: null,
    failure_timestamp: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_TRANSACTION:
      return {
        transactionHistory: [
          {
            date: DateTime.local().toLocaleString(),
            amount: action.payload,
          },
          ...state.transactionHistory,
        ],
        processing: false,
      };
    case PROCESSING_TRANSACTION:
      return {
        ...state,
        processing: action.payload,
      };
    case SET_TRANSACTION_INFO:
      return {
        ...state,
        processing: false,
        transactionHistory: [...action.payload.transactionHistory],
        account_balance: action.payload.account_balance,
        latest_deposit: action.payload.latest_deposit,
      };
    case SET_DWOLLA:
      return {
        ...state,
        processing: false,
        dwolla: {
          ...action.payload,
        },
      };
    case CONFIRM_DWOLLA:
      return {
        ...state,
        processing: false,
        dwolla: {
          customer_id: null,
          dwolla_funding: null,
          dwolla_verified: true,
          id: null,
          failure_timestamp: null,
        },
      };
    case RESET_DWOLLA:
      return {
        ...state,
        processing: false,
        dwolla: {
          customer_id: null,
          dwolla_funding: null,
          dwolla_verified: false,
          id: null,
          failure_timestamp: null,
        },
      };
    case SET_TRANSFER_MESSAGE:
      return {
        ...state,
        transferMessage: action.payload,
      };
    case SET_PAYMENTOPTIONS:
      return {
        ...state,
        paymentOptions: [...action.payload.paymentOptions],
      };
    case SET_PAYMENT_DATA:
      return {
        ...state,
        paymentData: action.payload,
      };
    case RESET_TRANSACTIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
