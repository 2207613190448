// lib
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Redux
import { getAchievements } from '../../../../redux/actions/customer';

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

// assets
import BasicCard from 'components/Card/BasicCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 15,
    boxShadow: theme.shadows[25],
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  progressSection: {
    width: '80%',
    marginTop: theme.spacing(3),
  },
  cardTitle: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(21),
  },
  icon: {
    width: '60%',
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
  badges: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 300,
  },
  badge: {
    color: theme.palette.primary.contrastText,
    borderRadius: '8px',
    padding: '8px 6px 8px 6px',
    textAlign: 'center',
    maxWidth: '180px',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.grey.main,
  },
}));

const ProgressBar = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 12,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey.main,
  },
  bar: {
    borderRadius: 5,
    background:
      'linear-gradient(90deg, rgba(0,142,170,1) 24%, rgba(151,215,0,1) 81%)',
  },
}))(LinearProgress);

function Achievements(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const achievements = useSelector((state) => state.customer.achievements);

  useEffect(() => {
    dispatch(getAchievements());
  }, []);

  const ProgressBadge = ({ label, color, value, dataCy }) => {
    const badgeClassnames = clsx({
      [classes.badge]: true,
      [classes.primary]: color === 'primary',
      [classes.secondary]: color === 'secondary',
    });

    return (
      <Box className={classes.badgeContainer}>
        <Typography className={classes.label} color="primary" variant="body1">
          {label}
        </Typography>
        <Box className={badgeClassnames} data-cy={dataCy}>
          <Typography variant="h5">{value}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <BasicCard>
      <Box p={3}>
        <Box mb={2}>
          <Typography className={classes.cardTitle} color="primary">
            {t('metrics.achievements')}
          </Typography>
        </Box>
        {achievements && (
          <Box className={classes.container}>
            <img
              className={classes.icon}
              src={`data:image/png;charset=ascii;base64,${achievements.badge_image}`}
              data-cy="achievements-badge-img"
            />
            <Box className={classes.progressSection}>
              <Typography color="primary" variant="h6">
                {achievements.title}
              </Typography>
              <ProgressBar
                variant="determinate"
                value={Number(achievements.percent_of_next)}
              />
              <Box className={classes.badges}>
                <ProgressBadge
                  label={t('metrics.totalCarbonPoints')}
                  value={
                    achievements.current_points &&
                    achievements.current_points.toLocaleString()
                  }
                  color="primary"
                  dataCy="achievements-carbon-total"
                />
                <ProgressBadge
                  label={t('metrics.untilNext')}
                  value={achievements.until_next}
                  color="secondary"
                  dataCy="achievements-carbon-next"
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </BasicCard>
  );
}

export default Achievements;
