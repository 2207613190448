import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

import { ALERT_FAIL, ALERT_SUCCESS } from 'redux/actions/types';
import { resetPassword } from 'redux/actions/auth';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// Material UI components
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.tertiary.main,
    fontWeight: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

const ResetStartForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, handleClose } = props;

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email or phonenumber')
      .required('Email or Phonenumber is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(resetPassword(values))
        .then((res) => {
          dispatch({
            type: ALERT_SUCCESS,
            payload:
              'If an account with that email or phonenumber exists a reset link will be sent shortly.',
          });
          handleDialogClose();
        })
        .catch((err) => {
          if (err.response.status === 403) {
            dispatch({
              type: ALERT_FAIL,
              payload: `${err.response.data.msg}, please try again`,
            });
            handleDialogClose();
          } else {
            dispatch({
              type: ALERT_FAIL,
              payload:
                'There was an error resetting your account. Please refresh and try again.',
            });
            handleDialogClose();
          }
        });
    },
  });

  const handleDialogClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box mb={1}>
            <Typography className={classes.title}>
              Reset Your Password
            </Typography>
          </Box>
          <Box mb={3}>
            <DialogContentText className={classes.text}>
              Please enter your email or phone number and if an associated
              account exists we wil send you a message with a reset link.
            </DialogContentText>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email or Phonenumber"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={classes.button} type="submit">
            Reset Password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResetStartForm;

ResetStartForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  zipcode: PropTypes.string,
};
