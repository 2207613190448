import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Box, Grid } from '@material-ui/core';
import BasicAccountInfo from './Sections/BasicAccountInfo';

// assets
import balanceIcon from 'assets/img/portal/account_icons_balance.svg';
import paymentIcon from 'assets/img/portal/account_icons_payment.svg';

// utils
import { formatMoney } from 'utils/formatTransactions';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 400,
  },
  text: {
    fontWeight: 300,
    maxWidth: '465px',
    textAlign: 'justify',
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  headerTitle: {
    marginBottom: theme.spacing(2),
  },
  headerContent: {},
}));

function EcoWallet(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const transactions = useSelector((state) => state.transactions);
  const [account_balance, setAccount_balance] = React.useState('');
  const [latest_deposit, setLatest_deposit] = React.useState('');

  React.useEffect(() => {
    const { account_balance, latest_deposit } = transactions;
    setAccount_balance(account_balance);
    setLatest_deposit(latest_deposit);
  }, [transactions]);

  return (
    <React.Fragment>
      <Box mt={3} className={classes.headerTitle}>
        <Typography variant="h3" color="primary">
          {t('common.eco')}
          <span className={classes.bold}>{t('common.wallet')}</span>
        </Typography>
      </Box>
      <div className={classes.headerContent}>
        <Grid container spacing={matches ? 2 : 5}>
          <Grid item className={classes.info}>
            <BasicAccountInfo
              icon={balanceIcon}
              background={'#EFF9D7'}
              name={t('ecowallet.accountBalance')}
              info={formatMoney(account_balance)}
            />
          </Grid>
          {latest_deposit.total_redemption_amount > 0 && (
            <Grid item className={classes.info}>
              <BasicAccountInfo
                icon={paymentIcon}
                background={'#E2ECF0'}
                name={t('ecowallet.latestRedemptionDeposit')}
                info={formatMoney(latest_deposit.total_redemption_amount)}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default EcoWallet;
