import React from 'react';
import { useTranslation } from 'react-i18next';

// redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Avatar } from '@material-ui/core';
import { formatMoney } from 'utils/formatTransactions';

// assets
import balanceIcon from 'assets/img/portal/account_icons_balance.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    width: '45px',
  },
  circle: {
    backgroundColor: '#EFF9D7',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
  },
  info: {
    fontSize: theme.typography.pxToRem(14),
  },
  balance: {
    margin: '0 auto',
  },
}));

function AccountInfoIcon(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const { account_balance } = useSelector((state) => state.transactions);
  return (
    <div className={classes.root}>
      <Box
        className={classes.balance}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Avatar
          className={classes.iconAvatar}
          classes={{ colorDefault: classes.circle }}
        >
          <img className={classes.icon} src={balanceIcon} alt="Balance" />
        </Avatar>
        {props.addBalance && (
          <React.Fragment>
            <Typography className={classes.title}>
              {t('ecowallet.accoutBalance')}
            </Typography>
            <Typography className={classes.info}>
              {formatMoney(account_balance)}
            </Typography>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}

export default AccountInfoIcon;
