import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  Typography,
  Tooltip,
  Badge,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Box,
} from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  list: {
    // padding: theme.spacing(1.5)
    margin: 0,
    paddingLeft: '12px',
  },
  link: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  helpLink: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '-11px',
    top: '-7px',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: theme.palette.primary.main,
  },
}))(Badge);

export default function PickupTypeForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Typography>{t('schedule.pickupForm.choosePickupType')}</Typography>
        <Link className={classes.helpLink} to="/portal/faq">
          {t('schedule.needHelp')}
        </Link>
      </Box>
      <FormControl
        component="fieldset"
        error={props.error}
        className={classes.formControl}
      >
        <RadioGroup
          aria-label="type"
          name="type"
          value={props.value}
          onChange={props.handleTypeChange}
        >
          {/* {pickups.serviceType.curbside ? ( */}
          <FormControlLabel
            value="curbside"
            control={<Radio />}
            label={
              <React.Fragment>
                <FormLabel>{t('schedule.pickupForm.curbside')}</FormLabel>
                <HtmlTooltip
                  enterTouchDelay={0}
                  interactive
                  arrow
                  onClick={(e) => e.preventDefault()}
                  title={
                    <React.Fragment>
                      <ul className={classes.list}>
                        <li>{t('schedule.pickupForm.curbsideHelp.p1')}</li>
                        <li>{t('schedule.pickupForm.curbsideHelp.p2')}</li>
                        <li>
                          {t('schedule.pickupForm.curbsideHelp.p3')}{' '}
                          <NavLink
                            exact
                            to="/portal/locations"
                            className={classes.link}
                          >
                            {t('schedule.pickupForm.curbsideHelp.p4')}
                          </NavLink>
                          )
                        </li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <StyledBadge badgeContent={'?'} color="secondary" />
                </HtmlTooltip>
              </React.Fragment>
            }
          />
          {/* ) : null} */}
          <FormControlLabel
            value="bulk"
            control={<Radio />}
            label={
              <React.Fragment>
                <FormLabel className={classes.formLabel}>
                  {t('schedule.pickupForm.bulk')}
                </FormLabel>
                <HtmlTooltip
                  enterTouchDelay={0}
                  arrow
                  onClick={(e) => e.preventDefault()}
                  title={
                    <React.Fragment>
                      <ul className={classes.list}>
                        <li>{t('schedule.pickupForm.bulkHelp.p1')}</li>
                        <li>{t('schedule.pickupForm.bulkHelp.p2')}</li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <StyledBadge badgeContent={'?'} color="secondary" />
                </HtmlTooltip>
              </React.Fragment>
            }
          />
        </RadioGroup>
        <FormHelperText>
          {props.error ? 'Please choose a pickup type' : ''}
        </FormHelperText>
      </FormControl>
    </React.Fragment>
  );
}

PickupTypeForm.propTypes = {
  handleTypeChange: PropTypes.func,
  error: PropTypes.bool,
  value: PropTypes.string,
};
