import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { SERVER_URL } from 'config.js';
import { ALERT_FAIL, ALERT_SUCCESS } from 'redux/actions/types';

// mui
import { TextField, Grid, Button, Box, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  recipient: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  address: {
    marginLeft: theme.spacing(2),
    border: '1px solid rgb(121, 119, 119, 0.5)',
    borderRadius: '33px',
    padding: '0px 8px 0px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      fontWeight: 300,
    },
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(1),
      fontSize: '17px',
      marginTop: '1px',
      cursor: 'pointer',
      opacity: '0.8',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  select: {
    cursor: 'pointer',
    opacity: '0.8',
    '&:hover': {
      opacity: '1',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
}));

const ContactForm = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedEmail, setSelectedEmail] = useState(props.emailAddress);

  const translations = {
    enterName: t('contact.form.enterYourName'),
    nameRequired: t('contact.form.nameIsRequired'),
    enterEmail: t('contact.form.enterYourEmail'),
    pleaseFormatEmail: t('contact.form.pleaseFormatEmail'),
    emailIsRequired: t('contact.form.emailIsRequired'),
    enterMessage: t('contact.form.enterMessage'),
    messageRequired: t('contact.form.messageRequired'),
  };
  const validationSchema = yup.object({
    name: yup
      .string(translations.enterName)
      .required(translations.nameRequired),
    email: yup
      .string(translations.enterYourEmail)
      .email(translations.pleaseFormatEmail)
      .required(translations.emailIsRequired),
    message: yup
      .string(translations.enterMessage)
      .required(translations.messageRequired),
  });

  const formik = useFormik({
    initialValues: {
      to: selectedEmail || 'info@recycletek.co',
      name: '',
      email: '',
      message: '',
    },
    // enableReinitialize: false,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      handleRequest(values, actions);
    },
  });

  const handleRequest = (values, actions) => {
    axios
      .post(`${SERVER_URL}/contact/`, values)
      .then((res) => {
        actions.resetForm({
          name: '',
          email: '',
          message: '',
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('contact.form.successMsg'),
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: ALERT_FAIL,
            payload: t('contact.form.errorMsg'),
          });
        }
      });
  };

  const removeEmail = () => {
    setSelectedEmail('');
    formik.values.to = '';
  };

  const addEmail = (value) => {
    setSelectedEmail(value);
    formik.values.to = value;
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.recipient}>
          <Typography>
            {selectedEmail
              ? t('contact.form.to')
              : t('contact.form.pleaseSelect')}
          </Typography>
          {selectedEmail && (
            <Box className={classes.address}>
              <Typography>{selectedEmail}</Typography>
              <CancelIcon onClick={removeEmail} />
            </Box>
          )}
          {!selectedEmail && (
            <>
              {props.options.map((option) => (
                <Box
                  className={`${classes.address} ${classes.select}`}
                  onClick={() => addEmail(option.value)}
                >
                  <Typography>{option.text}</Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="name"
              name="name"
              label={t('contact.form.name')}
              type="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              id="email"
              name="email"
              label={t('contact.form.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              id="message"
              name="message"
              label={t('contact.form.message')}
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button type="submit">{t('buttons.send')}</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;
