import {
  SET_CAPTCHA,
  DELETE_CAPTCHA,
  SET_VERIFYING,
  SET_VERIFY,
  SET_PHONE,
  SET_EMAIL,
  DELETE_VERIFICATION,
} from 'redux/actions/types';

const initialState = {
  images: [],
  question: '',
  id: '',
  verifying: false,
  id_email_auth: '',
  id_phone_number_auth: '',
  email_verified: false,
  phone_verified: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CAPTCHA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_VERIFYING:
      return {
        ...state,
        verifying: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email_verified: action.payload,
      };
    case SET_PHONE:
      return {
        ...state,
        phone_verified: action.payload,
      };
    case SET_VERIFY:
      return {
        ...state,
        id_email_auth: action.payload.id_email_auth,
        id_phone_number_auth: action.payload.id_phone_number_auth,
      };
    case DELETE_VERIFICATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
