import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

// components
import Pin from './Pin';
import LocationCard from './LocationCard';

const useStyles = makeStyles((theme) => ({
  default: {
    minWidth: 'max-content',
    position: 'absolute',
    zIndex: 20,
    padding: theme.spacing(2),
    top: '5px',
    [theme.breakpoints.down('300px')]: {
      minWidth: '100px',
    },
  },
  small: {
    minWidth: '300px',
    position: 'absolute',
    padding: theme.spacing(1),
    top: -140,
    left: -100,
    zIndex: 112,
  },
}));

function Marker({ lat, lng, $hover, ecocenter, popoverSize, data }) {
  const classes = useStyles();
  const [status, setStatus] = useState('');

  const card = clsx({
    [classes.default]: popoverSize !== 'small',
    [classes.small]: popoverSize === 'small',
  });

  useEffect(() => {
    data && isLocationAvailable();
  }, []);

  const getTimestamp = (date, time) => {
    const timestamp = Math.floor(new Date(`${date} ${time}`) / 1000);

    return timestamp;
  };

  const isLocationOpen = () => {
    const currentDateTime = new Date().toLocaleString('en-US', {
      timeZone: data.timezone,
      hour12: false,
    });
    const weekDay = new Date().getDay();
    const currentDate = currentDateTime.split(',')[0];
    const currentTime = currentDateTime.split(',')[1];
    const openTime = data.hours[weekDay]?.[0];
    const closeTime = data.hours[weekDay]?.[1];

    const currentTimestamp = getTimestamp(currentDate, currentTime);
    const openTimestamp = getTimestamp(currentDate, openTime);
    const closeTimestamp = getTimestamp(currentDate, closeTime);

    // check if current timestamp in seconds occurs after the open time and close time
    if (currentTimestamp > openTimestamp && currentTimestamp < closeTimestamp) {
      setStatus('open');
    } else {
      setStatus('closed');
    }
  };

  const isLocationAvailable = () => {
    if (!data.available) {
      setStatus('coming-soon');
    } else if (data.available && data.hours) {
      isLocationOpen();
    }
  };

  return (
    <>
      <Pin status={status} />
      {$hover && data ? (
        <Paper className={card}>
          <LocationCard ecocenter={ecocenter} size={popoverSize} />
        </Paper>
      ) : null}
    </>
  );
}

export default Marker;
