import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    minWidth: 'max-content',
    maxWidth: 'max-content',
    height: 'max-content',
    boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '81px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
  breakHeading: {
    maxWidth: '235px',
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiTypography-root': {
      fontWeight: '300',
    },
    '& .MuiListItemText-primary': {
      fontSize: '1rem',
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
  reducedPadding: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

export default function InfoCard(props) {
  const classes = useStyles();

  const heading = clsx({
    [classes.heading]: true,
    [classes.breakHeading]: props.breakHeading,
  });

  const content = clsx({
    [classes.content]: true,
    [classes.reducedPadding]: props.reducedPadding,
  });

  return (
    <Box className={classes.container} style={props.style}>
      <Box className={classes.header}>
        <Box className={heading}>
          <Typography variant="body2">{props.heading}</Typography>
        </Box>
      </Box>
      <Box className={content}>{props.children}</Box>
    </Box>
  );
}
