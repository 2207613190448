import React from 'react';

// @material-ui
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const CustomList = ({ points }) => {
  return (
    <List dense>
      {points.map((point) => (
        <ListItem>
          <ListItemIcon>
            <CheckCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText variant="h4" primary={point} />
        </ListItem>
      ))}
    </List>
  );
};

export default CustomList;
