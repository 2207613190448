import React from 'react';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Box, Typography, Avatar } from '@material-ui/core';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '550px',
    backgroundColor: theme.palette.teal.main,
    borderRadius: 20,
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(21),
    paddingLeft: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.yellow.main,
  },
  navLink: {
    fontWeight: 700,
    fontSize: 'inherit',
    letterSpacing: '.8px',
    color: theme.palette.yellow.main,
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: theme.palette.primary.main,
      background: 'inherit',
    },
  },
}));

function ShippingCallout(props) {
  const classes = useStyles(props);

  return (
    <Box mb={2}>
      <Card className={classes.root}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <Avatar
              className={classes.iconAvatar}
              classes={{ colorDefault: classes.icon }}
            >
              <LocalShippingIcon fontSize="large" />
            </Avatar>
            <Typography className={classes.title}>
              Orders ship for only $1.00 with any scheduled pickup
            </Typography>
          </Box>
          <Box px={3} className={classes.text}>
            <Box pb={2}>
              <Typography>
                It looks like you don't have any upcoming pickups.{` `}
                <NavLink
                  exact
                  to="/portal/schedule"
                  className={classes.navLink}
                >
                  Schedule
                </NavLink>
                {` `}a pickup now to receive the option for $1.00 delivery.
              </Typography>
            </Box>
            <Box>
              <Typography>
                Questions? Reach us through our {` `}
                <NavLink exact to="/contact" className={classes.navLink}>
                  contact form
                </NavLink>
                .
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ShippingCallout;
