import React, { useEffect } from 'react';

export const usePreventWindowUnload = (isMessageNeeded) => {
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (isMessageNeeded) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, []);
};
