import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// components
import BasicCard from 'components/Card/BasicCard';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    width: '100%',
    zIndex: '500',
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    backdropFilter: 'blur(3px)',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(8),
    borderRadius: 15,
    boxShadow: theme.shadows[25],
    height: '10rem',
    width: '28rem',
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
  },
}));

function OutOfService({ service }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box className={classes.container}>
      <Box className={classes.main}>
        <ErrorOutlineIcon fontSize="large" />
        <Typography variant="h5">
          {t('common.sorryOutOfService', { service: service })}{' '}
        </Typography>
      </Box>
    </Box>
  );
}

export default OutOfService;
