// list of available locations that Recycletek will serve
export const locations = ['NY', 'CA'];

// list of services that each state has available
export const locationServices = {
  ['NY']: {
    curbside: false, // customers have their containers picked up near their home/business
    dropOff: true, // customers go to the recycling facility and use the Kiosk machine
    quickDrop: true, // customers go to the recycling facility and drop off containers for an employee to sort
    driveThrough: false, // customers go to the recycling facility and have an employee take their containers from their car, requires booking
  },
  ['CA']: {
    curbside: false,
    dropOff: true,
    quickDrop: true,
    driveThrough: false,
  },
};

export const stateCoords = {
  ['NY']: {
    lat: 41.7806,
    lng: -74.535242,
    zoom: 7,
  },
  ['CA']: {
    lat: 36.0391,
    lng: -118.3568,
    zoom: 6,
  },
};
