import React from 'react';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { computeOrder } from 'redux/actions/shop';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Hidden } from '@material-ui/core';

// Components
import ItemCard from './Sections/ItemCard';
import CartCard from './Sections/CartCard';
import ShoppingCartPopOver from './Sections/ShoppingCartPopOver';
import ProgressSpinner from '../../../components/Progress/ProgressSpinner';

// utils
// import CheckOut from './Sections/CheckOut';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    maxWidth: '1400px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 100px',
      maxWidth: '1500px',
    },
  },
  products: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  spinner: {
    position: 'absolute',
  },
}));

function EcoSupplies(props) {
  const classes = useStyles();
  const { products } = useSelector((state) => state.store, shallowEqual);
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);

  const handleCheckout = () => {
    if (cart.length === 0) {
      return;
    }
    const order_list = cart.map((product) => ({
      product_id: product.id,
      count: product.amount,
    }));

    dispatch(computeOrder(order_list)).catch((error) => console.log(error));
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={4} alignContent="flex-start">
        <React.Fragment>
          <Grid item xs={10} sm={6} md={8} className={classes.products}>
            {products.length > 0 &&
              products.map((product) => (
                <Box key={product.id} mx={2} mb={2}>
                  <ItemCard product={product} />
                </Box>
              ))}
            {products.length <= 0 && (
              <Box className={classes.spinner}>
                <ProgressSpinner text={'Loading Products...'} />
              </Box>
            )}
          </Grid>
          <Hidden xsDown>
            <Grid item sm={6} md={4}>
              <CartCard handleCheckout={handleCheckout} />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={2} style={{ padding: 0 }}>
              <Box mt={1}>
                <ShoppingCartPopOver handleCheckout={handleCheckout} />
              </Box>
            </Grid>
          </Hidden>
        </React.Fragment>
      </Grid>
    </div>
  );
}

export default EcoSupplies;
