import React from 'react';

// Redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { placeOrder, deleteItemLocalStorage } from 'redux/actions/shop';
import { ALERT_SUCCESS, ALERT_FAIL } from 'redux/actions/types';

// paypal checkout buttons
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { paypal_client_id } from 'config';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Button,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { SERVER_URL } from 'config';
import { formatMoney } from 'utils/formatTransactions';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  detail: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.tertiary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  button: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cancelButton: {
    marginRight: 0,
    marginTop: '1rem',
    textTransform: 'none',
  },
  evtekBalance: {
    fontSize: '0.875rem',
    textAlign: 'center',
  },
}));

function CheckoutPayments(props) {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const customer = useSelector((state) => state.auth.customer, shallowEqual);
  const token = useSelector((state) => state.auth.access_token, shallowEqual);
  const { checkoutOptions } = useSelector((state) => state.store);
  const { cart } = useSelector((state) => state.cart);
  const evtekBalance = useSelector(
    (state) => state.transactions.account_balance
  );
  const [open, setOpen] = React.useState(true);
  const [amount, setAmount] = React.useState({});
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleCheckout = () => {
    if (cart.length === 0) {
      dispatch({
        type: ALERT_FAIL,
        payload: 'Please place items in your cart.',
      });
    }
    const order_list = cart.map((product) => ({
      product_id: product.id,
      count: product.amount,
    }));
    dispatch(placeOrder({ order_list, ...props.shipInfo }))
      .then((res) => {
        dispatch(deleteItemLocalStorage(customer.id));
        setAmount({});
        props.handleComplete();
      })
      .catch((err) => {
        dispatch({
          type: ALERT_FAIL,
          payload: 'Your order could not be placed at this time.',
        });
      });
  };

  // If the users Evtek account balance is less than the grand total, the "Use Evtek Balance" button will be disabled.
  React.useEffect(() => {
    if (checkoutOptions.positions) {
      const total = checkoutOptions.positions.total.amount_dollars;
      const grandTotal = total * 100;
      if (evtekBalance >= grandTotal) {
        setIsButtonDisabled(false);
      } else if (evtekBalance === 0 || evtekBalance < grandTotal) {
        setIsButtonDisabled(true);
      }
    }
  }, [checkoutOptions]);

  const initialOptions = {
    'client-id': paypal_client_id,
    currency: 'USD',
    intent: 'capture',
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <React.Fragment>
        <ListItem
          classes={{ root: classes.listRoot }}
          button
          onClick={handleClick}
        >
          <ListItemText className={classes.pickup}>
            <Typography color="primary" className={classes.title}>
              Payment Options
            </Typography>
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box mt={2} className={classes.action}>
            <PayPalButtons
              style={{
                layout: 'vertical',
              }}
              createOrder={(data, actions) => {
                if (cart.length === 0) {
                  return;
                }
                const order_list = cart.map((product) => ({
                  product_id: product.id,
                  count: product.amount,
                }));
                const body = JSON.stringify({ order_list, ...props.shipInfo });
                return fetch(`${SERVER_URL}/shop/paypal_place_order`, {
                  method: 'POST',
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body,
                })
                  .then(function (res) {
                    return res.json();
                  })
                  .then(function (data) {
                    return data.order_id; // Use the key sent by your server's response, ex. 'id' or 'token'
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch({
                      type: ALERT_FAIL,
                      payload:
                        'There was an error processing your order. Please refresh and try again.',
                    });
                  });
              }}
              onApprove={(data, actions) => {
                if (cart.length === 0) {
                  return;
                }
                const order_list = cart.map((product) => ({
                  product_id: product.id,
                  count: product.amount,
                }));
                return fetch(`${SERVER_URL}/shop/paypal_authorize_payment`, {
                  method: 'POST',
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    order_id: data.orderID,
                    order_list,
                    ...props.shipInfo,
                  }),
                })
                  .then(function (res) {
                    return res.json().then((body) => {
                      if (res.status === 201) {
                        return body;
                      } else {
                        let err = new Error('HTTP status code: ' + res.status);
                        err.response = body;
                        err.status = res.status;
                        throw err;
                      }
                    });
                  })
                  .then(function (details) {
                    dispatch({
                      type: ALERT_SUCCESS,
                      payload: 'Your order has been placed!',
                    });
                    setAmount({});
                    dispatch(deleteItemLocalStorage(customer.id));
                    props.handleRedirect();
                  })
                  .catch((err) => {
                    if (
                      err.status === 400 &&
                      err.response.order_data.name === 'INSTRUMENT_DECLINED'
                    ) {
                      return actions.restart();
                    } else {
                      dispatch({
                        type: ALERT_FAIL,
                        payload:
                          'There was an error processing your order. Please refresh and try again.',
                      });
                    }
                  });
              }}
            />
            <Box mb={2}>
              <Button
                className={classes.confirmButton}
                classes={{ root: classes.button }}
                onClick={handleCheckout}
                color="secondary"
                variant="contained"
                disabled={isButtonDisabled}
              >
                Use Recycletek Balance
              </Button>
              <Typography className={classes.evtekBalance}>
              Recycletek Balance: {formatMoney(evtekBalance)}
              </Typography>
            </Box>
          </Box>
        </Collapse>
        <Divider />
      </React.Fragment>
    </PayPalScriptProvider>
  );
}

export default CheckoutPayments;
