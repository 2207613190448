import React from 'react';
import clsx from 'clsx';

// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 15,
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

function BasicCard(props) {
  const classes = useStyles(props);

  return <Card className={classes.root} style={{ backgroundColor: props.backgroundColor }}>{props.children}</Card>;
}

export default BasicCard;

BasicCard.propTypes = {
  children: PropTypes.node,
};
