import {
  CREATE_PICK_UPS,
  CREATE_RECURRING_PICKUPS,
  GET_PICK_UPS,
  ALERT_FAIL,
  ALERT_SUCCESS,
  DELETE_PICK_UPS,
  CREATE_SKIP_REQUEST,
  SET_AVAILABLE_PICKUP_DAYS,
} from './types';

import { formatPickups } from 'utils/dateFunctions.js';

import { formatEvents } from 'utils/eventFunctions';

import axios from 'axios';
import { configureConfigHeader } from 'utils/utils';

import { SERVER_URL } from 'config.js';
import { DateTime } from 'luxon';
import { t } from 'i18next';

export const getUserPickups = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  if (config.headers.Authorization) {
    return axios
      .get(`${SERVER_URL}/calendar/`, config)
      .then((res) => {
        const pickups = formatPickups(res.data);
        const events = formatEvents(res.data);
        dispatch({
          type: GET_PICK_UPS,
          payload: { ...pickups, ...events },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const getAvailablePickupDays = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  if (config.headers.Authorization) {
    return axios
      .get(`${SERVER_URL}/recurring_pickup/available_pickup_days`, config)
      .then((res) => {
        dispatch({
          type: SET_AVAILABLE_PICKUP_DAYS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const createSinglePickUpRequest =
  (pickUpRequest) => (dispatch, getState) => {
    const config = configureConfigHeader(getState);

    // might need to change the pickuprequest
    if (config.headers.Authorization) {
      const body = JSON.stringify(pickUpRequest);
      axios
        .post(`${SERVER_URL}/single_pickup/`, body, config)
        .then((res) => {
          const pickups = formatPickups(res.data);
          const events = formatEvents(res.data);
          dispatch({
            type: CREATE_PICK_UPS,
            payload: { ...pickups, ...events },
          });
          dispatch({
            type: ALERT_SUCCESS,
            payload: t('actions.pickups.successfullyCreatedRequest'),
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: ALERT_FAIL,
            payload: t('actions.pickups.errorCreatingRequest'),
          });
        });
    }
  };

export const createRecurringPickUpRequest =
  (pickUpRequest) => (dispatch, getState) => {
    const config = configureConfigHeader(getState);

    // might need to change the pickuprequest
    if (config.headers.Authorization) {
      const body = JSON.stringify(pickUpRequest);
      axios
        .post(`${SERVER_URL}/recurring_pickup/`, body, config)
        .then((res) => {
          const pickups = formatPickups(res.data);
          const events = formatEvents(res.data);
          dispatch({
            type: CREATE_RECURRING_PICKUPS,
            payload: { ...pickups, ...events },
          });
          dispatch({
            type: ALERT_SUCCESS,
            payload: t('actions.pickups.successfullyCreatedRequest'),
          });
        })
        .catch((error) => {
          console.log(error.response);
          dispatch({
            type: ALERT_FAIL,
            payload: t('actions.pickups.errorCreatingRequest'),
          });
        });
    }
  };

export const createCustomRecurringPickUpRequest =
  (pickUpRequest) => (dispatch, getState) => {
    const config = configureConfigHeader(getState);

    // might need to change the pickuprequest
    if (config.headers.Authorization) {
      const body = JSON.stringify(pickUpRequest);
      return axios.post(
        `${SERVER_URL}/recurring_pickup/request_custom_pickup`,
        body,
        config,
      );
    }
  };

export const createSkipRequest = (pickUpRequest) => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  // might need to change the pickuprequest
  if (config.headers.Authorization) {
    const body = JSON.stringify(pickUpRequest);
    axios
      .post(`${SERVER_URL}/skip_pickup/`, body, config)
      .then((res) => {
        const pickups = formatPickups(res.data);
        const events = formatEvents(res.data);
        dispatch({
          type: CREATE_SKIP_REQUEST,
          payload: { ...pickups, ...events },
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('actions.pickups.successfullyAddedSkipRequest'),
        });
      })
      .catch((err) => {
        dispatch({
          type: ALERT_FAIL,
          payload: t('actions.pickups.errorCreatingRequest'),
        });
      });
  }
};

export const confirmPickup = (confirmation_id) => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  const body = JSON.stringify(confirmation_id);
  if (config.headers.Authorization) {
    axios
      .post(`${SERVER_URL}/calendar/confirm`, body, config)
      .then((res) => {
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('actions.pickups.confirmedPickup'),
        });
        dispatch(getUserPickups());
      })
      .catch((err) => {
        dispatch({
          type: ALERT_FAIL,
          payload: t('actions.pickups.errorCreatingRequest'),
        });
      });
  }
};

export const deletePickup = (date, request_url) => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  // might need to change the pickuprequest
  if (config.headers.Authorization) {
    axios
      .delete(
        `${SERVER_URL}/${request_url}/${encodeURIComponent(
          encodeURIComponent(date),
        )}`,
        config,
      )
      .then((res) => {
        const pickups = formatPickups(res.data);
        const events = formatEvents(res.data);
        dispatch({
          type: DELETE_PICK_UPS,
          payload: { ...pickups, ...events },
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('actions.pickups.successfullyDeletedRequest', {
            dateAndTime: DateTime.fromISO(date).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY,
            ),
          }),
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ALERT_FAIL,
          payload: t('actions.pickups.errorCreatingRequest'),
        });
      });
  }
};

export const deleteRecurringPickup =
  (availablepickupdays_id) => (dispatch, getState) => {
    const token = getState().auth.access_token;
    // might need to change the availablepickupdays_id
    const config = configureConfigHeader(getState);
    axios
      .delete(
        `${SERVER_URL}/recurring_pickup/${encodeURIComponent(
          encodeURIComponent(availablepickupdays_id),
        )}`,
        config,
      )
      .then((res) => {
        dispatch(getUserPickups());
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('actions.pickups.successfullyDeletedRecurringPickup'),
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ALERT_FAIL,
          payload: t('actions.pickups.errorDeletingPickup'),
        });
      });
  };
