import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import Legend from './Legend';
// lib
import { serviceLocations } from 'lib/locations';

const LocationsMap = ({ center, zoom, height, popoverSize }) => {
  const handleApiLoaded = (map, maps) => {
    const legend = document.getElementById('legend');
    map.controls[maps.ControlPosition.LEFT_TOP].push(legend);
  };

  return (
    <div style={{ height: `${height}`, width: '100%' }} data-cy="locations-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyADxFGc2Gec58t95waF6bcpgiU5tpPyuGM',
        }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        hoverDistance={20}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        <Legend size={popoverSize} />
        {serviceLocations.map((location, index) => (
          <Marker
            key={index}
            lat={location.lat}
            lng={location.lng}
            ecocenter={location.info}
            data={location.data}
            popoverSize={popoverSize}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default LocationsMap;
