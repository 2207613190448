import { OPEN_SIDEBAR } from '../actions/types.js'

const initialState = {
  sidebarOpen: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      }
    default:
      return state
  }
}
