import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginTop: '2px',
    marginRight: theme.spacing(1),
    marginLeft: '-30px',
  },
  dropoffTitle: {
    marginBottom: '5px',
  },
  dropoffSubtitle: {
    fontWeight: 500,
    fontSize: '1.25rem',
    marginBottom: '1rem',
  },
  dropoffText: {
    fontSize: '1.2rem',
    fontWeight: 300,
    width: '100%',
  },
}));

const ServiceInfo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.title && (
        <Box className={classes.title}>
          <Box className={classes.icon}>{props.icon}</Box>
          <Typography
            color="primary"
            variant="h6"
            className={classes.dropoffTitle}
          >
            {props.title}
          </Typography>
        </Box>
      )}
      {props.subtitle && (
        <Typography className={classes.dropoffSubtitle}>
          {props.subtitle}
        </Typography>
      )}
      {props.textBody && (
        <Typography className={classes.dropoffText}>
          {props.textBody}
        </Typography>
      )}
    </div>
  );
};

export default ServiceInfo;

ServiceInfo.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textBody: PropTypes.string,
};
