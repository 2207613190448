import React from 'react';

// Redux
import { useSelector, shallowEqual } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';

// components
import DatamatrixCard from './Sections/DatamatrixCard';
import AnnouncementCard from './Sections/AnnouncementCard';
import BagScannerCard from './Sections/BagScannerCard';
// import DashboardConfirmationCard from './Sections/DashboardConfirmationCard';
// import DashboardCalendar from './Sections/DashboardCalendar';
import Percentage from '../Metrics/Sections/Percentage';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    maxWidth: '1400px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 100px',
      maxWidth: '1500px',
    },
  },
  name: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  main: {
    paddingRight: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  announcement: {},
  smallcard: {
    flexDirection: 'column',
  },
}));

export default function Dashboard(props) {
  const pickups = useSelector((state) => state.pickups, shallowEqual);
  const services = useSelector((state) => state.customer.location.services);
  const customer = useSelector((state) => state.auth.customer);
  const classes = useStyles();

  return (
      <div className={classes.container}>
        <Grid container spacing={3} justify="center">

            <Grid item xs={12} sm={8} md={6}>
              <DatamatrixCard />
            </Grid>


              <Grid item xs={12} sm={8} md={6}>
                <BagScannerCard />
              </Grid>


            <Grid item xs={12} sm={8} md={6}>
              <AnnouncementCard />
            </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <Percentage />
              </Grid>


           {/* Pickups have been suspended as of May 2024
          {customer.zipcode && services.curbside && (
            <Grid
              item
              container
              spacing={3}
              xs={12}
              sm={4}
              direction="row"
              justifyContent="center"
              alignContent="flex-start"
            >
              <Grid item xs={12} sm={12}>
                {pickups.needConfirmation && <DashboardConfirmationCard />}
              </Grid>

              <Grid item xs={12} sm={12}>
                <DashboardCalendar />
              </Grid>
            </Grid>
          )}
          */}

        </Grid>
      </div>
  );
}
