import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, List, Typography } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import theme from 'themes/memberPortalTheme';

// components
import MemberPortalCard from 'components/Card/MemberPortalCard';
import PickupListItem from './Sections/PickupListItem';
import RecurringListItem from './Sections/RecurringListItem';
import { SchedulePickupForm } from './Forms/SchedulePickupForm';
import ConfirmPickup from './Sections/ConfirmPickup';
import Calendar from '../../../components/Calendar/Calendar';
import BusinessPickup from './Sections/BusinessPickup';
import DropOffOnlyCard from './Sections/DropOffOnlyCard';
import BusinessPickupForm from './Forms/BusinessPickupForm';
import KioskAccount from './Sections/KioskAccount';
import OutOfService from 'components/OutOfService/OutOfService';
import { getCharities } from 'redux/actions/charities';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    maxWidth: '1400px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 100px',
      maxWidth: '1500px',
    },
  },
  col1: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  col2: {
    order: 1,
    paddingLeft: 0,
    paddingBottom: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingBottom: 0,
      order: 2,
    },
  },
  calendarCard: {
    padding: theme.spacing(1),
    borderRadius: 20,
    boxShadow: theme.shadows[25],
    maxWidth: '900px',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  name: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  section: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
  noPickup: {
    fontWeight: 300,
  },
}));

export default function Schedule(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pickups = useSelector((state) => state.pickups, shallowEqual);
  const customer = useSelector((state) => state.auth.customer, shallowEqual);
  const { sidebarOpen } = useSelector((state) => state.sidebar);
  const charities = useSelector((state) => state.charities.list);
  const available = useSelector(
    (state) => state.customer.location.services.curbside,
  );
  const [scheduled, setScheduled] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    !charities.length && dispatch(getCharities());
  }, [charities]);

  // const { ...rest } = props;
  const calendarRef = React.createRef();
  useEffect(() => {
    handleUpdateSize();
  }, [sidebarOpen]);

  useEffect(() => {
    if (
      pickups.recurringPickupDays.length === 0 &&
      pickups.singlePickupDays.length === 0
    ) {
      setScheduled(true);
    }
  }, [pickups]);

  // Causes FullCalendar to resize the calendar after the sidebar collapses
  const handleUpdateSize = () => {
    setTimeout(() => {
      if (calendarRef.current !== null) {
        calendarRef.current.getApi().updateSize();
      }
    }, 300);
  };

  const handleOpenScheduled = () => {
    setScheduled((prev) => !prev);
  };
  return (
    <>
      <div className={classes.container}>
        {!available && <OutOfService service={t('schedule.pickupScheduler')} />}
        <Grid container justify="flex-start">
          {!pickups.serviceType && (
            <Grid item sm={12} md={8}>
              <Box mb={3}>
                <DropOffOnlyCard />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container justify="flex-start">
          <Grid item xs={12} sm={12} md={7} lg={7} className={classes.col1}>
            <Calendar
              handleOpenScheduled={handleOpenScheduled}
              scheduled={scheduled}
            />
          </Grid>
          <Grid
            item
            container
            sm={12}
            md={5}
            lg={5}
            direction="column"
            className={classes.col2}
          >
            {pickups.serviceType.bulk === 'na' &&
            pickups.serviceType.curbside === 'na' ? (
              <Grid item>
                <KioskAccount />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item>
                  <Box mb={2.5} data-cy="schedule-pickup-dropdown">
                    <div
                      data-cy={
                        scheduled
                          ? 'schedule-dropdown-open'
                          : 'schedule-dropdown-closed'
                      }
                    >
                      <MemberPortalCard
                        open={scheduled}
                        handleOpen={handleOpenScheduled}
                        background={`${theme.palette.primary.main}`}
                        color={`${theme.palette.primary.contrastText}`}
                        iconColor={`${theme.palette.secondary.main}`}
                        title={t('schedule.pickupForm.scheduleAPickup')}
                      >
                        <SchedulePickupForm />
                      </MemberPortalCard>
                    </div>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2.5}>
                    <MemberPortalCard
                      open={pickups.needConfirmation}
                      background={`${theme.palette.secondary.main}`}
                      color={`${theme.palette.primary.contrastText}`}
                      iconColor={`${theme.palette.primary.contrastText}`}
                      title={t('schedule.confirmPickups')}
                    >
                      <Box>
                        <ConfirmPickup />
                      </Box>
                    </MemberPortalCard>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2.5}>
                    <MemberPortalCard
                      open={false}
                      background={`${theme.palette.aqua.main}`}
                      color={`${theme.palette.primary.contrastText}`}
                      iconColor={`${theme.palette.primary.contrastText}`}
                      title={t('schedule.scheduledOneTimePickups')}
                    >
                      <Box>
                        <div data-cy="scheduled-onetime-wrapper">
                          <List>
                            {pickups && pickups.singlePickupDays.length > 0 ? (
                              pickups.singlePickupDays.map((pickup, index) => (
                                <PickupListItem
                                  key={index}
                                  pickup={pickup}
                                  type="single_pickup"
                                />
                              ))
                            ) : (
                              <Typography className={classes.noPickup}>
                                {t('schedule.noOneTimePickups')}
                              </Typography>
                            )}
                          </List>
                        </div>
                      </Box>
                    </MemberPortalCard>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2.5}>
                    <MemberPortalCard
                      open={false}
                      background={`${theme.palette.yellow.main}`}
                      color={`${theme.palette.primary.contrastText}`}
                      iconColor={`${theme.palette.primary.contrastText}`}
                      title={t('schedule.scheduledRecurringPickups')}
                    >
                      <div data-cy="scheduled-recurring-wrapper">
                        <List>
                          {pickups && pickups.recurringPickupDays.length > 0 ? (
                            pickups.recurringPickupDays.map((pickup, index) => (
                              <RecurringListItem
                                key={index}
                                pickup={pickup}
                                type="recurring_pickup"
                              />
                            ))
                          ) : (
                            <Typography className={classes.noPickup}>
                              {t('schedule.noRecurringPickups')}
                            </Typography>
                          )}
                        </List>
                      </div>
                    </MemberPortalCard>
                  </Box>
                </Grid>
                {customer.type === 1 && (
                  <Grid item>
                    <Box mb={2.5}>
                      <BusinessPickup />
                    </Box>
                    <BusinessPickupForm open={false} />
                  </Grid>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
