import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

// components
import LocationCard from 'components/Map/LocationCard';
import LocationsMap from 'components/Map/LocationsMap';

// redux
import { getCustomerCoords } from 'redux/actions/customer';
import { SET_CUSTOMER_COORDINATES } from 'redux/actions/types';

// lib
import { serviceLocations } from 'lib/locations';

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: theme.spacing(4),
  },
  container: {
    maxHeight: '100vh',
  },
  list: {
    height: '100vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: {
    padding: theme.spacing(3),
    cursor: 'pointer',
    transition: '200ms',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

function Locations(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.auth.customer);
  const userCoords = useSelector((state) => state.customer.location.coords);
  const stateCode = useSelector((state) => state.customer.location.stateCode);
  const [defaultCenter, setDefaultCenter] = useState({});
  const [loading, setLoading] = useState(true);
  const [defaultZoom, setDefaultZoom] = useState(12);
  const [serviceLocationsByState, setServiceLocationsByState] =
    useState(serviceLocations);
  const errCoords = {
    // center of USA if no location can be set
    lat: 32,
    lon: -98,
  };

  useEffect(() => {
    sortByState();
  }, [stateCode]);

  useEffect(() => {
    setLoading(true);
    getUserCoords();
  }, [userCoords]);

  // takes in coords and zoom
  const centerMap = (latitude, longitude, zoom) => {
    setDefaultCenter({
      lat: latitude,
      lng: longitude,
    });
    setDefaultZoom(zoom || defaultZoom);
    setLoading(false);
  };

  const getFallbackLocation = () => {
    const successCallback = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      dispatch({
        type: SET_CUSTOMER_COORDINATES,
        payload: {
          lat: latitude,
          lon: longitude,
        },
      });

      centerMap(latitude, longitude);
    };

    const errorCallback = () => {
      centerMap(errCoords.lat, errCoords.lon, 5);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  const getUserCoords = () => {
    const address = {
      house: customer.housenumber,
      street: customer.street,
      city: customer.city,
      state: customer.state,
      zipcode: customer.zipcode,
    };

    const validAddress =
      address.house && address.street && address.city && address.state;

    if (userCoords.lat && userCoords.lon) {
      centerMap(userCoords.lat, userCoords.lon);
    } else if (validAddress) {
      dispatch(getCustomerCoords(address, getFallbackLocation));
    } else {
      getFallbackLocation();
    }
  };

  const sortByState = () => {
    const sorted = [];

    serviceLocations.map((location) => {
      if (location.data.stateCode === stateCode) {
        sorted.unshift(location); // add to start of array
      } else {
        sorted.push(location); // add to end
      }
    });

    setServiceLocationsByState(sorted);
  };

  return (
    <>
      {loading && (
        <Box className={classes.loading} data-cy="loading-map-text">
          <Typography>{t('locations.loadingMap')}</Typography>
        </Box>
      )}
      {!loading && (
        <Grid className={classes.container} container>
          <Grid className={classes.list} item xs={12} sm={3}>
            {serviceLocationsByState.map((location, index) => (
              <Box
                className={classes.item}
                key={index}
                onClick={() => centerMap(location.lat, location.lng)}
              >
                <LocationCard key={index} ecocenter={location.info} />
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} sm={9}>
            <LocationsMap
              height={'100vh'}
              zoom={defaultZoom}
              center={[Number(defaultCenter.lat), Number(defaultCenter.lng)]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Locations;
