import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { getTenantList } from 'redux/actions/verification';
import { SET_ASSOCIATED_REDEMPTION_CENTER } from 'redux/actions/types';

// mui
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
  },
  form: {
    paddingRight: theme.spacing(1),
  },
  buttonGroup: {
    marginTop: theme.spacing(10),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function SelectATenantWindow({ hideTenantSelect }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTenant, setSelectedTenant] = useState('');
  const [tenantList, setTenantList] = useState(null);

  useEffect(() => {
    dispatch(getTenantList()).then((res) => {
      setTenantList(res);
    });
  }, []);

  const handleSelectTenant = (event) => {
    setSelectedTenant(event.target.value);
  };

  const handleSkip = () => {
    hideTenantSelect();
  };

  const handleContinue = () => {
    dispatch({
      type: SET_ASSOCIATED_REDEMPTION_CENTER,
      payload: selectedTenant.shortcode,
    });
    hideTenantSelect();
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        {t('signup.form.selectARedemptionCenter')}
      </Typography>
      {tenantList && (
        <FormControl fullWidth className={classes.form}>
          <InputLabel id="state-label">{t('signup.form.location')}</InputLabel>
          <Select
            labelId="select-tenant"
            id="select-tenant"
            value={selectedTenant}
            onChange={handleSelectTenant}
          >
            {tenantList.map((tenant, index) => (
              <MenuItem value={tenant} key={index}>
                {tenant.business_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Box className={classes.buttonGroup}>
        <Button onClick={handleSkip}>{t('signup.form.skip')}</Button>
        <Button variant="contained" onClick={handleContinue}>
          {t('buttons.continue')}
        </Button>
      </Box>
      {!tenantList && <Typography>Loading...</Typography>}
    </Box>
  );
}
