import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from 'redux/storage';
import './i18n';
import 'fonts/HelveticaNeueBold.ttf';
import 'fonts/HelveticaNeueLight.ttf';
import 'fonts/HelveticaNeueMedium.ttf';
import 'fonts/HelveticaNeueThin.ttf';

if (module.hot) {
  module.hot.accept();
}

Sentry.init({
  dsn: 'https://3de91cf006a3400287266ce992167cf6:37345d517c62492eac1d71aa2d255180@o1145172.ingest.sentry.io/6212123',
  integrations: [new BrowserTracing()],
  normalizeDepth: 10, // Or however deep you want your state context to be.
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
