import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Card, CardContent, CardMedia } from '@material-ui/core'

// assets
import recycleHeader from 'assets/img/Recycle-Crop-headerReduced.jpg'

const useStyles = makeStyles((theme) => ({
  content: {
    flex: '1 0 auto',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  card: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0
  },
  header: {
    height: 'auto',
    width: '100vw',
    display: 'flex',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
      objectFit: 'cover'
    }
  },
  headerTitle: {
    color: theme.palette.tertiary.main,
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 400,
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '8rem'
    }
  }
}))

function TitleSection(props) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <div style={{ position: 'relative' }}>
          <CardMedia
            component="img"
            className={classes.header}
            image={recycleHeader}
          ></CardMedia>
          <Typography variant="h1" className={classes.headerTitle}>
            More Than Sustainable
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default TitleSection
