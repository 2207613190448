import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: '1px solid #e2e2e1',
    padding: '8px',
    overflow: 'hidden',
    borderRadius: 10,
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    boxShadow: (props) => props.boxShadow,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

export default function CustomAutoComplete(props) {
  const classes = useStyles(props);
  const { list } = props;
  const flatProps = {
    options: list.map((list, index) => list),
  };

  return (
    <Autocomplete
      {...flatProps}
      multiple={props.multiple ? props.multiple : false}
      autoHighlight
      name={props.name}
      disabled={props.disabled}
      id={props.id}
      value={props.value}
      classes={classes}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      style={props.style}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            placeholder: props.placeholder,
          }}
        />
      )}
    />
  );
}
