import { jwtDecode } from 'jwt-decode';
import { logout } from 'redux/actions/auth';

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

export const configureConfigHeader = (getState) => {
  const token = getState().auth.access_token;
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const checkUserToken = (dispatch) => {
  if (localStorage.getItem('user')) {
    const token = localStorage.getItem('user');
    if (isTokenExpired(token)) {
      dispatch(logout());
    }
  } else {
    dispatch(logout());
  }
};
