/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { ReactReduxContext, useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, List, Button } from '@material-ui/core';

// components
import ProgressCircle from './ProgressCircle';

// assets
import BasicCard from 'components/Card/BasicCard';
import aluminumCan from 'assets/img/portal/icon_can.svg';
import glassBottle from 'assets/img/portal/icon_glass.svg';
import plasticBottle from 'assets/img/portal/icon_plastic.svg';
import theme from 'themes/memberPortalTheme';
import { formatMoney } from 'utils/formatTransactions';
import MetricDayListItem from './MetricDayListItem';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(21),
    lineHeight: 1,
  },
  totalCount: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(64),
    lineHeight: 1,
    marginRight: theme.spacing(2),
  },
  percentTitle: {
    textTransform: 'capitalize',
    fontWeight: 300,
  },
  cardTitle: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(21),
  },
}));

const per = [
  {
    metric: 'aluminum',
    icon: aluminumCan,
    color: theme.palette.text.primary,
  },
  {
    metric: 'glass',
    icon: glassBottle,
    color: theme.palette.secondary.main,
  },
  {
    metric: 'plastic',
    icon: plasticBottle,
    color: theme.palette.aqua.main,
  },
];

function MetricDates(props) {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const metricDay = useSelector((state) => state.metrics.stats?.metricDay);
  const [showMore, setShowMore] = React.useState(false);
  const [pickupDates, setPickupDates] = React.useState([]);

  const handleShowMore = () => {
    setShowMore(true);
  };

  React.useEffect(() => {
    if (metricDay && Object.keys(metricDay).length > 0) {
      let reverseDay = Object.entries(metricDay).reverse();
      let num = !showMore ? 5 : reverseDay.length;
      let dates = [];
      if (reverseDay.length > 5) {
        for (let i = 0; i < num; i++) {
          dates.push(reverseDay[i]);
        }
      } else {
        for (let i = 0; i < reverseDay.length; i++) {
          dates.push(reverseDay[i]);
        }
      }
      setPickupDates([...dates]);
    }
  }, [metricDay, showMore]);

  return (
    <BasicCard>
      <Box p={3}>
        <Box mb={2}>
          <Typography className={classes.cardTitle} color="primary">
            {t('metrics.metricsByTransaction')}
          </Typography>
        </Box>
        <Box>
          <List>
            {pickupDates.length > 0 ? (
              pickupDates.map((day, index) => (
                <MetricDayListItem
                  key={index}
                  metric={day}
                  type="recurring_pickup"
                />
              ))
            ) : (
              <Typography className={classes.noPickup}>
                {t('metrics.noPickupData')}
              </Typography>
            )}
          </List>
        </Box>
        {!showMore && pickupDates.length >= 5 ? (
          <Box mt={2} display="flex" justifyContent="center">
            <Button variant="contained" onClick={handleShowMore}>
              {t('metrics.showMore')}
            </Button>
          </Box>
        ) : null}
      </Box>
    </BasicCard>
  );
}

export default MetricDates;
