import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import {
  Typography,
  Grid,
  Container,
  Box,
  List,
  ListItem,
} from '@material-ui/core';

// components
import Page from 'components/Page/Page';
import ContactForm from 'components/ContactForm/ContactForm';
import ContactMap from 'components/Map/ContactMap';
import ContactOptions from './ContactOptions.js';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    minHeight: 'calc(100vh - 630px)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  mapContainer: {
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  listItem: {
    padding: '0',
    fontWeight: '300',
  },
  infoContainer: {
    padding: '2rem',
  },
  map: {
    border: 0,
    width: '100vw',
  },
}));

const Contact = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedContactEmail, setSelectedContactEmail] = useState('');

  const list = [
    'contact.helpPickup',
    'contact.dropOffSolutions',
    'contact.businessSolutions',
    'contact.appProblems',
  ];

  const options = [
    {
      title: 'contact.sales',
      text: 'sales@recycletek.co',
      value: 'sales@recycletek.co',
    },
    {
      title: 'contact.general',
      text: 'info@recycletek.co',
      value: 'info@recycletek.co',
    },
  ];

  const setContactSelected = (value) => {
    setSelectedContactEmail(value);
  };

  const clearForm = () => {
    setSelectedContactEmail('');
  };

  return (
    <Page>
      <Box className={classes.main}>
        <Box className={classes.mapContainer}>
          <ContactMap height={'400px'} />
        </Box>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container justify="space-around" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography color="primary" variant="h3">
                {t('contact.howCanWeHelp')}
              </Typography>
              <List>
                {list.map((reasonToContact, index) => (
                  <ListItem className={classes.listItem} key={index}>
                    <Typography>{t(reasonToContact)}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!selectedContactEmail && (
                <ContactOptions
                  options={options}
                  handleContactSelected={setContactSelected}
                />
              )}
              {selectedContactEmail && (
                <ContactForm
                  emailAddress={selectedContactEmail}
                  options={options}
                  clear={clearForm}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default Contact;
