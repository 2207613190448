import React from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Divider, Avatar } from '@material-ui/core';

// utils
import { formatMoney, formatMoney_alreadyInDollar } from 'utils/formatTransactions';
import AccountInfoIcon from '../Sections/AccountInfoIcon';

const useStyles = makeStyles((theme) => ({
  root: { paddingBottom: ' 20px' },
  balance: {
    marginTop: '2rem',
  },
  button: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  bold: {
    fontWeight: 400,
  },
  totalBold: {
    // fontSize: theme.typography.pxToRem(18),
  },
  confirmContainer: {
    margin: '0.5rem 0 2.3rem',
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  cancelButton: {
    marginRight: 0,
    marginTop: '1rem',
    order: 2,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginRight: '1rem',
      order: 1,
    },
  },
  confirmButton: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  info: {
    fontSize: theme.typography.pxToRem(21),
  },
  row: {
    width: '100%',
  },
  iconAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  icon: {
    width: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
    },
  },
  circle: {
    backgroundColor: '#EFF9D7',
  },
  smallText: {
    fontSize: theme.typography.pxToRem(12),
  },
  medText: {
    fontSize: theme.typography.pxToRem(14),
  },
  changeAccountLink: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '400',
    textDecoration: 'underline',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
  inputSublabel: {
    marginTop: '1rem',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
  },
  updateLink: {
    display: 'block',
    textAlign: 'center',
    marginTop: '.5rem',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover,&:focus': {
      color: theme.palette.primary.main,
      background: 'inherit',
    },
  },
  stepLabel: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 400,
  },
}));

function CheckbookConfirm(props) {
  const classes = useStyles();
  const { account_balance } = useSelector((state) => state.transactions);
  const { email } = useSelector((state) => state.auth.customer, shallowEqual);

  return (
    <div className={classes.root}>
      <Box
        className={classes.confirmContainer}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          mt={2}
          mb={3}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography className={classes.stepLabel} color="primary">
            Confirm Transfer
          </Typography>
        </Box>
        <Box
          px={1}
          display="flex"
          justifyContent="space-between"
          className={classes.row}
        >
          <Typography className={classes.medText}>Amount:</Typography>
          <Typography className={classes.medText}>
            {formatMoney_alreadyInDollar(props.amount / 100.)}
          </Typography>
        </Box>
        <Box
          px={1}
          mb={1}
          display="flex"
          justifyContent="space-between"
          className={classes.row}
        >
          <Typography gutterBottom className={classes.medText}>
            Check Fee:
          </Typography>
          <Typography gutterBottom className={classes.medText}>
            {formatMoney_alreadyInDollar(props.fee / 100.)}
          </Typography>
        </Box>
        <Divider className={classes.row} />
        <Box
          py={2}
          px={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.row}
        >
          <Typography color="primary" className={classes.totalBold}>
            Digital Check Amount:
          </Typography>
          <Typography color="primary" className={classes.totalBold}>
            {formatMoney_alreadyInDollar(props.finalAmount / 100.)}
          </Typography>
        </Box>
        <Divider className={classes.row} />
        <Box
          px={1}
          my={1}
          display="flex"
          justifyContent="space-between"
          className={classes.row}
        >
          <Typography className={classes.medText}>
            New Recycletek Balance:
          </Typography>
          <Typography className={classes.medText}>
            {formatMoney(props.remaining)}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography className={classes.inputSublabel}>
            Sending check to Recycletek account email {email}
          </Typography>
          <NavLink exact to="/portal/account" className={classes.updateLink}>
            Update Email
          </NavLink>
        </Box>
      </Box>
      <Box className={classes.action}>
        <Button
          className={classes.cancelButton}
          classes={{ root: classes.button }}
          color="primary"
          variant="contained"
          onClick={props.handlePreviousActiveStep}
        >
          Back
        </Button>
        <Button
          className={classes.confirmButton}
          classes={{ root: classes.button }}
          disabled={props.disabled}
          onClick={props.handleSubmitTransfer}
          color="secondary"
          variant="contained"
        >
          Confirm {formatMoney_alreadyInDollar(props.finalAmount / 100.)} Check Amount
        </Button>
      </Box>
    </div>
  );
}

export default CheckbookConfirm;
