import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Typography,
  Hidden,
  Button,
  TextField,
  FormHelperText,
} from '@material-ui/core';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// components
import PortalTextField from 'components/TextField/PortalTextField';
import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';
import theme from 'themes/memberPortalTheme';
import { validateAddress } from 'api/AddressValidation';
import AddressValidationModal from 'components/AddressValidationModal/AddressValidationModal';
import { states } from 'lib/formValues';

// styles
const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  step: {
    step: {
      padding: '8px 25px',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(21),
    },
  },
  phoneNumber: {
    color: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
    '&:disabled': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.primary.contrastText,
    },
  },
  disclaimer: {
    marginTop: theme.spacing(1),
  },
}));

export default function AddressForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const boxShadow = theme.shadows[25];
  const [openAddressValidation, setOpenAddressValidation] = useState(false);
  const [address, setAddress] = useState();
  const [recommendedAddress, setRecommendedAddress] = useState('');
  const [recommendedAddressComponents, setRecommendedAddressComponents] =
    useState();
  const [validationStatus, setValidationStatus] = useState('');

  const { first_name, last_name, street, city, zipcode, state } =
    props.validation;

  const validationSchema = yup.object({
    first_name,
    last_name,
    street,
    city,
    zipcode,
    state,
  });

  const setAddressAndContinue = (address) => {
    props.handleChange(address);
    props.handleChangeActiveStep();
  };

  const handleAddressValidation = (verdict, address) => {
    const unconfirmed = verdict.hasUnconfirmedComponents;
    const inferred = verdict.hasInferredComponents;
    const replaced = verdict.hasReplacedComponents;

    if (unconfirmed) {
      setValidationStatus('noRecommendation');
      setOpenAddressValidation(true);
    } else if (inferred || replaced) {
      setValidationStatus('showRecommendation');
      setOpenAddressValidation(true);
    } else if (!unconfirmed && !inferred && !replaced) {
      setAddressAndContinue(address);
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: props.userInfo.first_name,
      last_name: props.userInfo.last_name,
      street: props.userInfo.street,
      state: props.userInfo.state,
      city: props.userInfo.city,
      zipcode: props.userInfo.zipcode,
      apt: props.userInfo.apt,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      validateAddress(
        values.street,
        `#${values.apt}`,
        values.city,
        values.zipcode,
        values.state,
      )
        .then((res) => {
          actions.setSubmitting(false); // enable button
          props.handleChange({
            first_name: values.first_name,
            last_name: values.last_name,
          }); // add some of the user data to the user info obj, address will be added later
          setAddress(values); // user address, no edits
          setRecommendedAddress(res.address.formattedAddress); // user address, formatted by validation api
          setRecommendedAddressComponents(res.address.addressComponents);
          handleAddressValidation(res.verdict, values);
        })
        .catch((err) => {
          console.error('Error submitting Address: ', err);
          actions.setSubmitting(false); // enable button
          setAddressAndContinue(values);
        });
    },
  });

  const closeAddressValidationModal = () => {
    setOpenAddressValidation(false);
  };

  return (
    <React.Fragment>
      <AddressValidationModal
        open={openAddressValidation}
        close={closeAddressValidationModal}
        validationStatus={validationStatus}
        address={address}
        recommendedAddress={recommendedAddress}
        recommendedAddressComponents={recommendedAddressComponents}
        setAddressAndContinue={setAddressAndContinue}
      />
      <Box
        mb={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Hidden smUp>
          <Box mt={1}>
            <Typography className={classes.step} color="primary" align="center">
              {t('signup.steps.stepOne')}
            </Typography>
          </Box>
        </Hidden>
        <Typography variant="h5" align="center">
          {t('signup.form.addressInformation')}
        </Typography>
        <Typography className={classes.disclaimer} variant="body2">
          {t('signup.notificationDisclaimer')}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PortalTextField
              className={classes.input}
              id="firstName"
              name="first_name"
              placeholder={t('signup.form.firstName')}
              type="text"
              fullWidth
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PortalTextField
              className={classes.input}
              type="text"
              fullWidth
              id="lastName"
              name="last_name"
              placeholder={t('signup.form.lastName')}
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <PortalTextField
              className={classes.input}
              type="text"
              fullWidth
              id="street"
              name="street"
              placeholder={t('signup.form.streetAndNumber')}
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
            />
          </Grid>
          <Grid item xs={6}>
            <PortalTextField
              className={classes.input}
              type="text"
              fullWidth
              id="apt"
              name="apt"
              placeholder={t('signup.form.aptOrSuite')}
              value={formik.values.apt}
              onChange={formik.handleChange}
              error={formik.touched.apt && Boolean(formik.errors.apt)}
              helperText={formik.touched.apt && formik.errors.apt}
            />
          </Grid>
          <Grid item xs={6}>
            <PortalTextField
              className={classes.input}
              type="text"
              fullWidth
              id="city"
              name="city"
              placeholder={t('signup.form.city')}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={6}>
            <PortalTextField
              className={classes.input}
              type="text"
              fullWidth
              id="zipcode"
              name="zipcode"
              placeholder={t('signup.form.zipcode')}
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && formik.errors.zipcode}
            />
          </Grid>
          <Grid item xs={5} sm={3}>
            <CustomAutoComplete
              boxShadow={boxShadow}
              placeholder={t('signup.form.state')}
              disabled={false}
              id="state"
              list={states}
              value={formik.values.state}
              onChange={(e, value) => {
                formik.setFieldValue('state', value);
              }}
              fullWidth
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <TextField {...params.inputProps} />
                </div>
              )}
            />
            <FormHelperText
              error={formik.touched.state && Boolean(formik.errors.state)}
            >
              {formik.touched.state && formik.errors.state}
            </FormHelperText>
          </Grid>
          <Grid item xs={7} md={6}></Grid>
        </Grid>
        <Box mt={3}>
          <Button
            variant="contained"
            onClick={props.handleBack}
            className={classes.button}
          >
            {t('buttons.back')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            className={classes.button}
            disabled={formik.isSubmitting}
          >
            {t('buttons.next')}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
}

AddressForm.propTypes = {
  handleChange: PropTypes.func,
};
