import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    marginBottom: theme.spacing(2),
  },
  infoText: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'justify',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  errorBox: {
    border: '1px solid red',
    borderRadius: '10px',
  },
}));

const PayPalError = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { transferMessage } = useSelector((state) => state.transactions);
  return (
    <div>
      <DialogContentText className={classes.contentBox}>
        <Box mt={3}>
          <Typography className={classes.infoText}>
            {t('ecowallet.paypal.errorDuringTransfer')}
          </Typography>
        </Box>
        <Box p={3} className={classes.errorBox}>
          <Typography>{transferMessage}</Typography>
        </Box>
      </DialogContentText>
      <DialogActions>
        <Button
          classes={{ root: classes.button }}
          color="primary"
          variant="contained"
          onClick={props.handleClose}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          classes={{ root: classes.button }}
          color="secondary"
          variant="contained"
          type="submit"
          onClick={props.handlePreviousActiveStep}
        >
          {t('buttons.goBack')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default PayPalError;
