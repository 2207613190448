import React from 'react';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& h6': {
      marginBottom: theme.spacing(1),
    },
  },
  image: {
    width: '35%',
    marginRight: theme.spacing(6),
    '& img': {
      width: '100%',
      height: 'auto',
      minWidth: '250px',
      borderRadius: '3px',
    },
  },
  text: {
    maxWidth: '700px',
  },
}));

const CoFounder = ({ name, position, description, image }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.image}>
        <img src={image} alt="photo of co-founder" />
      </Box>

      <Box className={classes.text}>
        <Typography variant="h4">{name}</Typography>
        <Typography variant="h6">{position}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
};

export default CoFounder;
