import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormHelperText, Button, Grid } from '@material-ui/core';

import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';

const boxShadow = 'none';
const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  subtitle: {
    marginLeft: '5px',
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    padding: '5px 2px',
    fontSize: theme.typography.pxToRem(14),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SkipRecurringPickupForm(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <CustomAutoComplete
            fullWidth
            boxShadow={boxShadow}
            placeholder="Upcoming Pickups"
            disabled={false}
            id="skip-date"
            list={props.list}
            value={props.skipDate}
            onChange={(event, newValue) => {
              props.handleSelectDate(newValue);
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField {...params.inputProps} />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} container display="flex" alignItems="center">
          <Button
            classes={{ root: classes.button }}
            color="primary"
            variant="contained"
            onClick={props.handleSkipDate}
          >
            SKIP
          </Button>
        </Grid>
      </Grid>{' '}
      <FormHelperText error={props.error}>
        {props.error ? 'Please select a date' : ' '}
      </FormHelperText>
    </React.Fragment>
  );
}

SkipRecurringPickupForm.propTypes = {};
