import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Box,
  List,
  Divider,
  Typography,
} from '@material-ui/core';

// Components
import ConfirmPickupItem from './ConfirmPickupItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    borderRadius: 15,
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  expandButton: {
    color: (props) => props.iconColor,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  infoText: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  list: {
    padding: 0,
  },
  noConfirm: {
    fontWeight: 300,
  },
}));

function ConfirmPickup(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const pickups = useSelector((state) => state.pickups, shallowEqual);

  return (
    <React.Fragment>
      <Box mb={1}>
        <Typography className={classes.infoText}>
          {t('schedule.allPickupsNeedToBeConfirmed')}
        </Typography>
      </Box>
      <Divider />
      <Box>
        {pickups && Object.keys(pickups.confirmations).length > 0 ? (
          <List className={classes.list}>
            {Object.keys(pickups.confirmations).map((pickup, index) => (
              <ConfirmPickupItem
                key={index}
                pickup={pickups.confirmations[pickup]}
              />
            ))}
          </List>
        ) : (
          <React.Fragment>
            <Box mt={2}>
              <Typography
                className={classes.noConfirm}
                color="primary"
                align="center"
              >
                {t('schedule.noUpcomingPickupsNeedConfirmation')}
              </Typography>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
}

export default ConfirmPickup;
