import React from 'react';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Box, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 400,
  },
  text: {
    fontWeight: 300,
    maxWidth: '465px',
    textAlign: 'justify',
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  headerTitle: {
    marginBottom: theme.spacing(2),
  },
  headerContent: {},
}));

function EcoWallet(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { account_balance, latest_deposit } = useSelector(
    (state) => state.transactions
  );

  return (
    <React.Fragment>
      <Box mt={3} className={classes.headerTitle}>
        <Typography variant="h3" color="primary">
          Recycling <span className={classes.bold}>Metrics</span>
        </Typography>
      </Box>
      <div className={classes.headerContent}>
        <Grid container spacing={matches ? 2 : 5}>
          <Grid item className={classes.info}>
            {/* <BasicAccountInfo
              icon={balanceIcon}
              background={'#EFF9D7'}
              name={'Account Balance'}
              info={formatMoney(account_balance)}
            /> */}
          </Grid>
          <Grid item className={classes.info}>
            {/* <BasicAccountInfo
              icon={paymentIcon}
              background={'#E2ECF0'}
              name={'Latest Redemption Deposit'}
              info={formatMoney(latest_deposit)}
            /> */}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default EcoWallet;
