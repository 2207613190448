import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
}));

function AccountHeader() {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Box>
        <Typography variant="h3" color="primary">
          Eco
          <span className={classes.bold}>Account</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default AccountHeader;
