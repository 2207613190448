// lib
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Typography,
  DialogContent,
  Box,
  Button,
} from '@material-ui/core';

// components
import AddressOverviewOption from './AddressOverviewOption';
import { formatAddressComponents } from '../../utils/formatAddressComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    minWidth: '350px',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 300,
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'space-evenly',
      '& button': {
        margin: theme.spacing(1),
      },
    },
  },
}));

const AddressValidationModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(
    props.validationStatus === 'noRecommendation' ? 'original' : 'recommended',
  );

  /* eslint-disable no-useless-computed-key */
  const text = {
    ['noRecommendation']: {
      title: 'signup.form.pleaseCorrectAddress',
      subText: 'signup.form.itAppearsMistakeAddress',
    },
    ['showRecommendation']: {
      title: 'signup.form.pleaseConfirm',
      subText: 'signup.form.reviewRecommendedChanges',
    },
  };

  // submits the selected address option
  const handleContinue = () => {
    if (checked === 'original') {
      props.setAddressAndContinue(props.address);
    } else if (checked === 'recommended') {
      const formatted = formatAddressComponents(
        props.recommendedAddressComponents,
      );
      props.setAddressAndContinue(formatted);
    }
  };

  const handleAddressOptionSelected = (option) => {
    if (option === 'original') {
      setChecked(option);
    } else if (option === 'recommended') {
      setChecked(option);
    }
  };

  return (
    <Dialog
      maxWidth={'xs'}
      classes={{ paper: classes.root }}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogContent>
        <Typography className={classes.title} color="primary" variant="h4">
          {t(text[props.validationStatus]?.title)}
        </Typography>
        <Typography className={classes.description}>
          {t(text[props.validationStatus]?.subText)}
        </Typography>
        <AddressOverviewOption
          checked={checked === 'original'}
          text={t('signup.form.whatYouEntered')}
          address={props.address}
          zipcode={props.zipcode}
          selectOption={handleAddressOptionSelected}
        />
        {props.validationStatus === 'showRecommendation' && (
          <AddressOverviewOption
            checked={checked === 'recommended'}
            text={t('signup.form.recommended')}
            recommendedAddress={props.recommendedAddress.split(',')}
            selectOption={handleAddressOptionSelected}
          />
        )}

        <Box className={classes.buttonGroup}>
          <Button variant="contained" onClick={props.close}>
            {t('buttons.back')}
          </Button>
          <Button variant="contained" onClick={handleContinue}>
            {t('buttons.continue')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddressValidationModal;
