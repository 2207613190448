export const RECURRING_COLOR = '#ffC845';
export const RECURRING_COUNT = 26;
export const RECURRING_FREQUENCY = 7;

export const CURBSIDE_DAY = 3;
export const CURBSIDE_FREQUENCY = 'weekly';
export const CURBSIDE_INTERVAL = 2;
export const CURBSIDE_STDATE = '2020-12-29';
export const CURBSIDE_COLOR = '#97D700';
export const RECURRING_DAYS_COLOR = 'rgb(216 245 150)';

export const SKIP_COLOR = 'red';
export const SINGLE_COLOR = '#05C3DD';
export const CONFIRMED_COLOR = 'green';

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
