import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  ListItem,
  Divider,
  Button,
  Collapse,
  ListItemText,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PaymentIcon from '@material-ui/icons/Payment';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';

// components
import EcoCardTransferModal from './EcoCardTransferModal';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  title: {
    paddingRight: theme.spacing(6),
  },
  subtitle: {
    fontSize: '.75rem',
  },
  info: {
    fontWeight: 300,
  },
  icon: {
    width: '45px',
    marginRight: '0.25rem',
  },
  button: {
    /*     background: theme.palette.primary.main, */
    padding: '5px 20px',
    fontSize: theme.typography.pxToRem(14),
  },
  iconEcoCard: {
    marginRight: '7px',
    marginLeft: '-4px',
  },
  iconArrow: {
    marginLeft: '-5px',
    fontSize: '22px',
  },
  sublabel: {
    fontSize: theme.typography.pxToRem(12),
  },
  errorAlert: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
  },
  errorContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '@media (min-width:600px)': {
      maxWidth: '600px',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

function EcoCardTransfer(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  // Opens and closes dropdown
  const handleClick = () => {
    setOpen((current) => !current);
  };

  // Opens and closes popup
  const handleModal = () => {
    setOpenModal((current) => !current);
  };

  return (
    <React.Fragment>
      <ListItem
        classes={{ root: classes.listRoot }}
        button
        onClick={handleClick}
      >
        <ListItemText className={classes.pickup}>
          <Typography className={classes.title} color="primary">
            {t('ecowallet.ecocard.ecocard')} {t('ecowallet.ecocard.transfer')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Box py={1} pl={3} display="flex" flexDirection={'column'}>
            <Typography>{t('ecowallet.ecocard.transferAnAmount')}</Typography>
            <Box py={1} my={2}>
              <Button
                variant="contained"
                classes={{ root: classes.button }}
                onClick={handleModal}
              >
                <ForwardOutlinedIcon className={classes.iconArrow} />
                <PaymentIcon className={classes.iconEcoCard} />
                {t('ecowallet.ecocard.setupTransfer')}
              </Button>
              <EcoCardTransferModal
                open={openModal}
                handleClose={handleModal}
                activeStep={0}
              />
            </Box>
            <Box>
              <Typography className={classes.sublabel}>
                {t('ecowallet.ecocard.transferFee')}
              </Typography>
              <Typography className={classes.sublabel}>
                {t('ecowallet.ecocard.transferCannotExceedBalance')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

export default EcoCardTransfer;
