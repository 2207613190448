/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

// Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '12px 12px 12px 12px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  text: {
    fontWeight: 300,
    textAlign: 'justify',
  },
  chartContainer: {
    position: 'relative',
  },
}));

function TotalContainerGraph() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const aluminumData = useSelector(
    (state) => state.metrics.stats?.aluminumData,
  );
  const plasticData = useSelector((state) => state.metrics.stats?.plasticData);
  const glassData = useSelector((state) => state.metrics.stats?.glassData);

  const options = {
    animation: true,
    animationSteps: 20,
    // aspectRatio: 5 / 3,
    scales: {
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: t('metrics.numOfContainers'),
        },
        suggestedMax: 50,
        ticks: {
          beginAtZero: true,
          stepSize: 250,
        },
      },
      x: {
        grid: {
          display: true,
        },
        title: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'center',
      },
      title: {
        display: false,
        text: 'Amount Per Month By Container',
        padding: {
          bottom: 20,
        },
        font: {
          size: 18,
          weight: 'bold',
        },
      },
    },
  };

  const data = (canvas) => {
    return {
      labels: aluminumData?.labels && [...aluminumData.labels],
      datasets: [
        {
          label: t('common.plasticCap'),
          borderRadius: 4,
          backgroundColor: 'rgba(5, 195, 221)',
          data: aluminumData?.metrics && [...plasticData.metrics],
          stack: 'Stack 0',
        },
        {
          label: t('common.glassCap'),
          borderRadius: 4,
          backgroundColor: 'rgba(151, 215, 0)',
          data: glassData?.metrics && [...glassData.metrics],
          stack: 'Stack 1',
        },
        {
          label: t('common.aluminumCap'),
          borderRadius: 4,
          backgroundColor: 'rgba(124, 135, 142)',
          data: aluminumData?.metrics && [...aluminumData.metrics],
          stack: 'Stack 2',
        },
      ],
    };
  };

  let shadowed = {
    beforeDatasetsDraw: function (chart, options) {
      chart.ctx.shadowColor = 'rgba(0, 0, 0, .2)';
      chart.ctx.shadowOffsetX = 5;
      chart.ctx.shadowOffsetY = -5;
      chart.ctx.shadowBlur = 20;
    },
    afterDatasetsDraw: function (chart, options) {
      chart.ctx.shadowColor = 'rgba(0, 0, 0, 0)';
      chart.ctx.shadowBlur = 0;
    },
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Typography>{t('metrics.amountPerMonthByContainer')}</Typography>
      </Box>
      <Box mt={1} className={classes.chartContainer}>
        <Bar data={data} options={options} plugins={[shadowed]} />
      </Box>
    </div>
  );
}

export default TotalContainerGraph;
