import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  bold: {
    fontWeight: 400,
  },
}));

function CustomMoneyInput(props) {
  const { inputRef, onChange, ...other } = props;
  const classes = useStyles();
  return (
    <NumberFormat
      {...other}
      thousandSeparator={true}
      allowNegative={false}
      decimalScale={2}
      // allowEmptyFormatting
      prefix={'$ '}
      placeholder={'$ 0.00'}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default CustomMoneyInput;

CustomMoneyInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
