import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navbar: {
    padding: theme.spacing(1),
    position: 'sticky',
    top: '80px',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(10),
    zIndex: '199',
    [theme.breakpoints.down('sm')]: {
      top: '70px',
    },
  },
  linksHeading: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  links: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& a': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      '&:hover': {
        color: 'inherit',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'none',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const StickyNav = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.navbar}>
      <Typography variant="body2" className={classes.links}>
        <Hidden smUp>
          <Typography className={classes.linksHeading}>
            {t('how.onThisPage')}
          </Typography>
        </Hidden>
        <NavLink to="/howitworks#customers">
          {t('how.recyclingReturnCustomers')}
        </NavLink>

        <NavLink to="/howitworks#redemption-centers">
          {t('how.redemptionCenters')}
        </NavLink>

        <NavLink to="/howitworks#retailers">{t('how.retailers')}</NavLink>
        <NavLink to="/howitworks#municipalities">
          {t('how.sustainabilityManagers')}
        </NavLink>
      </Typography>
    </Box>
  );
};

export default StickyNav;
