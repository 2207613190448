import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CardContent, Button } from '@material-ui/core';

// Components
import BasicCard from 'components/Card/BasicCard';
import BusinessPickupForm from '../Forms/BusinessPickupForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    color: theme.palette.tertiary.main,
    marginBottom: '0.5rem',
  },
  infoText: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  button: {
    padding: '2px 10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
}));

function BusinessPickup(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BasicCard className={classes.root}>
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          {t('schedule.getMoreForYourBusiness')}
        </Typography>
        <Typography className={classes.infoText}>
          {t('schedule.workWithRecycletekSpecialist')}
        </Typography>
        <Button
          classes={{ root: classes.button }}
          color="secondary"
          variant="contained"
          onClick={handleClickOpen}
        >
          {t('buttons.contact')}
        </Button>
        {/* Dialog for contact */}
        <BusinessPickupForm open={open} handleClose={handleClose} />
      </CardContent>
    </BasicCard>
  );
}

export default BusinessPickup;
