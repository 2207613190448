import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

// Redux
import { useSelector, shallowEqual } from 'react-redux';

// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Box, Grid } from '@material-ui/core';

// Assets
import paymentIcon from 'assets/img/portal/account_icons_payment.svg';
import truckIcon from 'assets/img/portal/account_icons_truck.svg';

// utils
import { formatMoney } from 'utils/formatTransactions';
import LatestDepositInfo from './Sections/LatestDepositInfo';
import LatestPickupInfo from './Sections/LatestPickupInfo';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

function DashboardHeader() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const transactions = useSelector((state) => state.transactions);
  const customer = useSelector((state) => state.auth.customer);
  const [latest_deposit, setLatest_deposit] = React.useState('');
  const { nearest } = useSelector((state) => state.pickups, shallowEqual);

  React.useEffect(() => {
    const { latest_deposit } = transactions;
    setLatest_deposit(latest_deposit);
  }, [transactions]);

  return (
    <React.Fragment>
      <div className={classes.headerContent}>
        <Grid container spacing={matches ? 2 : 5}>
          <Grid xs={12} item className={classes.info}>
            <Box>
              <Typography variant="h3" color="primary">
                {t('dashboard.hello')}
                <span className={classes.bold}> {customer?.first_name}</span>
              </Typography>
              <Typography variant="h4" color="primary" className={classes.name}>
                {customer?.type === 1 && customer?.business_name}
              </Typography>
              <Typography className={classes.subtitle}>
                {customer?.type === 0
                  ? t('dashboard.recycletekMember')
                  : t('dashboard.recycletekBusinessMember')}
              </Typography>
            </Box>
          </Grid>
          {Object.keys(nearest).length > 0 && (
            <Grid item className={classes.infoContainer}>
              <Box>
                <LatestPickupInfo
                  icon={truckIcon}
                  background={'#CDF3F8'}
                  name={'upcoming pickup'}
                />
              </Box>
            </Grid>
          )}
          {latest_deposit.total_redemption_amount > 0 && (
            <Grid item className={classes.infoContainer}>
              <Box>
                <LatestDepositInfo
                  icon={paymentIcon}
                  background={'#E2ECF0'}
                  name={'Latest Redemption'}
                  // info={formatMoney(latest_deposit.total_redemption_amount) }
                  date={`${DateTime.fromMillis(
                    latest_deposit.timestamp * 1000,
                  ).toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}`}
                  amount={`${formatMoney(
                    latest_deposit.total_redemption_amount,
                  )} - ${
                    latest_deposit.transactiontype_id === 2
                      ? 'Donation'
                      : 'Deposit'
                  }`}
                  container={`${latest_deposit.note.match(/\d+/g)} containers`}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default DashboardHeader;
