import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { recycletekHQLocations } from 'lib/locations';

const ContactMap = ({ height }) => {
  return (
    <div style={{ height: `${height}`, width: '100%' }} data-cy="contact-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyADxFGc2Gec58t95waF6bcpgiU5tpPyuGM',
        }}
        defaultCenter={{ lat: 40.60119, lng: -99.50151 }}
        defaultZoom={4}
        hoverDistance={20}
      >
        {recycletekHQLocations.map((location, index) => (
          <Marker lat={location.lat} lng={location.lng} key={index} />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default ContactMap;
