import React from 'react';
import { useTranslation } from 'react-i18next';
// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import verifiedIcon from 'assets/img/portal/verified.svg';

// styles
const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: '1rem',
    width: '50px',
  },
}));

export default function Verified(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img className={classes.icon} src={verifiedIcon} />
      <Typography className={classes.glassTitle} align="center">
        {t('signup.hasBeenVerified', { item: props.name })}
      </Typography>
    </Box>
  );
}
